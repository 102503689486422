import * as React from 'react'

import styled from 'theme/styled-components'
import useTheme from 'theme/useTheme'

import Icon from 'components/icons/Icons'
import Button from 'components/button/Button'
import Alert from 'components/alert/Alert'
import ValidationContent from 'components/modal/ValidationContent'
import StatusLabel from 'components/label/StatusLabel'

import useI18n from 'i18n/useI18n'

import { windowSizeBreakpoints } from 'utils/breakpoints'
import stringUtils from 'utils/strings'

import api from '../api'

import useReducer from 'store/useReducer'
import * as siteStore from 'sites/store/siteStore'
import * as userStore from 'store/user/user'

import config from 'core/src/config'
import { utcDateToTimezoneSite } from '../utils'

interface Props {
  moduleInfos: BarclapModuleDatas
  closeModal: () => void
  refreshModulesInfos: () => void
  mode: 'BOOK' | 'READ'
}

const CURRENT_DATE = new Date()

const PackedLunchScreen = ({ moduleInfos, closeModal, refreshModulesInfos, mode }: Props) => {
  const [theme] = useTheme()
  const i18n = useI18n()

  const user = useReducer(userStore.store, (s) => s.user)
  const currentSite = useReducer(siteStore.store, (s) => s.site)

  const timeSlotInfos = moduleInfos.module_timeslots[0] as BarclapModuleTimeslot | undefined

  const [isBooking, setIsBooking] = React.useState(false)

  const onConfirmBooking = () => {
    if (!!user && !!currentSite && !!currentSite.barclapId && !!timeSlotInfos) {
      setIsBooking(true)
      api.barclap
        .postBooking(user.type, currentSite.id, {
          booking: {
            start_time: timeSlotInfos.start_time,
            end_time: timeSlotInfos.end_time,
            date: i18n.t('formats.utcDate', { date: CURRENT_DATE }),
            nb_guest: null,
            name: i18n.t(`screens.catering.services.packedLunch`),
          },
          module_uuid: config.barclap.packedLunchUUID,
          site_uuid: currentSite.barclapId,
        })
        .then(() =>
          Alert.open(
            () => (
              <ValidationContent
                title={i18n.t(`screens.barclap.form.packedLunchSuccess`)}
                onConfirm={() => {
                  Alert.close()
                  closeModal()
                  refreshModulesInfos()
                }}
                ariaLabelConfirm={i18n.t('label.ariaLabel.windowCrossIcon')}
              />
            ),
            true
          )
        )
        .catch(() =>
          Alert.open(
            () => (
              <ValidationContent
                title={i18n.t(`screens.barclap.form.error`)}
                onConfirm={Alert.close}
                ariaLabelConfirm={i18n.t('label.ariaLabel.windowCrossIcon')}
              />
            ),
            true
          )
        )
        .finally(() => setIsBooking(false))
    }
  }

  const renderBooking = () => {
    if (!currentSite) {
      return null
    }
    const remainingBookings = (moduleInfos.is_open && timeSlotInfos?.remaining_booking) || 0
    const startTime = utcDateToTimezoneSite(
      `${stringUtils.getYMDFromDate(new Date())}T${timeSlotInfos?.start_time || '00:00:00'}Z`,
      currentSite.timezone
    )
    const endTime = utcDateToTimezoneSite(
      `${stringUtils.getYMDFromDate(new Date())}T${timeSlotInfos?.end_time || '00:00:00'}Z`,
      currentSite.timezone
    )
    const isOpen = moduleInfos.is_open
    return (
      <InfosContainer>
        {mode === 'READ' ? (
          <DescriptionContainer>
            <Description>{i18n.t('screens.catering.services.packedLunchDescription')}</Description>
          </DescriptionContainer>
        ) : (
          <>
            <StatusLabel
              color={isOpen ? theme.colors.turquoise : theme.colors.red}
              label={i18n.t(`screens.catering.status.${isOpen ? 'open' : 'close'}`)}
              marginTop={5}
              marginBottom={20}
            />
            <RowContainer>
              <IconContainer>
                <Icon name="clock" size={20} color={theme.colors.blue} />
              </IconContainer>
              <Label>
                {i18n.t('screens.catering.services.openTimes', {
                  startTime,
                  endTime,
                })}
              </Label>
            </RowContainer>
            <RowContainer>
              <IconContainer>
                <Icon name="restaurant" size={20} color={theme.colors.blue} />
              </IconContainer>
              <Label>
                {i18n.t(`screens.catering.services.packedLunchLeft`, {
                  count: remainingBookings,
                })}
              </Label>
            </RowContainer>
            <Description>{i18n.t('screens.catering.services.packedLunchBookingDescription')}</Description>
            <ButtonContainer>
              <Button
                label={i18n.t('screens.barclap.form.confirm')}
                color={theme.colors.blue}
                textColor={theme.colors.white}
                font={theme.fonts.h3Bold}
                loading={isBooking}
                onClick={onConfirmBooking}
                disabled={remainingBookings === 0}
              />
            </ButtonContainer>
          </>
        )}
        <ButtonContainer>
          <Button
            label={i18n.t(`common.${mode === 'BOOK' ? 'cancel' : 'close'}`)}
            onClick={closeModal}
            color={mode === 'BOOK' ? theme.colors.white : theme.colors.blue}
            textColor={mode === 'BOOK' ? theme.colors.blue : theme.colors.white}
            shadow
            font={theme.fonts.h3Bold}
          />
        </ButtonContainer>
      </InfosContainer>
    )
  }

  return (
    <MainContainer>
      <Title>{i18n.t(`screens.catering.services.packedLunch`)}</Title>
      {renderBooking()}
    </MainContainer>
  )
}

export default PackedLunchScreen

const MainContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  padding: 20px;
  max-height: 70vh;
  width: 400px;
  @media only screen and (max-width: ${windowSizeBreakpoints.medium}px) {
    width: 280px;
  }
  overflow: auto;
`

const Title = styled.p`
  ${(props) => props.theme.fonts.h2Bold};
  margin: 20px 0px 0px 10px;
`

const RowContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  margin-bottom: 8px;
  align-items: center;
`

const ButtonContainer = styled.div`
  display: flex;
  flex: 1;
  margin-top: 10px;
`

const IconContainer = styled.div`
  width: 30px;
  align-items: center;
  justify-content: center;
`

const Label = styled.p`
  ${(props) => props.theme.fonts.label};
  margin: 0px;
  display: flex;
  flex: 1;
`

const Description = styled.p`
  ${(props) => props.theme.fonts.body};
  margin: 0px 0px 20px;
  font-size: 16px;
  line-height: 19px;
  display: flex;
  flex: 1;
  white-space: pre-line;
`

const InfosContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  padding: 0px 12px 20px;
`

const DescriptionContainer = styled('div')`
  margin-top: 20px;
`
