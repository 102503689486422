import * as React from 'react'
import styled from 'theme/styled-components'
import useTheme from 'theme/useTheme'

import api from './api'
import interventionApi from 'intervention/api'

import useReducer from 'store/useReducer'
import * as siteStore from 'sites/store/siteStore'
import * as favoritesStore from './favoritesStore'
import * as userStore from 'store/user/user'
import * as bannersStore from 'banners/store'

import useI18n from 'i18n/useI18n'
import Logger from 'utils/Logger'
import config from 'core/src/config'

import Tag from 'components/label/Tag'
import Icons from 'components/icons/Icons'
import Modal from 'components/modal/Modal'
import { CategoryIcon, LineIcon } from './svg/TransportIcon'
import Toast from 'components/toast/Toast'
import ToastContent from 'components/toast/ToastContent'
import Breadcrumb from 'components/breadcrumb/Breadcrumb'
import TitleHelmet from 'components/title/TitleHelmet'
import Banner from 'components/banner/Banner'
import SurveyBanner from 'components/banner/SurveyBanner'

import { getDistances } from './utils'
import { windowSizeBreakpoints } from 'utils/breakpoints'
import analytics, { analyticsKeys, externalLinkTracking } from 'utils/analytics'
import useNavigation from 'utils/navigation/useNavigation'
import useBreakpoint from 'utils/useBreakpoints'

import Loader from 'react-loader-spinner'
import FocusLock from 'react-focus-lock'

const REFRESH_DELAY = 60

const ONLYMOOV_LINK = `https://www.onlymoov.com/personnalisation-widget-carte-multimodale/?type=14778421378&displayMode=0&key=${config.ONLYMOOV_KEY}&zoom=1&defaultZoom=15&searchA=1&modeC=1&previsionC=1&layersC=1&lat=45.727800&lng=4.823931&address=Avenue%20Tony%20Garnier%2C%20Lyon&showPOICenter=1&vp=journey-time%7Ctraffic%7Cevent%7Ccamera&tc=tcl%7Cparking&velo=cycleroad%7Cvelov&pedestrian=walking&height=420`

const frenchCities: TransportStations['city'][] = ['PARIS', 'LYON']

const TransportScreen = () => {
  const [Theme] = useTheme()
  const navigation = useNavigation()
  const [windowSize, currentBreakpoint] = useBreakpoint()

  const [traffic, setTraffic] = React.useState<TransportStations>()
  const [bikes, setBikes] = React.useState<BikeStations>()
  const [current, setCurrent] = React.useState(new Date())
  const [modifying, setModifying] = React.useState(false)
  const [transportsLoaded, setTransportsLoaded] = React.useState(false)
  const [favoriteLoaded, setFavoriteLoaded] = React.useState(false)
  const [buildings, setBuildings] = React.useState<LocationV6[]>([])
  const [selectedBuilding, setSelectedBuilding] = React.useState<LocationV6>()
  const [selectedTypeLine, setSelectedTypeLine] = React.useState<string>('ALL')

  const site = useReducer(siteStore.store, (s) => s.site)
  const mySites = useReducer(siteStore.store, (s) => s.mySites)
  const favorites = useReducer(favoritesStore.store, (s) => s.favorites)
  const user = useReducer(userStore.store, (s) => s.user)
  const banners = useReducer(bannersStore.store, (s) => s.banners)
  const i18n = useI18n()

  const allTypesLines: string[] = React.useMemo(
    () =>
      !!traffic
        ? (['ALL', ...Array.from(new Set(traffic?.stations.map((s) => s.category))).filter((s) => !!s)] as string[])
        : [],
    [traffic]
  )

  const displayedFavorites = favorites.filter((f) => f.siteId === site?.id)
  // Pour les transports, on le fait que sur le site de Campus donc code = la_garenne_colombes_campus
  const hasMultipleBuildings = site?.code === 'la_garenne_colombes_campus'

  const city: TypeCity | undefined = traffic ? (traffic.city as TypeCity) : bikes ? (bikes.city as TypeCity) : undefined

  const isInFrance = !!city && frenchCities.includes(city)

  const wazeIFrame =
    site && `https://embed.waze.com/iframe?zoom=14&lat=${site.latitude}&lon=${site.longitude}&ct=livemap&pin=1`

  const CARSHARING_LINK = 'https://carsharing.engie.com/'

  const sortedCategory = ['METRO', 'TRAMWAY', 'BUS', 'RER']

  const showedBanner = React.useMemo(
    () => banners.find((banner) => !!banner.feature && banner.feature === 'TRANSPORT'),
    [banners]
  )

  const findFeature = React.useMemo(() => site?.features.find((f) => f.type === 'TRANSPORT'), [site])

  const stationsInfo = React.useMemo(() => {
    if (traffic) {
      return traffic.stations
        .reduce((acc, { name, line, category }) => {
          const index = acc.findIndex((o) => o.name === name)
          if (selectedTypeLine !== 'ALL' && category !== selectedTypeLine) {
            return acc
          }
          if (index > -1) {
            const lineAlreadyAdded = !!acc[index].lines.find((o) => o.name === line)
            if (!lineAlreadyAdded) {
              acc[index].lines.push({ name: line })
            }
          } else {
            acc.push({ name: name, lines: [{ name: line }], category: category })
          }
          return acc
        }, [] as DisplayedStation[])
        .sort((a, b) =>
          a.category && b.category ? sortedCategory.indexOf(a.category) - sortedCategory.indexOf(b.category) : 0
        )
    }
    return []
  }, [traffic, selectedTypeLine])

  const displayedStations = React.useMemo(
    () =>
      traffic?.stations
        .filter((s) => s.schedules.length > 0)
        .sort((a, b) =>
          a.line === b.line
            ? a.schedules[0].direction.localeCompare(b.schedules[0].direction)
            : a.line.localeCompare(b.line)
        )
        .reduce((acc, cur) => {
          cur.schedules.forEach((sch) => {
            const directionIndex = acc.findIndex(
              (item) =>
                item.schedules[0].direction === sch.direction && item.name === cur.name && item.line === cur.line
            )
            if (directionIndex < 0) {
              acc.push({ ...cur, schedules: cur.schedules.filter((s) => s.direction === sch.direction) })
            }
          })

          return acc
        }, [] as TransportStation[]),
    [traffic]
  )

  const addFavoriteStation = (station: TransportStation) => {
    const alreadyFavorite = favorites.find(
      (s) =>
        s.station === station.name &&
        s.line === station.line &&
        s.direction === station.schedules[0].direction &&
        s.siteId === site?.id
    )
    if (!alreadyFavorite && site) {
      favoritesStore.actions.setFav([
        ...favorites,
        {
          station: station.name,
          line: station.line,
          direction: station.schedules[0].direction,
          category: station.category,
          siteId: site.id,
        },
      ])
    }
  }

  const deleteFavorite = (station: FavoriteDirection) => {
    const updatedFav = favorites.filter(
      (f) =>
        f.station !== station.station ||
        f.line !== station.line ||
        f.direction !== station.direction ||
        f.siteId !== site?.id
    )
    favoritesStore.actions.setFav(updatedFav)
    if (updatedFav.length === 0) {
      setModifying(false)
    }
  }

  const favoriteFunc = (alreadyFavorite: boolean, station: TransportStation) => {
    if (alreadyFavorite && site) {
      const favStation: FavoriteDirection = {
        station: station.name,
        line: station.line,
        direction: station.schedules[0].direction,
        siteId: site.id,
        category: station.category,
      }
      deleteFavorite(favStation)
    }
    addFavoriteStation(station)
  }

  const renderModalButton = (opt: 'citymapper' | 'waze' | 'googlemaps', icon: IconName, link?: string) => (
    <ModalButton
      onClick={() => {
        if (!!link) {
          externalLinkTracking(link, analyticsKeys.transports, user, site, mySites)
        }
        window.open(link)
      }}>
      <Icons name={icon} size={20} />
      <SpecifiButtonText>{i18n.t(`screens.transport.${opt}Title`)}</SpecifiButtonText>
    </ModalButton>
  )

  // CityMapper && GoogleMaps => l'adresse du bâtiment
  // Waze => addresse du parking

  const ModalContent = () => {
    const buildingLatitude =
      hasMultipleBuildings && !!selectedBuilding && selectedBuilding.buildingLatitude
        ? selectedBuilding.buildingLatitude
        : site?.latitude
    const buildingLongitude =
      hasMultipleBuildings && !!selectedBuilding && selectedBuilding.buildingLongitude
        ? selectedBuilding.buildingLongitude
        : site?.longitude
    const buildingAddress =
      hasMultipleBuildings && !!selectedBuilding && selectedBuilding.buildingAddress
        ? selectedBuilding.buildingAddress
        : site?.address
    const parkingLatitude = selectedBuilding?.buildingParkingLatitude || site?.latitude
    const parkingLongitude = selectedBuilding?.buildingLongitude || site?.longitude
    const citymapperLink =
      buildingLatitude &&
      buildingLongitude &&
      buildingAddress &&
      site &&
      `https://citymapper.com/directions?endcoord=${buildingLatitude}%2C${buildingLongitude}&endname=${site.name}&endaddress=${buildingAddress}`
    const wazeLink =
      parkingLatitude &&
      parkingLongitude &&
      `https://www.waze.com/${i18n.lang}/live-map/directions?navigate=yes&to=ll.${parkingLatitude}%2C${parkingLongitude}`
    const googlemapsLink = buildingAddress && `https://www.google.com/maps/search/?api=1&query=${buildingAddress}`

    return (
      <ModalContainer>
        <ModalHeaderContainer>
          <ModaleTitle>{i18n.t('screens.transport.calculateItinerary')}</ModaleTitle>
          <CrossContainer onClick={Modal.close}>
            <Icons name="cross" size={20} color={Theme.colors.black} />
          </CrossContainer>
        </ModalHeaderContainer>
        {renderAdressesInfos(true)}
        <ModalButtonsContainer>
          {renderModalButton('citymapper', 'city-mapper', citymapperLink)}
          {renderModalButton('waze', 'waze', wazeLink)}
          {renderModalButton('googlemaps', 'maps', googlemapsLink)}
        </ModalButtonsContainer>
      </ModalContainer>
    )
  }

  React.useEffect(() => {
    const timeout = setTimeout(() => {
      setCurrent(new Date())
      if (site && user) {
        api.traffic(site.id, user.type).then(setTraffic).catch(Logger.error)
        api
          .bikes(site.id, user.type)
          .then((b) =>
            setBikes({
              bikes: b.bikes.sort((a, b) =>
                a.publicName && b.publicName ? a.publicName?.localeCompare(b.publicName) : 0
              ),
              city: b.city,
            })
          )
          .catch(Logger.error)
      }
    }, REFRESH_DELAY * 1000)

    return () => clearTimeout(timeout)
  }, [current])

  React.useEffect(() => {
    if (site && user) {
      fetchBuildings()
      api
        .traffic(site.id, user.type)
        .then(setTraffic)
        .catch(Logger.error)
        .finally(() => {
          setTransportsLoaded(true)
          setFavoriteLoaded(true)
        })
      api
        .bikes(site.id, user.type)
        .then((b) =>
          setBikes({
            bikes: b.bikes.sort((a, b) =>
              a.publicName && b.publicName ? a.publicName?.localeCompare(b.publicName) : 0
            ),
            city: b.city,
          })
        )
        .catch(Logger.error)
    }

    if (user) {
      analytics.track({ screenName: analyticsKeys.transports, userType: user.type, currentSite: site, mySites })
    }
  }, [])

  const fetchBuildings = () => {
    if (user && site) {
      interventionApi
        .getAllFloorLocations(site.id, user.type)
        .then((res) => {
          const allBuildings = res.locations
            .reduce((acc, cur) => {
              const findBuilding = acc.some((b) => b.buildingId === cur.buildingId)
              if (!findBuilding) {
                acc.push(cur)
              }
              return acc
            }, [] as LocationV6[])
            .sort((a, b) => (a.buildingName || '').localeCompare(b.buildingName || ''))
          setBuildings(allBuildings)
          if (allBuildings.length > 0) {
            setSelectedBuilding(allBuildings[0])
          }
        })
        .catch(Logger.error)
    }
  }

  const toggleModifyFavorite = () => setModifying(!modifying)

  const renderIcon = (name: LineTransportIconName, category: TransportStation['category']) => (
    <IconTitleContainer>
      {!!category && (
        <IconContainer>
          <TrafficIconContainer>
            <CategoryIcon name={`${city}_${category}` as CategoryTransportIconName} size={24} />
          </TrafficIconContainer>
        </IconContainer>
      )}
      <IconContainer>
        <LineIcon name={name} size={23} ariaLabel={name.replace(/\D/g, '')} />
      </IconContainer>
    </IconTitleContainer>
  )

  const renderTag = (schedule: Schedule, index: number, distance?: string, favorite = false) => (
    <TagContainer key={`${schedule.direction} - ${schedule.time} - ${favorite} - ${distance} - ${index}`}>
      <Tag
        label={distance}
        backgroundColor={favorite ? Theme.colors.white : Theme.colors.mediumDarkGrey}
        color={Theme.colors.darkGrey}
        highlight={distance === i18n.t('screens.transport.now') || distance === i18n.t('screens.transport.next')}
      />
    </TagContainer>
  )

  const renderFavorite = (station: FavoriteDirection) => {
    if (!site) {
      return null
    }

    const favoriteStation = displayedStations?.find(
      (s) => s.name === station.station && s.line === station.line && s.schedules[0].direction === station.direction
    )

    const schedule = getDistances(i18n, new Date(), site.timezone, favoriteStation).filter((s) => !!s)

    const deleteFromFavorite = () => deleteFavorite(station)

    return (
      favoriteStation && (
        <FavoriteContainer key={`${station.station} - ${station.line} - ${station.direction}`}>
          <IconTitleContainer>
            {renderIcon(station.line as LineTransportIconName, station.category)}
            <DirectionTitle>{favoriteStation.schedules[0].direction}</DirectionTitle>
          </IconTitleContainer>
          <Tags>
            {!favoriteLoaded ? (
              <NoNextDistanceTag>{i18n.t('common.loading')}...</NoNextDistanceTag>
            ) : favoriteStation && schedule.length > 0 ? (
              schedule.map((d, i) => renderTag(favoriteStation.schedules[0], i, d, true))
            ) : (
              <NoNextDistanceTag>{i18n.t('screens.transport.noNextTransport')}</NoNextDistanceTag>
            )}
          </Tags>
          {modifying && (
            <DeleteButton
              onClick={deleteFromFavorite}
              aria-label={i18n.t('label.ariaLabel.transport.deletePinnedLine')}>
              <DeleteBackground>
                <Icons name="cross" size={15} color={Theme.colors.black} />
              </DeleteBackground>
            </DeleteButton>
          )}
        </FavoriteContainer>
      )
    )
  }

  const renderDirection = (station: TransportStation, notLast: boolean) => {
    if (!site) {
      return null
    }

    const schedule = getDistances(i18n, new Date(), site.timezone, station).filter((s) => !!s)
    const alreadyFavorite = !!favorites.find(
      (s) =>
        s.station === station.name &&
        s.line === station.line &&
        s.direction === station.schedules[0].direction &&
        s.siteId === site?.id
    )

    const toggleFavorite = () => favoriteFunc(alreadyFavorite, station)

    return (
      <DirectionContainer key={station.schedules[0].direction}>
        <IconTitleContainer>
          {renderIcon(station.line as LineTransportIconName, station.category)}
          <DirectionTitle>{station.schedules[0].direction}</DirectionTitle>
        </IconTitleContainer>
        <Tags>
          {schedule.length > 0 ? (
            schedule.map((d, i) => renderTag(station.schedules[0], i, d))
          ) : (
            <NoNextDistanceTag>{i18n.t('screens.transport.noNextTransport')}</NoNextDistanceTag>
          )}
        </Tags>
        <AddFavoriteContainer onClick={toggleFavorite}>
          <Icons name={alreadyFavorite ? 'less' : 'plus'} color={Theme.colors.blue} size={15} />
          <AddFavoriteText>
            {i18n.t(`screens.transport.${alreadyFavorite ? 'removeFavorites' : 'addFavorites'}`)}
          </AddFavoriteText>
        </AddFavoriteContainer>
        {notLast && <BlueLineBreak role="presentation" />}
      </DirectionContainer>
    )
  }

  const renderLine = (lines: LineStation, station: DisplayedStation) => {
    const displayedLineStation = displayedStations?.filter((s) => s.line === lines.name && s.name === station.name)
    return (
      <LineContainer key={`${lines.name} - ${station.name}`}>
        <LineTitle>
          {i18n.t([`screens.transport.lineName.${lines.name}`, 'screens.transport.lineName.UNKNOWN'])} - {station.name}
        </LineTitle>
        <div>
          {displayedLineStation && displayedLineStation.length > 0 ? (
            displayedLineStation.map((s, i) => renderDirection(s, displayedLineStation.length !== i + 1))
          ) : (
            <StationErrorMessageContainer>
              <DirectionTitle>{i18n.t('screens.transport.noNextTransport')}</DirectionTitle>
            </StationErrorMessageContainer>
          )}
        </div>
      </LineContainer>
    )
  }

  const renderBike = (station: BikeStation, index: number, list?: BikeStation[]) => {
    const notLast = list && list.length !== index + 1
    return (
      <BikeStation key={station.id}>
        <IconTitleContainer>
          <CategoryIcon name={`${city}_VELO` as CategoryTransportIconName} size={22} />
          <BikeDirectionTitle>{station.publicName}</BikeDirectionTitle>
        </IconTitleContainer>
        <AvailabilityContainer>
          <IconTitleContainer>
            <CategoryIcon name="VELO" size={20} />
            <BikeNumber>{i18n.t('screens.transport.bikeLeft', { count: station.bikes })}</BikeNumber>
          </IconTitleContainer>
          <IconTitleContainer>
            <CategoryIcon name="PARKING" size={20} />
            <BikePlace>{i18n.t('screens.transport.bikeStandLeft', { count: station.stands })}</BikePlace>
          </IconTitleContainer>
        </AvailabilityContainer>
        {notLast && <BlueLineBreak role="presentation" />}
      </BikeStation>
    )
  }

  const renderAdressesInfos = (withCopy?: boolean) => (
    <>
      {hasMultipleBuildings && !!selectedBuilding && (
        <SelectedBuildingName>
          {i18n.t('screens.transport.buildingName', {
            buildingName: selectedBuilding.buildingName,
          })}
        </SelectedBuildingName>
      )}
      <TopHeaderInfosContainer>
        {hasMultipleBuildings && (
          <IconContainer>
            <Icons name="building" size={20} color={Theme.colors.blue} />
          </IconContainer>
        )}
        <AddressName>
          {!!selectedBuilding && hasMultipleBuildings ? selectedBuilding.buildingAddress : site?.address}
        </AddressName>
        {withCopy && (
          <CopyContainer
            onClick={() => {
              if (site && site.address && !hasMultipleBuildings) {
                navigator.clipboard.writeText(site.address)
              } else if (hasMultipleBuildings && !!selectedBuilding && selectedBuilding.buildingAddress) {
                navigator.clipboard.writeText(selectedBuilding.buildingAddress)
              }
              Toast.open(() => ToastContent(i18n.t('screens.transport.addressCopied'), Theme.colors.darkGrey), 'top')
            }}>
            <Icons name="copy" size={20} color={Theme.colors.middleGrey} />
          </CopyContainer>
        )}
      </TopHeaderInfosContainer>
      {hasMultipleBuildings && !!selectedBuilding && (
        <TopHeaderInfosContainer>
          <IconContainer>
            <Icons name="parking" size={20} color={Theme.colors.blue} />
          </IconContainer>
          <AddressName>{selectedBuilding.buildingParkingAddress}</AddressName>
          {withCopy && (
            <CopyContainer
              onClick={() => {
                if (selectedBuilding.buildingParkingAddress) {
                  navigator.clipboard.writeText(selectedBuilding.buildingParkingAddress)
                }
                Toast.open(() => ToastContent(i18n.t('screens.transport.addressCopied'), Theme.colors.darkGrey), 'top')
              }}>
              <Icons name="copy" size={20} color={Theme.colors.middleGrey} />
            </CopyContainer>
          )}
        </TopHeaderInfosContainer>
      )}
    </>
  )

  if (!site || !user) {
    return null
  }

  return (
    <>
      <TitleHelmet title={i18n.t('screens.transport.title')} />
      <Breadcrumb screen="transport" path="transport" navigation={navigation} />

      <MainContainer>
        {!!showedBanner && (
          <Banner banner={showedBanner} marginLeft={0.045 * windowSize} marginRight={0.045 * windowSize} />
        )}
        <SurveyBanner findFeature={findFeature} />
        <TopContainer>
          <div>
            <SiteName>{site.name}</SiteName>
            {hasMultipleBuildings && (
              <div>
                <AddressName>{i18n.t('screens.transport.selectBuilding')}</AddressName>
                <HorizontalContainer>
                  <HorizontalList>
                    {buildings.map((building, index) => (
                      <BuildingButton
                        key={index}
                        isSelected={building.buildingId === selectedBuilding?.buildingId}
                        onClick={() => setSelectedBuilding(building)}>
                        <Building isSelected={building.buildingId === selectedBuilding?.buildingId}>
                          {building.buildingName}
                        </Building>
                      </BuildingButton>
                    ))}
                  </HorizontalList>
                </HorizontalContainer>
              </div>
            )}
            {renderAdressesInfos()}
            <ButtonsContainer>
              <SpecificButton onClick={() => Modal.open(ModalContent)}>
                <Icons name="itinerary" size={20} color={Theme.colors.blue} />
                <SpecifiButtonText>{i18n.t('screens.transport.calculateItinerary')}</SpecifiButtonText>
              </SpecificButton>
              {isInFrance && user.type !== 'EXTERNAL' && (
                <SpecificButton
                  onClick={() => {
                    externalLinkTracking(CARSHARING_LINK, analyticsKeys.transports, user, site, mySites)
                    window.open(CARSHARING_LINK)
                  }}>
                  <Icons name="car-sharing" size={20} color={Theme.colors.blue} />
                  <SpecifiButtonText>{i18n.t('screens.transport.carSharing')}</SpecifiButtonText>
                </SpecificButton>
              )}
            </ButtonsContainer>
          </div>
          {(currentBreakpoint === 'laptop' || currentBreakpoint === 'big') && (
            <SiteImageContainer>
              <SiteImage src={site.image || require('components/image/assets/site_placeholder.jpg').default} />
            </SiteImageContainer>
          )}
        </TopContainer>
        <BottomContainer infoTraffic={site.name === 'Urban Garden'}>
          <PinnedLines disabled={!modifying} autoFocus={false}>
            <TopTitleContainer>
              <FavoriteTitleContainer>
                <CategoryTitle>{i18n.t('screens.transport.favorites')}</CategoryTitle>
              </FavoriteTitleContainer>
              {favorites.length > 0 && (
                <ModifyText
                  onClick={toggleModifyFavorite}
                  aria-label={i18n.t(
                    `label.ariaLabel.transport.${modifying ? 'finishPinnedLines' : 'modifyPinnedLines'}`
                  )}>
                  {i18n.t(`common.${modifying ? 'finish' : 'modify'}`)}
                </ModifyText>
              )}
            </TopTitleContainer>
            {displayedFavorites.length > 0 && site ? (
              <GridContainer>{displayedFavorites.map(renderFavorite)}</GridContainer>
            ) : (
              <NoFavoritesMessage>{i18n.t('screens.transport.noFavorites')}</NoFavoritesMessage>
            )}
          </PinnedLines>
          <TrafficContainer>
            <CategoryTitle>{i18n.t('screens.transport.nextLines')}</CategoryTitle>
            {allTypesLines.length > 2 && (
              <HorizontalContainer>
                <HorizontalList>
                  {allTypesLines.map((typeLine, index) => (
                    <BuildingButton
                      key={index}
                      isSelected={typeLine === selectedTypeLine}
                      onClick={() => setSelectedTypeLine(typeLine)}>
                      <Building isSelected={typeLine === selectedTypeLine}>
                        {i18n.t(`screens.transport.types.${typeLine}`)}
                      </Building>
                    </BuildingButton>
                  ))}
                </HorizontalList>
              </HorizontalContainer>
            )}
            <GridContainer>
              {!transportsLoaded ? (
                <LoaderContainer>
                  <Loader type="TailSpin" color={Theme.colors.blue} />
                </LoaderContainer>
              ) : displayedStations && stationsInfo.length > 0 ? (
                stationsInfo.map((s) => s.lines.map((l) => renderLine(l, s)))
              ) : (
                <DirectionTitle>{i18n.t('screens.transport.noNextTransport')}</DirectionTitle>
              )}
            </GridContainer>
          </TrafficContainer>
        </BottomContainer>
        <SubBottomContainer>
          {!!bikes && !!bikes.bikes && bikes.bikes.length > 0 && bikes.city !== 'UNKNOWN' && (
            <BikesContainer>
              <CategoryTitle>{i18n.t(`screens.transport.availableBike${bikes.city}`)}</CategoryTitle>
              <Bikes>{bikes.bikes.map(renderBike)}</Bikes>
            </BikesContainer>
          )}

          <TrafficInfoContainer>
            <CategoryTitle>{i18n.t(`screens.transport.trafficInfo`)}</CategoryTitle>
            <IframeContainer>
              <iframe
                title={i18n.t('label.ariaLabel.transport.onlymoov')}
                src={site.onlymoovEnabled ? ONLYMOOV_LINK : wazeIFrame}
                width="100%"
                height="420px"
                scrolling="no"
                style={{ border: 0 }}
              />
            </IframeContainer>
          </TrafficInfoContainer>
        </SubBottomContainer>
      </MainContainer>
    </>
  )
}

// CONTAINERS

const MainContainer = styled('div')`
  display: flex;
  flex-direction: column;
  margin-top: 26px;
  min-height: calc(100vh - 116px);
  overflow-x: hidden;
  @media only screen and (max-width: ${windowSizeBreakpoints.phone}px) {
    margin-left: 0px;
    min-width: 100%;
  }
`
const TopContainer = styled('div')`
  margin-left: 5%;
  margin-bottom: 45px;
  display: flex;
  flex: 1;
  flex-direction: row;
  align-items: center;
  @media only screen and (max-width: ${windowSizeBreakpoints.medium}px) {
    display: block;
  }
`
const BottomContainer = styled('div')<{ infoTraffic: boolean }>`
  display: grid;
  flex: 1;
  min-height: 430px;
  grid-template-columns: repeat(3, 1fr);
  padding: 40px 0px 0px 5%;
  ${(props) => !props.infoTraffic && 'padding-bottom: 4%'};
  background-color: ${(props) => props.theme.colors.mediumLightGrey};
  @media only screen and (max-width: ${windowSizeBreakpoints.big}px) {
    display: flex;
    flex-direction: column;
    padding-right: 3%;
  }
  @media only screen and (max-width: ${windowSizeBreakpoints.phone}px) {
    margin-right: 5px;
  }
`
const SubBottomContainer = styled('div')`
  display: flex;
  flex: 1;
  padding-left: 5%;
  padding-right: 40px;
  background-color: ${(props) => props.theme.colors.mediumLightGrey};
  flex-direction: row;
  @media only screen and (max-width: ${windowSizeBreakpoints.medium}px) {
    flex-direction: column;
    padding-right: 1.5%;
  }
`
const TrafficContainer = styled('div')`
  margin-right: 15px;
  grid-column: 1 / 3;
  @media only screen and (max-width: ${windowSizeBreakpoints.big}px) {
    margin-right: 0px;
  }
`
const BikesContainer = styled('div')`
  display: flex;
  flex: 1;
  flex-direction: column;
`
const GridContainer = styled('div')`
  display: grid;
  grid-template-columns: repeat(2, 50%);
  margin-left: -5px;
  @media only screen and (max-width: ${windowSizeBreakpoints.small}px) {
    display: flex;
    flex-direction: column;
  }
`
const LineContainer = styled('div')`
  background-color: ${(props) => props.theme.colors.white};
  padding: 20px;
  padding-bottom: 3px;
  margin: 5px;
  box-shadow: 0px 0px 15px ${(props) => props.theme.colors.shadow};
  min-width: 300px;
  @media only screen and (max-width: ${windowSizeBreakpoints.medium}px) {
    min-width: unset;
    margin-right: 0px;
  }
  @media only screen and (max-width: ${windowSizeBreakpoints.small}px) {
    margin-right: 0px;
  }
`
const DirectionContainer = styled('div')`
  margin: 15px 0px;
`
const Tags = styled('div')`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
`
const TagContainer = styled('div')`
  margin: 10px 7px 10px 0px;
`
const Bikes = styled('div')`
  background-color: ${(props) => props.theme.colors.white};
  padding: 20px;
  padding-top: 5px;
  box-shadow: 0px 0px 15px ${(props) => props.theme.colors.shadow};
  width: auto;
  margin-right: 12px;
`
const AvailabilityContainer = styled('div')`
  display: flex;
  flex-direction: column;
  margin: 10px 0px;
  gap: 10px;
`
const BikeStation = styled('div')`
  padding-top: 20px;
`
const FavoriteContainer = styled('div')`
  position: relative;
  padding: 20px;
  padding-bottom: 8px;
  margin: 5px;
  background-color: ${(props) => props.theme.colors.white};
  @media only screen and (max-width: ${windowSizeBreakpoints.small}px) {
    margin-right: 0px;
  }
`
const TopTitleContainer = styled('div')`
  display: flex;
  align-items: center;
  margin-right: 6px;
`
const FavoriteTitleContainer = styled('div')`
  flex: 1;
`
const PinnedLines = styled(FocusLock)`
  grid-column: 1 / 3;
  margin-right: 40px;
  @media only screen and (max-width: ${windowSizeBreakpoints.big}px) {
    margin-right: 0px;
  }
`
const ButtonsContainer = styled('div')`
  display: flex;
  flex-direction: row;
  padding-right: 10px;
  gap: 0 8px;
  padding-top: 8px;
  @media only screen and (max-width: ${windowSizeBreakpoints.phone}px) {
    flex-direction: column;
    gap: 8px 0;
    margin-right: 5%;
  }
`
const TopHeaderInfosContainer = styled('div')`
  display: flex;
  flex-direction: row;
  padding-bottom: 12px;
  align-items: center;
`
const ModalContainer = styled('div')`
  display: flex;
  flex-direction: column;
  padding: 24px;
  margin-top: -10px;
`
const ModalButtonsContainer = styled('div')`
  display: flex;
  flex: 1;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 0 16px;
  margin-top: 20px;
`
const CopyContainer = styled('button')`
  padding: 0px 8px;
`
const HorizontalContainer = styled('div')`
  width: 100%;
  overflow-x: auto;
  white-space: no-wrap;
  box-sizing: border-box;
  margin-left: -8px;
`
const HorizontalList = styled('div')`
  display: inline-flex;
  gap: 0 12px;
  margin: 12px 12px 28px 0;
  margin-left: 8px;
`
const IconTitleContainer = styled('div')`
  display: flex;
  align-items: center;
  gap: 10px;
`
const IconContainer = styled('div')`
  display: flex;
  align-items: center;
  margin-right: 5px;
`
const LoaderContainer = styled('div')`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 15%;
`
const TrafficIconContainer = styled('div')`
  margin-top: 4px;
`
const TrafficInfoContainer = styled('div')`
  display: flex;
  flex-direction: column;
  flex: 1;
`
const IframeContainer = styled('div')`
  width: 100%;
  @media only screen and (max-width: ${windowSizeBreakpoints.big}px) {
    width: auto;
    padding-right: 5px;
  }
`

const SpecificButton = styled('button')`
  display: flex;
  flex: 1;
  margin: 0;
  width: 205px;
  padding: 12px 18px;
  gap: 0 8px;
  align-items: center;
  justify-content: center;
  border: 1px solid ${(props) => props.theme.colors.middleGrey};
  @media only screen and (max-width: ${windowSizeBreakpoints.phone}px) {
    width: 100%;
    padding: 8px 18px;
  }
`

const ModalButton = styled('button')`
  display: flex;
  margin: 0;
  width: 168px;
  margin: 0;
  padding: 14px;
  gap: 0 12px;
  align-items: center;
  justify-content: center;
  border: 1px solid ${(props) => props.theme.colors.middleGrey};
  @media only screen and (max-width: ${windowSizeBreakpoints.small}px) {
    margin-bottom: 12px;
    width: 100%;
  }
`

const BuildingButton = styled('button')<{ isSelected: boolean }>`
  display: flex;
  flex: 0 0 auto;
  margin: 0;
  min-width: 80px;
  max-width: 120px;
  height: 40px;
  align-items: center;
  justify-content: center;
  background-color: ${(props) => (props.isSelected ? props.theme.colors.blue : props.theme.colors.white)};
  padding: 12px;
  box-shadow: 0px 0px 5px 1px ${(props) => props.theme.colors.shadow};
`

const Building = styled('p')<{ isSelected: boolean }>`
  ${(props) => props.theme.fonts.labelBold};
  color: ${(props) => (props.isSelected ? props.theme.colors.white : props.theme.colors.black)};
`

const SpecifiButtonText = styled('p')`
  margin: 0;
  ${(props) => props.theme.fonts.body};
  text-align: center;
`

const SiteName = styled('p')`
  ${(props) => props.theme.fonts.h1Bold};
  margin: 0;
  padding-bottom: 16px;
`

const SelectedBuildingName = styled('p')`
  ${(props) => props.theme.fonts.bodyBold};
  margin: 0;
  padding-bottom: 14px;
`

const AddressName = styled('p')`
  ${(props) => props.theme.fonts.menuSubtitle};
  margin: 0;
`

const SiteImageContainer = styled('div')`
  display: flex;
  flex: 1;
  justify-content: flex-end;
  margin-right: 100px;
`

const SiteImage = styled('img')`
  width: 444px;
  height: 270px;
  object-fit: cover;
  background-color: ${(props) => props.theme.colors.middleGrey};
`

// TEXTES

const CategoryTitle = styled('h1')`
  ${(props) => props.theme.fonts.bodyBold}
  font-size: 24px;
  line-height: 26px;
  min-width: 400px;
  margin-bottom: 16px;
  @media only screen and (max-width: ${windowSizeBreakpoints.small}px) {
    min-width: 200px;
  }
`
const ModalHeaderContainer = styled('div')`
  display: flex;
  flex-direction: row;
  align-items: center;
`
const CrossContainer = styled('button')``
const ModaleTitle = styled('h1')`
  ${(props) => props.theme.fonts.h1Bold};
  margin-bottom: 24px;
  display: flex;
  flex: 1;
`
const LineTitle = styled('h2')`
  ${(props) => props.theme.fonts.bodyBold}
  font-size: 18px;
  margin-top: 0px;
`
const DirectionTitle = styled('h3')`
  ${(props) => props.theme.fonts.body}
  margin: 0px;
`
const BikeDirectionTitle = styled('h3')`
  ${(props) => props.theme.fonts.bodyBold}
  margin: 0px;
  margin-left: 8px;
`
const BikeNumber = styled('p')`
  ${(props) => props.theme.fonts.body};
  margin: 0;
`
const BikePlace = styled('p')`
  ${(props) => props.theme.fonts.body};
  margin: 0;
`
const AddFavoriteText = styled('p')`
  ${(props) => props.theme.fonts.label}
  margin: 0px 0px 0px 8px;
  padding: 3px 0px;
`
const ModifyText = styled('button')`
  ${(props) => props.theme.fonts.linkUnderlined}
  &:hover {
    color: ${(props) => props.theme.colors.blue};
  }
  :focus-visible {
    outline: 2px solid ${(props) => props.theme.colors.darkBlue};
  }
`
const NoFavoritesMessage = styled('p')`
  ${(props) => props.theme.fonts.body}
`
const NoNextDistanceTag = styled('p')`
  ${(props) => props.theme.fonts.body};
  margin: 12px 0px 10px 10px;
  font-size: 14px;
`
const StationErrorMessageContainer = styled('div')`
  margin-bottom: 20px;
`

// AUTRES

const BlueLineBreak = styled('div')`
  width: 60px;
  height: 3px;
  background-color: ${(props) => props.theme.colors.blue};
  margin: 10px 0px;
  border-radius: 2px;
`
const DeleteButton = styled('button')`
  position: absolute;
  top: -4px;
  right: -4px;
  justify-content: center;
  align-items: center;
  :focus-visible {
    outline: 2px solid ${(props) => props.theme.colors.darkBlue};
  }
`
const DeleteBackground = styled('div')`
  display: flex;
  height: 22px;
  width: 22px;
  border-radius: 11px;
  background-color: ${(props) => props.theme.colors.white};
  align-items: center;
  justify-content: center;
  box-shadow: 0px 0px 10px ${(props) => props.theme.colors.shadow};
  &:hover {
    background-color: ${(props) => props.theme.colors.lightGrey};
  }
`
const AddFavoriteContainer = styled('button')`
  display: inline-flex;
  align-items: center;
  padding-right: 5px;
  margin: -5px 0px 9px 0px;
  &:hover {
    ${AddFavoriteText} {
      color: ${(props) => props.theme.colors.blue};
    }
  }
  :focus-visible {
    outline: 2px solid ${(props) => props.theme.colors.darkBlue};
  }
`

export default TransportScreen
