import { subHours, isAfter, parseISO, setMinutes, setHours } from 'date-fns'
import { zonedTimeToUtc } from 'date-fns-tz'

export const todayVisitCanBeModified = (visit: PersonalVisit, timeZone: string) => {
  if (!visit.arrivalTime) {
    return false
  }
  const arrivalDateTimeString = `${visit.day}T${visit.arrivalTime}`
  const arrivalDateTime = parseISO(arrivalDateTimeString)

  const arrivalDateTimeUTC = zonedTimeToUtc(arrivalDateTime, timeZone)

  const oneHourBeforeArrivalUTC = subHours(arrivalDateTimeUTC, 1)

  const currentDateUTC = new Date()

  return !isAfter(currentDateUTC, oneHourBeforeArrivalUTC)
}

export const getDateTime = (time?: string) => {
  if (time) {
    try {
      const [hours, minutes] = time.split(':')
      if (hours === '00') {
        return undefined
      }
      return setMinutes(setHours(new Date(), parseInt(hours)), parseInt(minutes))
    } catch {
      return undefined
    }
  }
  return undefined
}

export const getApprovalColor = (approvalStatus: 'WAITING_FOR_APPROVAL' | 'APPROVED' | 'REJECTED', theme: Theme) => {
  if (approvalStatus === 'WAITING_FOR_APPROVAL') {
    return theme.colors.orange
  }
  if (approvalStatus === 'APPROVED') {
    return theme.colors.green
  }
  return theme.colors.red
}
