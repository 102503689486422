import Webservice from './Webservice'

const MAX_SEARCH_RESULT = 100

interface Page {
  pageNumber: number
  pageSize: number
  totalNumberOfPage: number
}

type PageReturn<U, V extends string> = Record<V, U[]> & Page

const fetchPages = <U>(
  userType: UserType,
  token: string,
  key: string,
  path: string,
  method: 'GET' | 'PUT' | 'POST' | 'DELETE' | 'PATCH',
  body?: any,
  pageNumber = 0,
  last: U[] = []
): Promise<U[]> => {
  const withParameters = path.includes('?')
  const fullPath = path + (withParameters ? '&' : '?') + `pageNumber=${pageNumber}&pageSize=${MAX_SEARCH_RESULT}`

  return Webservice<PageReturn<U, typeof key>>(
    userType,
    fullPath,
    method,
    {
      Authorization: `Bearer ${token}`,
    },
    undefined,
    body
  ).then((res) => {
    // extract datas from object
    const results = res[key]

    if (res.totalNumberOfPage > 1 && res.pageNumber + 1 < res.totalNumberOfPage) {
      // more to dowload
      return fetchPages(userType, token, key, path, method, body, pageNumber + 1, [...last, ...results])
    }

    //last page return previous results + last page results
    return [...last, ...results]
  })
}

export { fetchPages }
