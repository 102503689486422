const getCurrentBadgeWithSolde = (badges: BadgeV6[], siteId: string) => {
  const getAllBadges = badges.filter((b) => b.siteId === siteId)
  const findValidSolde = getAllBadges.find((b) => b.solde !== null && b.solde !== undefined)
  if (findValidSolde) {
    return findValidSolde
  }
  if (getAllBadges.length > 0) {
    return getAllBadges[0]
  }
  return undefined
}

export { getCurrentBadgeWithSolde }
