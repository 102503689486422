import * as React from 'react'
import styled from 'theme/styled-components'

import useI18n from 'i18n/useI18n'

import Icons from 'components/icons/Icons'

import { documentMimeTypesIntervention, imageMimeTypes } from '../utils'
import { Attachment } from '../InterventionScreen'

interface Props {
  gdprAccepted: boolean
  onUploadFile: (uploadEvent: React.ChangeEvent<HTMLInputElement>, onChange: (val: Attachment) => void) => void
  onChange: (val?: Attachment) => void
}

const AddPictureButtonComponent = ({ gdprAccepted, onUploadFile, onChange }: Props) => {
  const hiddenFileInput = React.useRef<HTMLInputElement>(null)
  const i18n = useI18n()
  return (
    <AddPictureButton
      onKeyPress={(e) => {
        if (e.key === 'Enter') {
          hiddenFileInput.current?.click()
        }
      }}
      onClick={() => hiddenFileInput.current?.click()}
      tabIndex={0}
      disabled={!gdprAccepted}
      aria-label={i18n.t('label.ariaLabel.incident.uploadPicture')}>
      <Icons name="plus" size={30} />
      <AddPictureInput
        disabled={!gdprAccepted}
        ref={hiddenFileInput}
        type="file"
        accept={[...Object.values(imageMimeTypes), ...Object.values(documentMimeTypesIntervention)].join(',')}
        onChange={(evt) => onUploadFile(evt, (val) => onChange(val))}
      />
    </AddPictureButton>
  )
}

export default AddPictureButtonComponent

const AddPictureButton = styled('button')<{ disabled?: boolean }>`
  width: 58px;
  height: 58px;
  background-color: ${(props) => props.theme.colors.blue10p};
  display: flex;
  align-items: center;
  justify-content: center;
  &:hover {
    cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};
  }
  :focus {
    outline: 2px solid ${(props) => props.theme.colors.darkBlue};
  }
`

const AddPictureInput = styled('input')`
  display: none;
`
