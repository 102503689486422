import mixpanel from 'mixpanel-browser'
import * as userStore from 'store/user/user'

import { sha256 } from 'js-sha256'
import config from 'core/src/config'

type AnalyticsUserType =
  | 'Occupant ENGIE'
  | 'Visiteur ENGIE'
  | 'Occupant EQUANS'
  | 'Visiteur EQUANS'
  | 'Externe'
  | undefined

type IncidentType = 'IA' | 'User'

mixpanel.init(config.mixpanelProjectToken, {
  api_host: config.mixpanelUrl,
  opt_out_tracking_by_default: true,
})

export const handleTracking = (enabled: boolean) => {
  if (enabled) {
    mixpanel.opt_in_tracking()
  } else {
    mixpanel.opt_out_tracking()
  }
}

const transformString = (text?: string) => {
  if (!text) {
    return undefined
  }
  return text.replace(' ', '_').toLowerCase()
}

export const analyticsKeys = {
  home: 'Home',
  account: 'Mon compte',
  newsList: 'Actualités Liste',
  newsDetails: 'Actualités Détail',
  transports: 'Transports',
  practicalInfoList: 'Informations pratiques Liste',
  practicalInfoDetails: 'Informations pratiques Détail',
  sites: 'Choix du site',
  map: 'Plans',
  cgu: 'CGU',
  desk: 'Réservation de bureau',
  guest: 'Pre enregistrement visiteur',
  room: 'Réservation de salle',
  restaurantList: 'Restauration Liste',
  restaurantDetails: 'Restauration Détail',
  directory: 'Annuaire',
  incident: "Demande d'intervention",
  fleet: 'Gestion des véhicules Fleet',
  thirdPartyApp: 'Apps tierces',
  zapfloor: 'Venue sur site',
  myMRS: 'My MRS',
  meetingCenter: 'Meeting Center',
  parking: 'Parking',
  parkingSharvy: 'Parking Sharvy',
  otherServices: 'Other services',
  boxIdeas: 'Boîte à idées',
  removeAccount: 'Suppression de compte',
  externalLink: 'Lien externe',
  legalNotices: 'Mentions légales',
  dayPlanner: 'Planificateur de journée',
}

export const analyticsActionKeys = {
  myBookings: 'Mes réservations',
  eventRoomLink: 'Réserver une salle Event',
  myFavorites: 'Mes favoris',
  mrsReloadBadge: 'Rechargement badge',
  planningSaveRefWeek: 'Enregistrement semaine de ref',
  planningApplyRefWeek: 'Application semaine de ref',
  planningSaveFavoriteZone: 'Enregistrement zone favorite',
  bookingList: 'Réservation depuis la liste',
  bookingMap: 'Réservation depuis la carte',
  bookingZone: 'Réservation par zone',
  roomService: 'Commande Room Service',
  poiInterventionClickButton: "Demande d'intervention Poi",
  poiRoomBookingClickButton: 'Détail salle réunion Poi',
  poiItineraryClickButton: 'Intinéraire Poi',
  askingRemovingAccount: 'Clic suppression de compte',
  confirmRemovingAccount: 'Confirmation suppression de compte',
  openExternalLink: 'Ouverture lien externe',
  fleetSendForm: 'Formulaire fleet soumis',
  dayPlannerNewBooking: 'Nouvelle réservation',
  dayPlannerModifyBooking: 'Modification de réservation',
}

type AnalyticsParams = {
  screenName: string
  userType?: UserType
  currentSite?: Site
  mySites?: PersonalSite[]
  label?: string
  thirdPartyLabel?: string
  origin?: string
  action?: string
  url?: string
  incidentProps?: {
    success: boolean
    image: boolean
    description: IncidentType
    typo: IncidentType
    service: IncidentType
  }
}

export const externalLinkTracking = (
  url: string,
  origin: string,
  user?: UserSelf,
  site?: Site,
  mySites?: PersonalSite[],
  label?: string
) => {
  service.track({
    screenName: analyticsKeys.externalLink,
    userType: user?.type,
    currentSite: site,
    mySites,
    url,
    origin,
    action: analyticsActionKeys.openExternalLink,
    label,
  })
}

const isExternal = (mySites: PersonalSite[], currentSiteId: string) => !mySites.find((s) => s.id === currentSiteId)

const getAnalyticsUserType = (
  userType: UserType,
  mySites: PersonalSite[],
  currentSiteId: string
): AnalyticsUserType => {
  switch (userType) {
    case 'ENGIE':
      return isExternal(mySites, currentSiteId) ? 'Visiteur ENGIE' : 'Occupant ENGIE'
    case 'EQUANS':
      return isExternal(mySites, currentSiteId) ? 'Visiteur EQUANS' : 'Occupant EQUANS'
    case 'EXTERNAL':
      return 'Externe'
    default:
      return undefined
  }
}

const transformAnalyticsParams = ({
  screenName,
  userType,
  currentSite,
  mySites,
  label,
  thirdPartyLabel,
  action,
  origin,
  url,
  incidentProps,
}: AnalyticsParams) => {
  const analyticsUserType: AnalyticsUserType =
    !!userType && !!mySites && !!currentSite ? getAnalyticsUserType(userType, mySites, currentSite.id) : undefined
  const findBadge = !!currentSite && !!mySites ? mySites.find((mySite) => mySite.id === currentSite.id) : undefined
  const badges = userStore.store.getState().badges
  const findPrincipalBadge = badges.find((b) => b.isPrincipal)
  return {
    label: label ? transformString(label) : undefined,
    user_type: analyticsUserType ? transformString(analyticsUserType) : undefined,
    screen_name: transformString(screenName),
    site: currentSite ? currentSite.name : undefined,
    third_party: transformString(thirdPartyLabel),
    action,
    origin,
    url,
    has_badge: !!currentSite ? (!!findBadge ? 'YES' : 'NO') : undefined,
    badge_principal: !!findPrincipalBadge ? findPrincipalBadge.campus : undefined,
    ...incidentProps,
  }
}

const service = {
  track: ({
    screenName,
    userType,
    currentSite,
    mySites,
    label,
    thirdPartyLabel,
    action = 'Ouverture page',
    origin,
    url,
    incidentProps,
  }: AnalyticsParams) =>
    mixpanel.track(
      screenName,
      transformAnalyticsParams({
        screenName,
        userType,
        currentSite,
        mySites,
        label,
        thirdPartyLabel,
        action,
        origin,
        url,
        incidentProps,
      })
    ),
  reset: () => mixpanel.reset(),
  identify: (uniqueId: string) => mixpanel.identify(sha256(uniqueId)),
}

export default service
