import * as React from 'react'
import styled from 'theme/styled-components'
import Theme from 'theme/Theme'

import useI18n from 'i18n/useI18n'
import Logger from 'utils/Logger'
import { windowSizeBreakpoints } from 'utils/breakpoints'
import useBreakpoint from 'utils/useBreakpoints'
import useResizeObserver from 'use-resize-observer'

import api from 'map/mapApi'
import useMap from 'map/useMap'
import { AreaWithStatusColor } from 'map/Map'

import useReducer from 'store/useReducer'
import * as siteStore from 'sites/store/siteStore'
import * as userStore from 'store/user/user'
import * as themeStore from 'theme/store'

import Modal from 'components/modal/Modal'
import Icons from 'components/icons/Icons'
import PlaceSearch, { Status } from 'components/map/PlaceSearch'
import RoomInfo from 'intervention/components/RoomInfo'
import RoomMapInfo from 'intervention/components/RoomMapInfo'

const REFRESH_DELAY = 60 //secondes

interface Props {
  setSelectedRoom: (room: Area) => void
  selectedFloor?: APILocation
  selectedFloorBuilding?: string
  selectedBuilding?: string
  setNoLocation: () => void
  hideLocation?: boolean
  goBack?: () => void
}

const RoomSelector = ({
  setSelectedRoom,
  selectedFloor,
  setNoLocation,
  hideLocation,
  selectedBuilding,
  selectedFloorBuilding,
  goBack,
}: Props) => {
  const i18n = useI18n()
  const [, bp] = useBreakpoint()

  const site = useReducer(siteStore.store, (s) => s.site)
  const user = useReducer(userStore.store, (s) => s.user)
  const isAccessible = useReducer(themeStore.store, (s) => s.theme) === 'accessible'
  const mySites = useReducer(siteStore.store, (s) => s.mySites)

  const hasMap = !!site?.webVisioglobeHash

  // is user a visitor or engie employee
  const isVisitor =
    mySites && site && user ? !mySites.find(({ id }) => id === site.id) || user.type === 'EXTERNAL' : true

  const isSelectedFloorNotNull = !!selectedFloor || selectedFloor === 0

  // mode map, list, or both
  const [mode, setMode] = React.useState<'map' | 'list' | 'all'>(bp === 'phone' ? 'list' : 'all')

  // map items
  const [areas, setAreas] = React.useState<AreaWithStatusColor[]>([])
  const [areasStatus, setAreaStatus] = React.useState<APIStatus[]>([])

  const [resultList, setResultList] = React.useState<Area[]>([])
  const [searchText, setSearchText] = React.useState('')
  const [researchStatus, setResearchStatus] = React.useState<Status>(isSelectedFloorNotNull ? 'loading' : 'notStarted')

  const { ref: mainContainerRef, width: mainContainerWidth = 1, height: mainContainerHeight = 1 } = useResizeObserver<
    HTMLDivElement
  >()

  const mapOffset = React.useMemo(() => (mode === 'all' ? 400 : 0), [mode])

  const [ref, status, , , place, , actions] = useMap(
    areas,
    areasStatus,
    isAccessible,
    site?.webVisioglobeHash || '',
    i18n.lang,
    undefined,
    undefined,
    undefined,
    mainContainerWidth - mapOffset,
    mainContainerHeight
  )

  React.useEffect(() => {
    // update render when breakpoint change
    if (bp === 'phone') {
      setMode('list')
    } else {
      setMode('all')
    }
  }, [bp === 'phone'])

  React.useEffect(() => {
    if (!!site && !!user && status === 'initialized') {
      api
        .getAllAreas(site.id, user.type)
        .then((res) => setAreas(res.areas))
        .catch(Logger.error)
    }
  }, [status])

  // On fetch les pois à interval régulier
  React.useEffect(() => {
    if (areas.length > 0) {
      updatePoisStatus()
      const timeout = setInterval(() => {
        updatePoisStatus()
      }, REFRESH_DELAY * 1000)

      return () => clearInterval(timeout)
    }
  }, [areas.length])

  React.useEffect(() => {
    if (selectedFloor?.visioglobeBuildingId && status === 'initialized' && mode !== 'list') {
      actions.goTo(selectedFloor.visioglobeBuildingId, selectedFloor.visioglobeFloorId)
    }
  }, [selectedFloor, status, mode])

  const updatePoisStatus = () => {
    if (site && user) {
      api
        .getAllStatus(site.id, user.type)
        .then((res) => {
          setAreaStatus(res.status)
          actions.updatePOIs(res.status, isAccessible, isVisitor)
        })
        .catch(Logger.error)
    }
  }

  const searchPlaces = (searchText: string) => {
    if (site && user && (searchText.length > 2 || isSelectedFloorNotNull)) {
      setResearchStatus('loading')
      api
        .getAllSearchedPlaces(
          site.id,
          user.type,
          {
            query: searchText,
            floor: selectedFloor?.level,
            hasIntervention: true,
          },
          selectedBuilding || selectedFloorBuilding
        )
        .then((res) => {
          setResultList(res.sort((a, b) => a.name.localeCompare(b.name)))
          setResearchStatus('ready')
        })
        .catch((err) => {
          setResearchStatus('error')
          Logger.error(err)
        })
    }
  }

  const renderRoomInfo = (room: Area, i: number) => (
    <RoomInfo setSelectedRoom={setSelectedRoom} key={room.id} room={room} isLast={resultList.length === i + 1} />
  )

  return (
    <MainContainer hasMap={hasMap}>
      <TitleContainer>
        {!!goBack && (
          <CrossContainer
            onClick={() => {
              goBack()
              Modal.close()
            }}>
            <Icons name="chevron_left" size={25} color={Theme.colors.blue} />
          </CrossContainer>
        )}

        <Title>{i18n.t('screens.incident.form.whichLocation')}</Title>
        <CrossContainer onClick={Modal.close} aria-label={i18n.t('label.ariaLabel.windowCrossIcon')}>
          <Icons name="cross" size={25} color={Theme.colors.blue} />
        </CrossContainer>
      </TitleContainer>

      {hasMap ? (
        <ContentContainer ref={mainContainerRef}>
          <MapContainer>
            <Map ref={ref} tabIndex={-1} />

            {!!place && (
              <PlaceContainer>
                <RoomMapInfo room={place} onClose={() => actions.setPlace()} onSelect={() => setSelectedRoom(place)} />
              </PlaceContainer>
            )}

            {mode === 'map' && (
              <Switch onClick={() => setMode('list')}>
                <Icons name="list" size={25} color={Theme.colors.blue} />
              </Switch>
            )}
          </MapContainer>

          {mode !== 'map' && (
            <SearchContainer>
              <PlaceSearch
                listElement={renderRoomInfo}
                resultList={resultList}
                setResultList={setResultList}
                searchText={searchText}
                setSearchText={setSearchText}
                researchStatus={researchStatus}
                setResearchStatus={setResearchStatus}
                searchPlaces={searchPlaces}
                height="calc(100% - 152px)"
                autoFocus={true}
                withoutSearchMinimum={isSelectedFloorNotNull}
                onMap={mode === 'list' ? () => setMode('map') : undefined}
              />

              {!hideLocation && (
                <NoLocationContainer
                  onClick={() => {
                    setNoLocation()
                    Modal.close()
                  }}>
                  <Label>{i18n.t('screens.incident.locationNotInListQuestion')}</Label>

                  <Icons size={16} name="arrow_right" color={Theme.colors.black} />
                </NoLocationContainer>
              )}
            </SearchContainer>
          )}
        </ContentContainer>
      ) : (
        <>
          <PlaceSearch
            listElement={renderRoomInfo}
            resultList={resultList}
            setResultList={setResultList}
            searchText={searchText}
            setSearchText={setSearchText}
            researchStatus={researchStatus}
            setResearchStatus={setResearchStatus}
            searchPlaces={searchPlaces}
            height="calc(100% - 152px)"
            autoFocus={true}
            withoutSearchMinimum={isSelectedFloorNotNull}
          />
          {!hideLocation && (
            <NoLocationContainer
              onClick={() => {
                setNoLocation()
                Modal.close()
              }}>
              <Label>{i18n.t('screens.incident.locationNotInListQuestion')}</Label>

              <Icons size={16} name="arrow_right" color={Theme.colors.black} />
            </NoLocationContainer>
          )}
        </>
      )}
    </MainContainer>
  )
}

const MainContainer = styled('div')<{ hasMap: boolean }>`
  display: flex;
  flex-direction: column;
  height: 80vh;
  width: ${(props) => (props.hasMap ? '80vw' : '444px')};

  @media only screen and (max-width: ${windowSizeBreakpoints.phone}px) {
    width: 90vw;
  }
`

const TitleContainer = styled('div')`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px;

  @media only screen and (max-width: ${windowSizeBreakpoints.phone}px) {
    padding: 20px 30px 10px;
  }
`

const NoLocationContainer = styled('button')`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 12px 24px;
  padding: 16px;
  cursor: pointer;
  border: 0px;
  ${(props) => props.theme.constants.shadow.card};
  @media only screen and (max-width: ${windowSizeBreakpoints.phone}px) {
    margin-top: 20px;
  }
`

const CrossContainer = styled('button')`
  padding: 0 10px;
`

const Title = styled('h1')`
  display: flex;
  flex: 1;
  margin: 0px;
  ${(props) => props.theme.fonts.title};
  overflow-wrap: anywhere;
`

const Label = styled('span')`
  ${(props) => props.theme.fonts.label};
  flex: 1;
  text-align: center;
`

const ContentContainer = styled('div')`
  display: flex;
  flex-direction: row;
  flex: 1;
  overflow: hidden;
  position: relative;
`

const MapContainer = styled('div')`
  display: flex;
  flex-direction: column;
  flex: 1;
  align-items: center;
  position: relative;
`

const Map = styled('div')`
  flex: 1;
`

const SearchContainer = styled('div')`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 400px;
  background-color: ${(props) => props.theme.colors.white};

  @media only screen and (max-width: ${windowSizeBreakpoints.phone}px) {
    position: absolute;
    inset: 0;
    width: unset;
  }
`

const PlaceContainer = styled('div')`
  position: absolute;
  bottom: 20px;
`

const Switch = styled('button')`
  position: absolute;
  top: 15px;
  right: 15px;

  background-color: ${(props) => props.theme.colors.white};
  border-radius: 8px;
  padding: 10px;

  ${(props) => props.theme.constants.shadow.card};
`

export default RoomSelector
