import * as React from 'react'
import styled from 'theme/styled-components'
import useTheme from 'theme/useTheme'

import { format, utcToZonedTime } from 'date-fns-tz'

import useI18n from 'i18n/useI18n'
import useNavigation from 'utils/navigation/useNavigation'

import Logger from 'utils/Logger'
import { windowSizeBreakpoints } from 'utils/breakpoints'
import roomBookingUtils from './utils'
import { hasTheRightDistributionList } from 'utils/graphUtils'
import useBreakpoint from 'utils/useBreakpoints'

import useReducer from 'store/useReducer'
import * as siteStore from 'sites/store/siteStore'
import * as userStore from 'store/user/user'
import * as roomStore from './roomStore'
import * as featureStore from 'sites/store/featureStore'
import * as themeStore from 'theme/store'

import api from './api/roomApi'
import graphApi from './api/graphRoomApi'
import { AllTimes, Attendee } from './types'

import Icons from 'components/icons/Icons'
import Planning from 'components/planning/Planning'
import BookingForm from './BookingForm'
import Shortcut from 'components/button/Shortcut'
import Modal from 'components/modal/Modal'
import ValidationContent from 'components/modal/ValidationContent'
import RoomRequests from 'intervention/modal/RoomRequests'
import Breadcrumb from 'components/breadcrumb/Breadcrumb'
import TitleHelmet from 'components/title/TitleHelmet'
import RoomImage from 'components/image/RoomImage'
import InteractiveToast from 'components/toast/InteractiveToast'
import StatusLabel from 'components/label/StatusLabel'

import Loader from 'react-loader-spinner'
import { startOfDay, isSameDay, addDays, isWeekend, addHours, isAfter, isBefore, addMinutes } from 'date-fns'
import { findLevelWording } from 'intervention/utils'
import { analyticsKeys, externalLinkTracking } from 'utils/analytics'

interface Props {
  id: number
  formTimes?: AllTimes
}

type Status = 'ok' | 'loading' | 'error'

const DAYS_OF_PLANNING = 28 // 4 semaines

const IMG_SIZE = 76

const RoomDetails = ({ id, formTimes }: Props) => {
  const [Theme] = useTheme()
  const i18n = useI18n()
  const navigation = useNavigation()
  const [, currentBreakpoint] = useBreakpoint()
  const user = useReducer(userStore.store, (s) => s.user)
  const site = useReducer(siteStore.store, (s) => s.site)
  const favorites = useReducer(roomStore.store, (s) => s.favorites)
  const myFeatures = useReducer(featureStore.store, (f) => f.features)
  const isAccessible = useReducer(themeStore.store, (s) => s.theme) === 'accessible'
  const mySites = useReducer(siteStore.store, (s) => s.mySites)

  const isNUC = React.useMemo(() => !!user && !!user.email && user.email.includes('@bnl.engie.com'), [user])

  const today = startOfDay(new Date())
  const daysOfPlanning = Array.from(Array(site?.nbDaysPlanning || DAYS_OF_PLANNING), (_, index) =>
    addDays(today, index)
  ).filter((d) => !isWeekend(d))

  const [status, setStatus] = React.useState<Status>('ok')
  const [roomInfo, setRoomInfo] = React.useState<RoomWithPlanning>()
  const [planningDay, setPlanningDay] = React.useState<Date>(today)
  const [statusColor, setStatusColor] = React.useState(Theme.colors.darkGrey)
  const [statusName, setStatusName] = React.useState<string>()
  const [bookingDuration, setBookingDuration] = React.useState<'30min' | '1h'>()

  const isFav = favorites.indexOf((roomInfo && roomInfo.id) || '') > -1

  const canLocalize = !!roomInfo && !!roomInfo.id && myFeatures.find((f) => f.type === 'CARTOGRAPHY')
  const canBook =
    !!roomInfo &&
    !!roomInfo.bookable &&
    roomInfo.office365Id &&
    !!site &&
    !isNUC &&
    hasTheRightDistributionList(roomInfo, user)
  const canIncident = !!roomInfo?.hasIntervention && myFeatures.find((f) => f.type === 'INCIDENT')

  const breadCrumbRoomName = i18n.t('screens.room.details.detailsOf', { name: roomInfo?.name })

  const placeTypeLabel = roomInfo
    ? roomInfo.placeType.toLowerCase() === 'salle de réunion' || roomInfo.placeType.toLowerCase() === 'meeting room'
      ? i18n.t('screens.room.details.meetingRoom')
      : roomInfo.placeType.toLowerCase() === 'bulle' || roomInfo.placeType.toLowerCase() === 'booth'
      ? i18n.t('screens.room.details.bubbleRoom')
      : undefined
    : undefined

  const attendeesList = [
    {
      type: 'required',
      emailAddress: { name: user?.firstName + ' ' + user?.lastName, address: user?.email },
    } as Attendee,
  ]

  React.useEffect(() => {
    if (user && site) {
      setStatus('loading')
      api
        .getRoomPlanning(user.type, id, site.id)
        .then((r: RoomWithPlanning) => {
          setRoomInfo(r)
          setStatus('ok')
        })
        .catch((err) => {
          Logger.error(err)
          setStatus('error')
        })
    }
  }, [])

  React.useEffect(() => {
    if (roomInfo) {
      setStatusColor((isAccessible ? roomInfo.contrastedColorCode : roomInfo.colorCode) || '')
      setStatusName(roomBookingUtils.getStatusName(roomInfo.occupationStatus, roomInfo.bookable, roomInfo.booked))
    }
  }, [roomInfo, isAccessible])

  // Les fonctions pour la réservation

  const openModal = (title: string, showCancel: boolean, onConfirm?: () => void, confirmTitle?: string) =>
    Modal.open(() => (
      <ValidationContent
        title={title}
        onConfirm={() => {
          Modal.close()
          if (!!onConfirm) {
            onConfirm()
          }
        }}
        cancelButton={showCancel}
        confirmButtonTitle={confirmTitle}
        font={Theme.fonts.label}
      />
    ))

  const confirmReservation = (
    name: string,
    o365Id: string,
    start: string,
    end: string,
    attendees: Attendee[],
    subject: string
  ) => {
    const bookingStart = new Date(start)
    const bookingEnd = new Date(end)

    const alreadyBooked =
      !!roomInfo &&
      !!roomInfo.reservations.find(
        (r) =>
          isSameDay(new Date(r.from), bookingStart) &&
          ((isAfter(bookingStart, new Date(r.from)) && isBefore(bookingStart, new Date(r.to))) ||
            (isAfter(bookingEnd, new Date(r.from)) && isBefore(bookingEnd, new Date(r.to))))
      )

    if (!alreadyBooked) {
      makeReservation(name, o365Id, start, end, attendees, subject)
    } else {
      openModal(i18n.t('screens.room.alreadyBooked'), false)
      setBookingDuration(undefined)
    }
  }

  const makeReservation = (
    name: string,
    o365Id: string,
    start: string,
    end: string,
    attendees: Attendee[],
    subject: string
  ) => {
    if (user && site) {
      // On récupère la timezone de l'utilisateur
      const currentLocationTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone
      // On récupère l'heure réelle de l'utilisateur
      const currentUserStartDateTime = utcToZonedTime(new Date(start), currentLocationTimeZone)
      const currentUserEndDateTime = utcToZonedTime(new Date(end), currentLocationTimeZone)
      // On récupère l'heure réelle du site
      const currentSiteStartDateTime = utcToZonedTime(currentUserStartDateTime, site.timezone)
      const currentSiteEndDateTime = utcToZonedTime(currentUserEndDateTime, site.timezone)
      // On formate la date
      const formattedUtcStartDateTime = format(currentSiteStartDateTime, "yyyy-MM-dd'T'HH:mm:ss.SSSSSSS", {
        timeZone: site.timezone,
      })
      const formattedUtcEndDateTime = format(currentSiteEndDateTime, "yyyy-MM-dd'T'HH:mm:ss.SSSSSSS", {
        timeZone: site.timezone,
      })
      graphApi
        .bookRoom(user.type, {
          location: { displayName: o365Id, locationEmailAddress: o365Id },
          subject: subject || i18n.t('screens.room.defaultSubject'),
          start: { dateTime: formattedUtcStartDateTime, timeZone: site.timezone },
          end: { dateTime: formattedUtcEndDateTime, timeZone: site.timezone },
          attendees: [{ type: 'required', emailAddress: { name: name, address: o365Id } }, ...attendees],
        })
        .then((res) => {
          InteractiveToast.close()
          roomStore.actions.setGraphIdBooking(res.id)
          roomStore.actions.setGraphEmailBooking(o365Id)
          if (!!formTimes) {
            roomStore.actions.setFormTimes(formTimes)
          }
          InteractiveToast.open(
            i18n.t('screens.room.toast.waitingTitle'),
            i18n.t('screens.room.toast.waitingDescription'),
            'info-circle',
            Theme.colors.toastInfoText,
            Theme.colors.toastInfoBackground,
            currentBreakpoint === 'phone' ? 'bottom' : 'top-right',
            navigation
          )
        })
        .catch((err) => {
          Logger.error(err)
          openModal(
            i18n.t('screens.room.reservationFailed'),
            true,
            () => makeReservation(name, o365Id, start, end, attendees, subject),
            i18n.t('common.retry')
          )
        })
        .finally(() => setBookingDuration(undefined))
    }
  }

  const toggleFav = () => {
    const roomId = (roomInfo && roomInfo.id) || ''
    if (!isFav) {
      roomStore.actions.setFav([...favorites, roomId])
    } else {
      roomStore.actions.setFav(favorites.filter((f) => f !== roomId))
    }
  }

  const onEnterKey = (e: React.KeyboardEvent<HTMLDivElement>, onClick: () => void) => {
    if (e.key === 'Enter') {
      onClick()
    }
  }

  const bookRoomFromShortcut = (duration: '30min' | '1h') => {
    if (!bookingDuration && roomInfo && roomInfo.office365Id) {
      setBookingDuration(duration)
      const now = new Date()
      confirmReservation(
        roomInfo.name,
        roomInfo.office365Id,
        i18n.t('screens.room.fullUTCDate', { date: now }),
        i18n.t('screens.room.fullUTCDate', { date: duration === '30min' ? addMinutes(now, 30) : addHours(now, 1) }),
        attendeesList,
        ''
      )
    }
  }

  const renderShortcut = (
    label: string,
    ariaLabel: string,
    iconName: IconName,
    onClick: () => void,
    isLoading?: boolean
  ) => (
    <Shortcut
      label={label}
      ariaLabel={ariaLabel}
      iconName={iconName}
      onClick={onClick}
      color={Theme.colors.blue}
      font={Theme.fonts.labelBold}
      circleSize={30}
      iconSize={20}
      thirdParty
      useMargin={false}
      loading={isLoading}
    />
  )

  if (status === 'loading') {
    return (
      <LoaderContainer>
        <Loader type="TailSpin" color={Theme.colors.blue} />
      </LoaderContainer>
    )
  }

  if (status === 'error' || !roomInfo) {
    return (
      <RoomInfoMainContainer>
        <RoomError>{i18n.t('screens.room.details.noRoomError')}</RoomError>
      </RoomInfoMainContainer>
    )
  }

  return (
    <MainContainer>
      <TitleHelmet title={roomInfo.name} />
      <Breadcrumb screen="room" path="room" details={breadCrumbRoomName} navigation={navigation} />

      <RoomInfoMainContainer unbookable={!roomInfo.bookable || isNUC}>
        <LeftContainer>
          <PhotoTitleContainer>
            <PhotoContainer>
              <RoomImage
                iconSize={40}
                height={IMG_SIZE}
                width={IMG_SIZE}
                radius={IMG_SIZE}
                src={roomInfo.picture}
                roomType={roomInfo.placeType}
              />
            </PhotoContainer>

            <MainInfoContainer>
              <TitleContainer>
                <RoomTitle>{roomInfo.name}</RoomTitle>
                <HeartContainer onClick={toggleFav} tabIndex={0} onKeyDown={(e) => onEnterKey(e, toggleFav)}>
                  <Icons name={isFav ? 'heart-filled' : 'heart'} size={38} color={Theme.colors.blue} />
                </HeartContainer>
              </TitleContainer>

              <CategoryStatusContainer>
                <NormalBodyText>{placeTypeLabel}</NormalBodyText>
                {user && user.type !== 'EXTERNAL' && roomInfo.occupationStatus !== 'UNKNOWN' && (
                  <StatusLabel
                    color={statusColor}
                    label={i18n.t([`screens.room.status.${statusName}`, 'screens.room.status.UNKNOWN'])}
                    font={Theme.fonts.body}
                  />
                )}
              </CategoryStatusContainer>
            </MainInfoContainer>
          </PhotoTitleContainer>

          <InformationContainer>
            {!!roomInfo.description && (
              <DescriptionContainer>
                <IconContainer>
                  <Icons name="text" size={20} color={Theme.colors.blue} ariaLabel={i18n.t('label.svg.description')} />
                </IconContainer>
                <NormalBodyText>{roomInfo.description}</NormalBodyText>
              </DescriptionContainer>
            )}

            <AlignedContainer>
              <IconContainer>
                <Icons name="user" size={20} color={Theme.colors.blue} ariaLabel={i18n.t('label.svg.capacity')} />
              </IconContainer>
              <NormalBodyText>{i18n.t('screens.room.capacity', { count: roomInfo.capacity })}</NormalBodyText>
            </AlignedContainer>

            {roomInfo.equipments.length > 0 && (
              <DescriptionContainer>
                <IconContainer>
                  <Icons name="tv" size={20} color={Theme.colors.blue} ariaLabel={i18n.t('label.svg.equipments')} />
                </IconContainer>
                <NormalBodyText>{roomInfo.equipments.join(', ')}</NormalBodyText>
              </DescriptionContainer>
            )}

            {roomInfo.departments.length > 0 && (
              <DescriptionContainer>
                <IconContainer>
                  <Icons name="users-wm" size={20} color={Theme.colors.blue} ariaLabel={i18n.t('label.svg.entities')} />
                </IconContainer>
                <NormalBodyText>{roomInfo.departments.join(', ')}</NormalBodyText>
              </DescriptionContainer>
            )}

            <AlignedContainer>
              <IconContainer>
                <Icons name="building" size={20} color={Theme.colors.blue} />
              </IconContainer>
              <NormalBodyText>{findLevelWording(roomInfo.floor, i18n, roomInfo.floorCustomName)}</NormalBodyText>
            </AlignedContainer>
          </InformationContainer>

          <ShortcutsMainContainer unbookable={!canBook}>
            {canLocalize &&
              renderShortcut(
                i18n.t('screens.room.details.shortcuts.locate'),
                i18n.t('label.ariaLabel.room.locateOnMap'),
                'pin',
                () => {
                  if (site && site.webVisioglobeHash && roomInfo.uniquePlaceName) {
                    navigation.pushQuery(`/map`, `?areaId=${roomInfo.uniquePlaceName}`, { origin: 'localisation' })
                  }
                }
              )}

            {canBook &&
              renderShortcut(
                i18n.t('screens.room.details.shortcuts.bookThirtyMinutes'),
                i18n.t('label.ariaLabel.room.bookThirtyMinutes'),
                '30min',
                () => bookRoomFromShortcut('30min'),
                bookingDuration === '30min'
              )}

            {canBook &&
              renderShortcut(
                i18n.t('screens.room.details.shortcuts.bookOneHour'),
                i18n.t('label.ariaLabel.room.bookOneHour'),
                '1h',
                () => bookRoomFromShortcut('1h'),
                bookingDuration === '1h'
              )}

            {canIncident &&
              renderShortcut(
                i18n.t('screens.room.details.shortcuts.requestIntervention'),
                i18n.t('label.ariaLabel.room.requestIntervention'),
                'intervention',
                () =>
                  Modal.open(() => (
                    <RoomRequests
                      room={roomInfo}
                      onNewRequest={() =>
                        navigation.push(`/incident`, {
                          room: roomInfo,
                          origin: 'détail de salle',
                        })
                      }
                    />
                  ))
              )}

            {!!roomInfo.buttonTitle &&
              !!roomInfo.webButtonLink &&
              renderShortcut(roomInfo.buttonTitle, roomInfo.buttonTitle, 'globe', () => {
                externalLinkTracking(
                  roomInfo.webButtonLink!,
                  analyticsKeys.room,
                  user,
                  site,
                  mySites,
                  roomInfo.buttonTitle
                )
                window.open(roomInfo.webButtonLink)
              })}

            {!!roomInfo.button2Title &&
              !!roomInfo.webButton2Link &&
              renderShortcut(roomInfo.button2Title, roomInfo.button2Title, 'globe', () => {
                externalLinkTracking(
                  roomInfo.webButton2Link!,
                  analyticsKeys.room,
                  user,
                  site,
                  mySites,
                  roomInfo.button2Title
                )
                window.open(roomInfo.webButton2Link)
              })}
          </ShortcutsMainContainer>

          {roomInfo.bookable && (
            <PlanningContainer>
              <PlanningTitle>{i18n.t('screens.room.details.planning.title')}</PlanningTitle>
              <Planning
                meetings={roomInfo.reservations}
                min={8}
                max={20}
                day={planningDay}
                setDay={setPlanningDay}
                daysList={daysOfPlanning}
              />
            </PlanningContainer>
          )}
        </LeftContainer>

        <RightContainer unbookable={!roomInfo.bookable || isNUC}>
          {canBook && <BookingForm roomInfo={roomInfo} formTimes={formTimes} />}
        </RightContainer>
      </RoomInfoMainContainer>
    </MainContainer>
  )
}

// CONTAINERS

const MainContainer = styled('div')`
  min-height: calc(100vh - 146px);
`
const RoomInfoMainContainer = styled('div')<{ unbookable?: boolean }>`
  display: flex;
  justify-content: center;
  padding: 60px;
  flex-wrap: wrap;
  gap: 20px 60px;
  @media only screen and (max-width: ${windowSizeBreakpoints.phone}px) {
    padding: 20px;
  }
`
const LeftContainer = styled('div')`
  display: flex;
  flex-direction: column;
  flex: 1;
  gap: 10px;
  max-width: 800px;
`
const RightContainer = styled('div')<{ unbookable?: boolean }>`
  display: flex;
  flex-direction: column;
  flex: 1;
  max-width: 400px;
`
const LoaderContainer = styled('div')`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 15%;
`
const AlignedContainer = styled('div')`
  display: flex;
  align-items: center;
`
const HeartContainer = styled('div')`
  cursor: pointer;
  :focus-visible {
    outline: 2px solid ${(props) => props.theme.colors.darkBlue};
  }
`
const MainInfoContainer = styled('div')`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
`
const PhotoTitleContainer = styled('div')`
  display: flex;
`
const TitleContainer = styled('div')`
  display: flex;
  align-items: center;
  gap: 20px;
`
const PhotoContainer = styled('div')`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${(props) => props.theme.colors.mediumDarkGrey};
  height: ${IMG_SIZE}px;
  width: ${IMG_SIZE}px;
  border-radius: ${IMG_SIZE}px;
  margin-right: 20px;
`
const CategoryStatusContainer = styled('div')`
  display: flex;
  align-items: center;
  margin-top: 9px;
  gap: 30px;
  @media only screen and (max-width: ${windowSizeBreakpoints.medium}px) {
    gap: 20px;
  }
  @media only screen and (max-width: ${windowSizeBreakpoints.phone}px) {
    flex-direction: column;
    align-items: flex-start;
    gap: 0px;
  }
`
const PlanningContainer = styled('div')`
  margin: 20px 0px;
`
const InformationContainer = styled('div')`
  display: flex;
  flex-direction: column;
  flex: 1;
  margin: 26px 0px 20px;
  gap: 10px;
`
const IconContainer = styled('div')`
  margin-right: 5px;
`
const DescriptionContainer = styled('div')`
  display: flex;
`
const ShortcutsMainContainer = styled('div')<{ unbookable?: boolean }>`
  display: flex;
  width: 100%;
  justify-content: space-around;
  max-width: 600px;
  align-self: center;
`

// TEXTES

const RoomError = styled('p')`
  ${(props) => props.theme.fonts.body};
  font-size: 20px;
`
const NormalBodyText = styled('p')`
  ${(props) => props.theme.fonts.body};
  color: ${(props) => props.theme.colors.darkGrey};
  margin: 0px 0px 1px 0px;
`
const RoomTitle = styled('h1')`
  ${(props) => props.theme.fonts.h1};
  color: ${(props) => props.theme.colors.blue};
  font-size: 30px;
  margin: 0px;
  word-break: break-word;
`
const PlanningTitle = styled('h3')`
  ${(props) => props.theme.fonts.h3Bold};
  color: ${(props) => props.theme.colors.darkGrey};
  margin: 0px 0px 8px;
`

export default RoomDetails
