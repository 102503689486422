import * as React from 'react'
import styled from 'theme/styled-components'
import useTheme from 'theme/useTheme'

import useI18n from 'i18n/useI18n'

import Modal from 'components/modal/Modal'
import Icons from 'components/icons/Icons'

import utils from 'roombooking/utils'
import { findLevelWording } from '../utils'

interface Props {
  room: Area
  isLast: boolean
  setSelectedRoom: (room: Area) => void
}

const RoomInfo = ({ room, isLast, setSelectedRoom }: Props) => {
  const i18n = useI18n()
  const [Theme] = useTheme()

  return (
    <MainContainer>
      <RoomMainContainer
        onClick={() => {
          setSelectedRoom(room)
          Modal.close()
        }}>
        <RoomImageContainer>
          <Icons name={utils.getRoomPlaceholder(room.placeType)} color={Theme.colors.blue} size={50} />
        </RoomImageContainer>
        <InfosContainer>
          <RoomName>{room.name}</RoomName>
          <RoomDescription>{room.description}</RoomDescription>
          <RoomFloor>{findLevelWording(room.floor, i18n, room.floorCustomName)}</RoomFloor>
        </InfosContainer>
      </RoomMainContainer>
      {!isLast && <BlueLineBreak role="presentation" />}
    </MainContainer>
  )
}

// CONTAINERS

const MainContainer = styled('div')`
  display: flex;
  flex-direction: column;
`
const RoomMainContainer = styled('div')`
  cursor: pointer;
  display: flex;
  flex: 1;
  height: 80px;
  margin: 12px 0px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
`
const RoomImageContainer = styled('div')`
  min-width: 80px;
  min-height: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${(props) => props.theme.colors.mediumDarkGrey};
`
const InfosContainer = styled('div')`
  display: flex;
  flex: 1;
  flex-direction: column;
  padding: 10px;
  gap: 5px;
`

// TEXTES

const RoomName = styled('p')`
  ${(props) => props.theme.fonts.bodyBold};
  color: ${(props) => props.theme.colors.blue};
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: break-word;
  margin: 0;
`
const RoomDescription = styled('p')`
  ${(props) => props.theme.fonts.subtitle};
  color: ${(props) => props.theme.colors.darkGrey};
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: break-word;
  margin: 0;
`
const RoomFloor = styled('p')`
  ${(props) => props.theme.fonts.labelBold};
  color: ${(props) => props.theme.colors.darkGrey};
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: break-word;
  margin: 0;
`

// AUTRES

const BlueLineBreak = styled('div')`
  width: 60px;
  height: 3px;
  background-color: ${(props) => props.theme.colors.blue};
  border-radius: 2px;
`

export default RoomInfo
