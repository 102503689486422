import * as React from 'react'
import styled from 'theme/styled-components'
import useTheme from 'theme/useTheme'

import useReducer from 'store/useReducer'
import * as editosStore from '../store/editoStore'
import * as siteStore from 'sites/store/siteStore'
import * as userStore from 'store/user/user'
import * as bannersStore from 'banners/store'

import useNavigation from 'utils/navigation/useNavigation'
import Logger from 'utils/Logger'
import useI18n from 'i18n/useI18n'
import { windowSizeBreakpoints } from 'utils/breakpoints'
import analytics, { analyticsKeys } from 'utils/analytics'
import useBreakpoint from 'utils/useBreakpoints'

import api from '../api/editoApi'

import Button from 'components/button/Button'
import Breadcrumb from 'components/breadcrumb/Breadcrumb'
import TitleHelmet from 'components/title/TitleHelmet'
import Icons from 'components/icons/Icons'
import Banner from 'components/banner/Banner'
import SurveyBanner from 'components/banner/SurveyBanner'

import Loader from 'react-loader-spinner'

type Status = 'loading' | 'error' | 'success'

interface Props {
  limit?: number
  displayedHome?: boolean
  navigate?: () => void
}

const EditosScreen = ({ limit, displayedHome = false, navigate }: Props) => {
  const [Theme] = useTheme()
  const navigation = useNavigation()
  const i18n = useI18n()
  const [, currentBreakpoint] = useBreakpoint()

  const editos = useReducer(editosStore.store, (s) => s.editos)
  const site = useReducer(siteStore.store, (s) => s.site)
  const mySites = useReducer(siteStore.store, (s) => s.mySites)
  const user = useReducer(userStore.store, (s) => s.user)
  const banners = useReducer(bannersStore.store, (s) => s.banners)

  const [status, setStatus] = React.useState<Status>('loading')

  const showedEditos = editos.filter((r, i) => !limit || i < limit)

  const sections = showedEditos.reduce((acc, cur) => {
    if (cur.category) {
      const prev = acc[cur.category.name]

      if (!prev) {
        acc[cur.category.name] = []
      }

      acc[cur.category.name].push(cur)
    }

    return acc
  }, {} as Record<string, InformationSimple[]>)

  const others = showedEditos.filter((edito) => !edito.category)

  const showedBanner = React.useMemo(
    () => banners.find((banner) => !!banner.feature && banner.feature === 'PRACTICAL_INFORMATION'),
    [banners]
  )

  const findFeature = React.useMemo(() => site?.features.find((f) => f.type === 'PRACTICAL_INFORMATION'), [site])

  const load = (displayLoading?: boolean) => {
    if (displayLoading) {
      setStatus('loading')
    }
    if (site && user) {
      api
        .getAllEditos(site.id, user.type)
        .then((list) => {
          editosStore.actions.setEditos(list.information)
          setStatus('success')
        })
        .catch((err) => {
          Logger.error(err)
          setStatus('error')
        })
    }
  }

  React.useEffect(() => {
    load()
    if (user) {
      analytics.track({ screenName: analyticsKeys.practicalInfoList, userType: user.type, currentSite: site, mySites })
    }
  }, [i18n.lang])

  const renderInformation = (edito: InformationSimple, i: number) => {
    const goToDetails = () => navigation.push(`/information/${edito.id}`)
    return (
      <ArticleContainer
        key={edito.id}
        tabIndex={0}
        onClick={goToDetails}
        onKeyDown={(e) => {
          if (e.key === 'Enter') {
            goToDetails()
          }
        }}>
        {edito.photo && (
          <ImageContainer>
            <Img key={edito.photo.url} src={edito.photo.url} alt={edito.photo.description} />
          </ImageContainer>
        )}
        <TitleArticle displayedHome={displayedHome}>{edito.title}</TitleArticle>
        <Icons name="chevron_right" size={20} color={Theme.colors.black} />
      </ArticleContainer>
    )
  }

  const renderList = (data: InformationSimple[], withTitle: boolean) => {
    if (data.length === 0) {
      return null
    }
    return (
      <>
        {withTitle && <Title>{i18n.t('screens.practical_information.others')}</Title>}
        <List>{data.map(renderInformation)}</List>
      </>
    )
  }

  const renderSection = (title: string, data: InformationSimple[]) => {
    return (
      <>
        <Title>{title}</Title>
        <List>{data.map(renderInformation)}</List>
      </>
    )
  }

  if (status === 'loading') {
    return (
      <LoaderContainer>
        <Loader type="TailSpin" color={Theme.colors.blue} />
      </LoaderContainer>
    )
  }

  if (status === 'error') {
    return (
      <>
        <NoNewsError>{i18n.t('screens.practical_information.error')}</NoNewsError>
        <ErrorButtonContainer>
          <Button label={i18n.t('common.retry')} onClick={() => load(true)} font={Theme.fonts.h3Bold} />
        </ErrorButtonContainer>
      </>
    )
  }

  return (
    <>
      <TitleHelmet title={i18n.t('screens.practical_information.title')} />
      {!displayedHome && <Breadcrumb screen="practical_information" path="information" navigation={navigation} />}

      <MainContainer>
        {!!showedBanner && !displayedHome && (
          <Banner banner={showedBanner} marginTop={20} marginRight={currentBreakpoint !== 'phone' ? 20 : 0} />
        )}
        {!displayedHome && <SurveyBanner findFeature={findFeature} />}
        <Title>{i18n.t('screens.practical_information.title')}</Title>

        {showedEditos.length > 0 ? (
          <>
            {/* CATEGORIES */}
            {Object.keys(sections).map((key) => renderSection(key, sections[key]))}

            {/* OTHERS */}
            {renderList(others, Object.keys(sections).length !== 0)}
          </>
        ) : (
          <NoInformationMessage>{i18n.t('screens.practical_information.noInfo')}</NoInformationMessage>
        )}

        {limit && navigate && (
          <CenterButtonContainer>
            <ButtonContainer>
              <Button label={i18n.t('screens.home.more')} onClick={navigate} font={Theme.fonts.h3Bold} />
            </ButtonContainer>
          </CenterButtonContainer>
        )}
      </MainContainer>
    </>
  )
}

export default EditosScreen

const MainContainer = styled('div')`
  display: flex;
  flex: 1;
  flex-direction: column;
  margin-bottom: 60px;
  margin: 0px 90px;
  @media only screen and (max-width: ${windowSizeBreakpoints.big}px) {
    margin: 0px 60px;
  }
  @media only screen and (max-width: ${windowSizeBreakpoints.mediumBig}px) {
    margin: 0px 32px;
  }
  @media only screen and (max-width: ${windowSizeBreakpoints.small}px) {
    margin: 0px 12px;
  }
`
const List = styled('div')`
  display: grid;
  grid-template-columns: repeat(2, 50%);
  @media only screen and (max-width: ${windowSizeBreakpoints.mediumBig}px) {
    grid-template-columns: repeat(1, 100%);
  }
`
const ImageContainer = styled('div')`
  width: 163px;
  height: 130px;
  background-color: ${(props) => props.theme.colors.darkGrey};
  @media only screen and (max-width: ${windowSizeBreakpoints.mediumBig}px) {
    width: 130px;
    height: 100px;
  }
`
const Img = styled('img')`
  cursor: pointer;
  width: 163px;
  height: 130px;
  object-fit: cover;
  background-color: ${(props) => props.theme.colors.darkGrey};
  @media only screen and (max-width: ${windowSizeBreakpoints.mediumBig}px) {
    width: 130px;
    height: 100px;
  }
`
const Title = styled('h1')`
  ${(props) => props.theme.fonts.bodyBold};
  font-size: 24px;
  margin-bottom: 30px;
  margin-top: 40px;
`
const ArticleContainer = styled.div`
  margin-right: 30px;
  display: flex;
  flex-direction: row;
  padding-right: 25px;
  cursor: pointer;
  align-items: center;
  ${(props) => props.theme.constants.shadow.card};
  margin-bottom: 30px;
  :focus-visible {
    outline: 2px solid ${(props) => props.theme.colors.darkBlue};
  }

  :hover {
    transform: scale(1.02);
  }
  @media only screen and (max-width: ${windowSizeBreakpoints.mediumBig}px) {
    margin-right: 0;
    padding-right: 12px;
  }
`
const TitleArticle = styled.h4<{ displayedHome: boolean }>`
  cursor: pointer;
  ${(props) => props.theme.fonts.h1Bold};
  margin-left: 25px;
  display: flex;
  flex: 1;
  padding-right: 8px;
  color: ${(props) => (props.displayedHome ? props.theme.colors.black : props.theme.colors.blue)};
  @media only screen and (max-width: ${windowSizeBreakpoints.big}px) {
    ${(props) => props.theme.fonts.h3Bold};
    color: ${(props) => (props.displayedHome ? props.theme.colors.black : props.theme.colors.blue)};
  }
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  line-clamp: 3;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
  word-wrap: break-word;
`
const LoaderContainer = styled('div')`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 15%;
`
const NoNewsError = styled('p')`
  ${(props) => props.theme.fonts.body};
  font-size: 20px;
`
const ErrorButtonContainer = styled('div')`
  display: flex;
  width: 300px;
`
const NoInformationMessage = styled('p')`
  margin-top: 40px;
  margin-left: 15px;
  ${(props) => props.theme.fonts.body};
`
const CenterButtonContainer = styled('div')`
  display: flex;
  justify-content: center;
  margin: 30px 0;
`
const ButtonContainer = styled('div')`
  display: flex;
  width: 350px;
`
