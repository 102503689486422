import * as React from 'react'

import Loader from 'react-loader-spinner'
import { isAfter, format, isToday, isBefore } from 'date-fns'
import { utcToZonedTime } from 'date-fns-tz'
import numbro from 'numbro'
import languages from 'numbro/dist/languages.min'

import styled from 'theme/styled-components'
import useTheme from 'theme/useTheme'

import api from '../api/timeBookingsApi'
import restaurantApi from '../api/restaurantsApi'

import Icon from 'components/icons/Icons'
import Button from 'components/button/Button'
import Alert from 'components/alert/Alert'
import ValidationContent from 'components/modal/ValidationContent'
import Modal from 'components/modal/Modal'

import useReducer from 'store/useReducer'
import * as siteStore from 'sites/store/siteStore'
import * as userStore from 'store/user/user'

import useBreakpoint from 'utils/useBreakpoints'
import { windowSizeBreakpoints } from 'utils/breakpoints'
import stringUtils from 'utils/strings'

import useI18n from 'i18n/useI18n'

import { TAB } from 'planning/barclap/BarclapScreen'
import { timeSlotToTimezoneSite } from 'planning/utils'
import { getCurrentBadgeWithSolde } from 'utils/badgeUtils'
import LowBalance from 'planning/barclap/LowBalance'
import MyMRSReloadModal from '../mrs/MyMRSReloadModal'

interface Props {
  closeModal: () => void
  restaurantInfos: RestaurantV6
  refreshPlanningBookings?: () => void
}

type StatusScreen = 'loading' | 'error' | 'ok'

const FULL_ZONE = 100
const ALMOST_FULL_ZONE = 70

const onEnterKey = (e: React.KeyboardEvent, onClick: () => void) => {
  if (e.key === 'Enter') {
    onClick()
  }
}

const TimeBookingModal = ({ closeModal, restaurantInfos, refreshPlanningBookings }: Props) => {
  const [theme] = useTheme()
  const i18n = useI18n()
  const [, currentBreakpoint] = useBreakpoint()

  const user = useReducer(userStore.store, (s) => s.user)
  const currentSite = useReducer(siteStore.store, (s) => s.site)
  const badges = useReducer(userStore.store, (s) => s.badges)

  const [currentTab, setCurrentTab] = React.useState<TAB>('FORM')
  const [statusScreen, setStatusScreen] = React.useState<StatusScreen>('loading')
  const [participants, setParticipants] = React.useState(1)
  const [bookingToModify, setBookingToModify] = React.useState<BookingV6>()
  const [currentIndex, setCurrentIndex] = React.useState(0)
  const [isBooking, setIsBooking] = React.useState(false)
  const [selectedTimeSlot, setSelectedTimeSlot] = React.useState<ReservationSlotV6>()
  const [bookings, setBookings] = React.useState<BookingV6[]>([])
  const [restaurant, setRestaurant] = React.useState<RestaurantV6>(restaurantInfos)

  const selectedDate = new Date()
  const today = format(selectedDate, 'EEEE').toLowerCase()

  Object.getOwnPropertyNames(languages).forEach((lang) => {
    numbro.registerLanguage(languages[lang])
  })
  numbro.setLanguage('fr-FR')

  const currentBadge = React.useMemo(
    () => (!!currentSite ? getCurrentBadgeWithSolde(badges, currentSite.id) : undefined),
    [currentSite, badges]
  )
  const solde = React.useMemo(() => currentBadge?.solde, [currentBadge])
  const lowAmountTreshold = currentSite?.badgeTresholdRecharge

  const { name, reservationMaxPeople, reservationSlots } = restaurant

  const filteredTimeSlots = React.useMemo(
    () =>
      !!reservationSlots && !!currentSite
        ? reservationSlots
            .filter((slot) => slot.day.toLowerCase() === today)
            .sort((a, b) => {
              const startDateTimeA = timeSlotToTimezoneSite(selectedDate, a.startTime, currentSite.timezone)
              const startDateTimeB = timeSlotToTimezoneSite(selectedDate, b.startTime, currentSite.timezone)
              if (isBefore(startDateTimeA, startDateTimeB)) {
                return -1
              }
              return 1
            })
        : [],
    [reservationSlots, today, currentSite]
  )

  const MAX_PEOPLE_NUMBER = reservationMaxPeople || 0
  const MAX_PEOPLE_SIMPLE_VIEW = 2
  const MAX_PEOPLE_ITEMS =
    currentBreakpoint === 'laptop' || currentBreakpoint === 'big' || currentBreakpoint === 'mediumBig' ? 5 : 3

  React.useEffect(() => {
    updateRestaurantInfos()
  }, [])

  React.useEffect(() => {
    if (!!bookingToModify) {
      setParticipants(bookingToModify.nbPeople)
      updateIndex(bookingToModify.nbPeople)
      const findSlot = filteredTimeSlots.find((slot) => slot.id === bookingToModify.slotId)
      setSelectedTimeSlot(findSlot)
    }
  }, [bookingToModify])

  React.useEffect(() => {
    updateIndex()
  }, [MAX_PEOPLE_ITEMS])

  const updateIndex = (numberOfPeople?: number) => {
    const newIndex = Math.max(0, Math.min(numberOfPeople || participants, MAX_PEOPLE_NUMBER - MAX_PEOPLE_ITEMS))
    setCurrentIndex(newIndex > 0 ? newIndex - 1 : 0)
  }

  React.useEffect(() => {
    if (!!selectedTimeSlot) {
      const bookingToModifyTimeSlot = !!bookingToModify && selectedTimeSlot.id === bookingToModify.slotId
      const availableSeats =
        !!bookingToModifyTimeSlot && !!bookingToModify
          ? selectedTimeSlot.capacity + bookingToModify.nbPeople - selectedTimeSlot.nbPeople
          : selectedTimeSlot.capacity - selectedTimeSlot.nbPeople
      const isTimeSlotDisable = availableSeats < participants
      if (isTimeSlotDisable) {
        setSelectedTimeSlot(undefined)
      }
    }
  }, [selectedTimeSlot, participants, bookingToModify])

  const updateRestaurantInfos = () => {
    if (!!currentSite && !!user) {
      restaurantApi
        .getRestaurant(user.type, restaurantInfos.id, currentSite.id)
        .then((res) => {
          setRestaurant(res)
          fetchBookings()
        })
        .catch(() => setStatusScreen('error'))
    }
  }

  const checkAmount = () => {
    if (solde !== undefined && solde !== null && !!lowAmountTreshold && solde < lowAmountTreshold) {
      Modal.open(() => (
        <LowBalance
          balance={solde !== undefined && solde !== null ? numbro(solde).formatCurrency({ mantissa: 2 }) : ''}
          closeDrawer={Modal.close}
          onReload={() => Modal.open(() => <MyMRSReloadModal closeModal={Modal.close} />)}
        />
      ))
    }
  }

  const fetchBookings = () => {
    if (!!user && !!currentSite) {
      setStatusScreen('loading')
      api
        .getAllBookings(user.type)
        .then((res) => {
          setStatusScreen('ok')
          const filteredBookings = res.bookings.filter(
            (booking) =>
              filteredTimeSlots?.some((slot) => slot.id === booking.slotId) &&
              isToday(utcToZonedTime(new Date(booking.date), currentSite.timezone))
          )
          if (filteredBookings.length > 0) {
            setCurrentTab('BOOKINGS')
          } else {
            setCurrentTab('FORM')
          }
          setBookings(filteredBookings)
        })
        .catch(() => setStatusScreen('error'))
    }
  }

  const handlePrev = () => setCurrentIndex((prevIndex) => Math.max(prevIndex - 1, 0))

  const handleNext = () => setCurrentIndex((prevIndex) => Math.min(prevIndex + 1, MAX_PEOPLE_NUMBER - MAX_PEOPLE_ITEMS))

  const onBooking = () => {
    if (!!user && selectedTimeSlot) {
      setIsBooking(true)
      const request: BookingFormV6 = {
        slotId: selectedTimeSlot.id,
        nbPeople: participants,
        date: i18n.t('screens.planning.secondForm.dateToSend', {
          date: selectedDate,
        }),
      }
      if (!!bookingToModify) {
        api
          .modifyBooking(user.type, bookingToModify.id, request)
          .then(() => {
            checkAmount()
            setBookingToModify(undefined)
            updateRestaurantInfos()
            if (refreshPlanningBookings) {
              refreshPlanningBookings()
            }
          })
          .catch(() => {
            Alert.open(
              () => (
                <ValidationContent
                  title={i18n.t(`screens.barclap.form.error`)}
                  onConfirm={Alert.close}
                  ariaLabelConfirm={i18n.t('label.ariaLabel.windowCrossIcon')}
                />
              ),
              true
            )
          })
          .finally(() => setIsBooking(false))
      } else {
        api
          .createBooking(user.type, request)
          .then(() => {
            checkAmount()
            if (refreshPlanningBookings) {
              refreshPlanningBookings()
            }
            updateRestaurantInfos()
          })
          .catch(() => {
            Alert.open(
              () => (
                <ValidationContent
                  title={i18n.t(`screens.barclap.form.error`)}
                  onConfirm={Alert.close}
                  ariaLabelConfirm={i18n.t('label.ariaLabel.windowCrossIcon')}
                />
              ),
              true
            )
          })
          .finally(() => setIsBooking(false))
      }
    }
  }

  const onDeleteBooking = (item: BookingV6) => {
    if (!!user) {
      setIsBooking(true)
      api
        .deleteBooking(user.type, item.id)
        .then(() => {
          setParticipants(1)
          setSelectedTimeSlot(undefined)
          updateIndex(1)
          Alert.open(
            () => (
              <ValidationContent title={i18n.t('screens.planning.booking.successDelete')} onConfirm={Alert.close} />
            ),
            true
          )
          updateRestaurantInfos()
          if (refreshPlanningBookings) {
            refreshPlanningBookings()
          }
        })
        .catch(() =>
          Alert.open(
            () => (
              <ValidationContent
                title={i18n.t('screens.planning.delete.error')}
                onConfirm={Alert.close}
                ariaLabelConfirm={i18n.t('label.ariaLabel.windowCrossIcon')}
              />
            ),
            true
          )
        )
        .finally(() => setIsBooking(false))
    }
  }

  const renderPeopleItem = (index: number, selected: boolean) => (
    <TimeSlotContainer
      tabIndex={index}
      role="option"
      index={index}
      selected={selected}
      onClick={() => setParticipants(index + 1)}
      initialSlot={!!bookingToModify && bookingToModify.nbPeople - 1 === index}>
      <CheckContainer>
        {selected ? <Icon name="check-circle" size={24} color={theme.colors.blue} /> : <Placeholder />}
      </CheckContainer>
      <TimeSlot aria-hidden>
        {i18n.t('screens.barclap.form.peopleNumber', {
          count: index + 1,
        })}
      </TimeSlot>
    </TimeSlotContainer>
  )

  const renderPeople = () => {
    if (!!reservationMaxPeople && reservationMaxPeople <= MAX_PEOPLE_SIMPLE_VIEW) {
      return (
        <TimeSlotListContainer role="listbox">
          {renderPeopleItem(0, participants === 1)}
          {reservationMaxPeople === 2 && renderPeopleItem(1, participants === 2)}
        </TimeSlotListContainer>
      )
    }

    const showPrevButton = currentIndex > 0
    const showNextButton = currentIndex + MAX_PEOPLE_ITEMS < MAX_PEOPLE_NUMBER

    return (
      <PeopleContainer>
        <ArrowButtonContainer margin>
          {showPrevButton && (
            <ArrowContainer type="left" onClick={handlePrev}>
              <Icon name="chevron_left" size={20} />
            </ArrowContainer>
          )}
        </ArrowButtonContainer>
        {Array.from({ length: Math.min(MAX_PEOPLE_ITEMS, MAX_PEOPLE_NUMBER - currentIndex) }, (_, i) => {
          const itemNumber = currentIndex + i + 1
          return (
            <PeopleItemContainer
              role="option"
              key={itemNumber}
              selected={participants === itemNumber}
              onClick={() => setParticipants(itemNumber)}
              onKeyDown={(e) => onEnterKey(e, () => setParticipants(itemNumber))}>
              <PeopleItemText>{itemNumber}</PeopleItemText>
            </PeopleItemContainer>
          )
        })}
        <ArrowButtonContainer>
          {showNextButton && (
            <ArrowContainer type="right" onClick={handleNext}>
              <Icon name="chevron_right" size={20} />
            </ArrowContainer>
          )}
        </ArrowButtonContainer>
      </PeopleContainer>
    )
  }

  const renderTimeSlots = () => {
    if (!currentSite) {
      return null
    }
    if (filteredTimeSlots.length === 0) {
      return (
        <BodyText>
          {i18n.t('screens.barclap.form.noTimeslot', {
            date: selectedDate,
          })}
        </BodyText>
      )
    }
    return filteredTimeSlots.map((slot, index) => {
      const bookingToModifyTimeSlot = !!bookingToModify && slot.id === bookingToModify.slotId
      const { capacity, nbPeople, startTime, endTime, id } = slot
      const startDateTime = timeSlotToTimezoneSite(selectedDate, startTime, currentSite.timezone)
      const endDateTime = timeSlotToTimezoneSite(selectedDate, endTime, currentSite.timezone)

      const availableSeats =
        !!bookingToModifyTimeSlot && !!bookingToModify
          ? capacity + bookingToModify.nbPeople - nbPeople
          : capacity - nbPeople
      const isTimeSlotDisable =
        availableSeats < participants || isAfter(utcToZonedTime(selectedDate, currentSite.timezone), endDateTime)
      const rate = Math.round((nbPeople / capacity) * 100)
      const isTimeSlotSelected = !!selectedTimeSlot && selectedTimeSlot.id === id
      const iconChoice: IconName =
        rate === FULL_ZONE ? 'gauge-full' : rate > ALMOST_FULL_ZONE ? 'gauge-middle' : 'gauge-empty'
      const occupancyColor = !isTimeSlotDisable
        ? rate === FULL_ZONE
          ? theme.colors.red
          : rate > ALMOST_FULL_ZONE
          ? theme.colors.orange
          : theme.colors.strongTurquoise
        : theme.colors.darken
      const isInitialTimeSlot = !!bookingToModify && bookingToModify.slotId === id

      return (
        <TimeSlotContainer
          tabIndex={0}
          role="option"
          aria-selected={isTimeSlotSelected}
          aria-description={i18n.t('label.ariaLabel.restaurant.form.slot', {
            timeSlot: i18n.t('screens.barclap.form.timeSlot', {
              start: startDateTime,
              end: endDateTime,
            }),
            occupancy: `${nbPeople}/${capacity}`,
            rate: `${rate}%`,
          })}
          key={startTime + ' - ' + endTime}
          disabled={isTimeSlotDisable}
          index={index}
          initialSlot={isInitialTimeSlot}
          onClick={() =>
            !isTimeSlotDisable && (!isTimeSlotSelected ? setSelectedTimeSlot(slot) : setSelectedTimeSlot(undefined))
          }
          selected={isTimeSlotSelected}>
          <CheckContainer>
            {isTimeSlotSelected ? <Icon name="check-circle" size={24} color={theme.colors.blue} /> : <Placeholder />}
          </CheckContainer>
          <TimeSlot disabled={isTimeSlotDisable} aria-hidden>
            {i18n.t('screens.barclap.form.timeSlot', {
              start: startDateTime,
              end: endDateTime,
            })}
          </TimeSlot>
          <OccupancyContainer>
            <OccupancyIconContainer>
              <Icon
                aria-hidden
                name="users-wm"
                size={16}
                color={isTimeSlotDisable ? theme.colors.darken : theme.colors.blue}
              />
            </OccupancyIconContainer>
            <OccupancyText
              aria-hidden
              color={
                isTimeSlotDisable ? theme.colors.darken : theme.colors.black
              }>{`${nbPeople}/${capacity}`}</OccupancyText>
            <OccupancyIconContainer>
              <Icon aria-hidden name={iconChoice} size={16} color={occupancyColor} />
            </OccupancyIconContainer>
            <OccupancyText aria-hidden color={occupancyColor}>{`${rate}%`}</OccupancyText>
          </OccupancyContainer>
        </TimeSlotContainer>
      )
    })
  }

  const renderForm = () => (
    <TabContentContainer>
      <FormContainer>
        <RowContainer>
          <Label>{i18n.t('screens.barclap.form.location')}</Label>
          <ChoiceContainerNotClickable>
            <ChoiceTextBlack>{name}</ChoiceTextBlack>
          </ChoiceContainerNotClickable>
        </RowContainer>

        <BlueLine role="presentation" />

        <RowContainer>
          <Label>{i18n.t('screens.planning.firstForm.date')}</Label>
          <ChoiceContainer disabled={true}>
            <DateContainer>
              <ChoiceText disabled={true}>
                {stringUtils.capitalize(i18n.t('screens.planning.reservationDate', { date: selectedDate }))}
              </ChoiceText>
              <Icon name="chevron_right" color={theme.colors.darkGrey} />
            </DateContainer>
          </ChoiceContainer>
        </RowContainer>

        <BlueLine role="presentation" />

        <RowContainer>
          <Label id="guestPicker">{i18n.t('screens.barclap.form.people')}</Label>
        </RowContainer>
        {renderPeople()}

        <BlueLine role="presentation" />

        <RowContainer>
          <Label id="timeslotTitle">{i18n.t('screens.barclap.form.timeSlotTitle')}</Label>
        </RowContainer>
        <TimeSlotListContainer role="listbox" aria-describedby="timeslotTitle">
          {renderTimeSlots()}
        </TimeSlotListContainer>
      </FormContainer>

      <ButtonContainer>
        <Button
          label={i18n.t('screens.barclap.form.confirm')}
          color={theme.colors.blue}
          textColor={theme.colors.white}
          font={theme.fonts.h3Bold}
          loading={isBooking}
          onClick={onBooking}
          disabled={!selectedTimeSlot}
        />
      </ButtonContainer>
      <ButtonContainer>
        <Button
          label={i18n.t('common.cancel')}
          onClick={() => {
            if (!!bookingToModify) {
              setBookingToModify(undefined)
              setCurrentTab('BOOKINGS')
            } else {
              closeModal()
            }
          }}
          color={theme.colors.white}
          textColor={theme.colors.blue}
          shadow
          font={theme.fonts.h3Bold}
        />
      </ButtonContainer>
    </TabContentContainer>
  )

  const renderInfos = (icon: IconName, label: string) => (
    <RowContainer>
      <IconContainer>
        <Icon name={icon} size={20} color={theme.colors.blue} />
      </IconContainer>
      <Label>{label}</Label>
    </RowContainer>
  )

  const renderBooking = (item: BookingV6) => {
    if (!currentSite) {
      return null
    }
    const { startTime, endTime, nbPeople, qrCode } = item
    const startDateTime = timeSlotToTimezoneSite(selectedDate, startTime, currentSite.timezone)
    const endDateTime = timeSlotToTimezoneSite(selectedDate, endTime, currentSite.timezone)
    const disableModifications = isAfter(utcToZonedTime(selectedDate, currentSite.timezone), startDateTime)
    return (
      <BookingContainer key={item.id}>
        <DateBooking>
          {stringUtils.capitalize(
            i18n.t(`screens.barclap.bookings.todayDate`, {
              date: selectedDate,
            })
          )}
        </DateBooking>
        <QrCodeImageContainer>
          <QrCodeImage src={`data:image/jpeg;base64,${qrCode}`} alt={i18n.t('label.ariaLabel.restaurant.qrCode')} />
        </QrCodeImageContainer>
        {renderInfos('building', name)}
        {renderInfos(
          'user',
          i18n.t(`screens.barclap.form.${nbPeople > 1 ? 'guestCount2' : 'noGuest'}`, {
            count: item.nbPeople - 1,
          })
        )}
        {renderInfos(
          'clock',
          i18n.t('screens.barclap.form.timeSlot', {
            start: startDateTime,
            end: endDateTime,
          })
        )}
        {!disableModifications && (
          <>
            <ButtonContainer>
              <Button
                label={i18n.t('screens.barclap.bookings.modifyBooking')}
                color={theme.colors.white}
                textColor={theme.colors.blue}
                font={theme.fonts.bodyBold}
                onClick={() => {
                  setCurrentTab('FORM')
                  setBookingToModify(item)
                }}
                loading={isBooking}
                shadow
              />
            </ButtonContainer>
            <ButtonContainer>
              <Button
                label={i18n.t('screens.barclap.bookings.deleteBooking')}
                color={theme.colors.blue}
                textColor={theme.colors.white}
                font={theme.fonts.bodyBold}
                onClick={() => onDeleteBooking(item)}
                loading={isBooking}
              />
            </ButtonContainer>
          </>
        )}
      </BookingContainer>
    )
  }

  const renderBookings = () => (
    <BookingsContainer>
      {bookings.length === 0 ? (
        <CenteredDiv>
          <BodyText>{i18n.t('screens.barclap.bookings.noBookings')}</BodyText>
        </CenteredDiv>
      ) : (
        <TabContentContainer>{bookings.map(renderBooking)}</TabContentContainer>
      )}
      <ButtonContainer>
        <Button
          label={i18n.t('common.close')}
          onClick={closeModal}
          color={theme.colors.white}
          textColor={theme.colors.blue}
          shadow
          font={theme.fonts.h3Bold}
        />
      </ButtonContainer>
    </BookingsContainer>
  )

  return (
    <MainContainer>
      <Title>{i18n.t('screens.barclap.title')}</Title>
      {statusScreen === 'loading' ? (
        <CenteredDiv>
          <Loader type="TailSpin" color={theme.colors.blue} />
        </CenteredDiv>
      ) : statusScreen === 'error' ? (
        <BodyText>{i18n.t('screens.barclap.bookings.error')}</BodyText>
      ) : currentTab === 'FORM' ? (
        renderForm()
      ) : (
        renderBookings()
      )}
    </MainContainer>
  )
}

export default TimeBookingModal

const MainContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  padding: 20px;
  max-height: 70vh;
  width: 400px;
  @media only screen and (max-width: ${windowSizeBreakpoints.medium}px) {
    width: 280px;
  }
  overflow: auto;
`

const Title = styled.p`
  ${(props) => props.theme.fonts.bodyBold}
  font-size: 18px;
  line-height: 20px;
  margin: 20px 0px 20px 10px;
`

const CenteredDiv = styled.div`
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
`

const BodyText = styled.p`
  ${(props) => props.theme.fonts.body};
  text-align: center;
`

const TabContentContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  padding: 0px 12px;
`

const FormContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
`

const RowContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  margin-bottom: 15px;
  align-items: center;
`

const Label = styled.p`
  ${(props) => props.theme.fonts.label}
  margin: 0px;
  font-size: 16px;
  line-height: 19px;
  display: flex;
  flex: 1;
`

const ChoiceContainer = styled.div<{ disabled?: boolean }>`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  ${(props) => !props.disabled && 'cursor: pointer;'}
  flex: 1;
  max-height: 20px;
`

const ChoiceContainerNotClickable = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex: 1;
  max-height: 20px;
`

const ChoiceTextBlack = styled.p`
  ${(props) => props.theme.fonts.labelBold}
  color: ${(props) => props.theme.colors.black};
  font-size: 16px;
  line-height: 19px;
  margin: 0px;
  padding-right: 5px;
`

const BlueLine = styled.div`
  display: flex;
  width: 60px;
  height: 3px;
  border-radius: 2px;
  background-color: ${(props) => props.theme.colors.blue};
  margin-bottom: 15px;
`

const DateContainer = styled.div`
  display: flex;
`

const ChoiceText = styled.p<{ disabled?: boolean }>`
  ${(props) => props.theme.fonts.labelBold}
  color: ${(props) => (props.disabled ? props.theme.colors.middleGrey : props.theme.colors.blue)};
  font-size: 16px;
  line-height: 19px;
  margin: 0px;
  padding-right: 5px;
`

const TimeSlotListContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: 20px;
`

const TimeSlotContainer = styled.button<{
  index: number
  disabled?: boolean
  selected?: boolean
  initialSlot?: boolean
}>`
  position: relative;
  ${(props) => props.theme.constants.shadow};
  background-color: ${(props) => (!!props.disabled ? props.theme.colors.mediumLightGrey : props.theme.colors.white)};
  padding: 8px;
  margin: 10px;
  display: flex;
  flex: 1;
  flex-direction: column;
  border: 2px solid
    ${(props) =>
      !!props.initialSlot
        ? props.theme.colors.turquoise
        : !!props.disabled
        ? 'transparent'
        : !!props.selected
        ? props.theme.colors.blue
        : props.theme.colors.white};
  justify-content: center;
  align-items: center;
  box-shadow: 0 0 10px ${(props) => props.theme.colors.shadow};
  cursor: ${(props) => (!!props.disabled ? 'not-allowed' : 'pointer')};
`

const CheckContainer = styled.div`
  position: absolute;
  top: -8px;
  right: -8px;
  background-color: ${(props) => props.theme.colors.white};
  border-radius: 16px;
  z-index: 10;
`

const Placeholder = styled.div`
  height: 24px;
`

const TimeSlot = styled.p<{ disabled?: boolean }>`
  ${(props) => props.theme.fonts.bodyBold};
  color: ${(props) => (props.disabled ? props.theme.colors.darken : props.theme.colors.black)};
  font-size: 13px;
  line-height: 14px;
  margin: 0px;
`

const PeopleContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  margin-bottom: 15px;
  align-items: center;
  justify-content: center;
`

const PeopleItemContainer = styled('div')<{ selected?: boolean }>`
  display: flex;
  width: 40px;
  height: 40px;
  cursor: pointer;
  border-radius: 30px;
  justify-content: center;
  align-items: center;
  ${(props) => props.theme.constants.shadow.card};
  margin-right: 16px;
  border: 2px solid ${(props) => (props.selected ? props.theme.colors.blue : props.theme.colors.white)};
`

const PeopleItemText = styled.p`
  ${(props) => props.theme.fonts.menuSubtitle};
`

const ArrowContainer = styled.div<{ type: 'right' | 'left' }>`
  cursor: pointer;
  display: flex;
  flex: 1;
  justify-content: ${(props) => (props.type === 'right' ? 'flex-end' : 'flex-start')};
  margin: ${(props) => `0px ${props.type === 'right' ? '0px' : '16px'} 0px 0px`};
`

const ArrowButtonContainer = styled.div<{ margin?: boolean }>`
  width: 30px;
  ${(props) => props.margin && 'margin-right: 16px;'}
`

const ButtonContainer = styled.div`
  display: flex;
  flex: 1;
  margin-bottom: 20px;
`

const OccupancyContainer = styled.div`
  display: flex;
  flex-direction: row;
  padding: 0px 0px 6px 10px;
  margin-top: 8px;
`

const OccupancyIconContainer = styled.div`
  display: flex;
  margin-right: 6px;
  justify-content: center;
  align-items: center;
`

const OccupancyText = styled.p<{ color: string }>`
  ${(props) => props.theme.fonts.label};
  color: ${(props) => props.color};
  margin: 0px;
  padding: 3px 10px 0px 0px;
`

const BookingsContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
`

const BookingContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`

const DateBooking = styled.p`
  ${(props) => props.theme.fonts.h3Bold};
  color: ${(props) => props.theme.colors.blue};
  margin-bottom: 10px;
`

const QrCodeImageContainer = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  margin: 12px 20px;
`

const QrCodeImage = styled.img`
  object-fit: contain;
`

const IconContainer = styled.div`
  width: 30px;
  align-items: center;
  justify-content: center;
`
