import * as React from 'react'

import useI18n from 'i18n/useI18n'
import useNavigation from 'utils/navigation/useNavigation'

import styled from 'theme/styled-components'
import useTheme from 'theme/useTheme'

import Icon from 'components/icons/Icons'
import Button from 'components/button/Button'

import { windowSizeBreakpoints } from 'utils/breakpoints'

import useReducer from 'store/useReducer'
import * as surveysStore from 'surveys/store'

interface Props {
  findFeature?: FeatureV4
}

const SurveyBanner = ({ findFeature }: Props) => {
  const i18n = useI18n()
  const navigation = useNavigation()
  const [theme] = useTheme()

  const surveys = useReducer(surveysStore.store, (s) => s.surveys)
  const hiddenNotifSurveys = useReducer(surveysStore.store, (s) => s.hiddenNotifSurveys)

  const [isClosed, setIsClosed] = React.useState(false)

  const findSurveys = React.useMemo(
    () =>
      surveys.filter(
        (s) =>
          !hiddenNotifSurveys.includes(s.id) &&
          !!findFeature &&
          !!findFeature.typeId &&
          !!s.links &&
          s.links.featureSurveyLink &&
          s.links.featureSurveyLink.includes(findFeature.typeId)
      ),
    [surveys, hiddenNotifSurveys, findFeature]
  )

  const onlyOneSurvey = findSurveys.length === 1

  const goToSurvey = () => {
    if (onlyOneSurvey) {
      navigation.push(`/surveys/${findSurveys[0].id}`)
    } else {
      navigation.push('/surveys')
    }
  }

  const hideSurveys = () => {
    surveysStore.actions.setHiddenNotifSurveys([...hiddenNotifSurveys, ...findSurveys.map((s) => s.id)])
    setIsClosed(true)
  }

  const closeSurveys = () => setIsClosed(true)

  if (isClosed || findSurveys.length === 0) {
    return null
  }

  return (
    <BannerContainer>
      <TitleContainer>
        <Title>{i18n.t(`screens.surveys.banner.${onlyOneSurvey ? 'title' : 'titlePlural'}`)}</Title>
        <CrossContainer onClick={closeSurveys}>
          <Icon name="cross" size={24} />
        </CrossContainer>
      </TitleContainer>
      {onlyOneSurvey && <Description>{findSurveys[0].title}</Description>}
      <ButtonsContainer>
        <Button
          label={i18n.t('screens.surveys.banner.answer')}
          onClick={goToSurvey}
          color={theme.colors.blue}
          textColor={theme.colors.white}
          font={theme.fonts.labelBold}
          width={100}
        />
        <HideContainer onClick={hideSurveys}>
          <HideText>{i18n.t('screens.surveys.banner.hide')}</HideText>
        </HideContainer>
      </ButtonsContainer>
    </BannerContainer>
  )
}

export default SurveyBanner

const BannerContainer = styled('div')`
  width: 380px;
  padding: 20px 16px;
  background-color: ${(props) => props.theme.colors.white};
  box-shadow: 0px 3px 12px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  position: fixed;
  bottom: 40px;
  right: 40px;
  z-index: 200;
  @media only screen and (max-width: ${windowSizeBreakpoints.phone}px) {
    width: 80vw;
    left: 50%;
    transform: translateX(-50%);
  }
`

const TitleContainer = styled('div')`
  display: flex;
  flex-direction: row;
  align-items: center;
`

const Title = styled('p')`
  display: flex;
  flex: 1;
  ${(props) => props.theme.fonts.bodyBold};
  margin: 0;
`

const CrossContainer = styled('button')`
  padding: 4px 8px;
`

const Description = styled('p')`
  margin: 0;
  padding-top: 12px;
  ${(props) => props.theme.fonts.menuSubtitle};
`

const ButtonsContainer = styled('div')`
  display: flex;
  flex-direction: row;
  padding-top: 20px;
  align-items: center;
`

const HideContainer = styled('button')`
  padding-left: 16px;
`

const HideText = styled('p')`
  margin: 0;
  ${(props) => props.theme.fonts.menuSubtitle};
  text-decoration: underline;
`
