import * as React from 'react'
import useI18n from 'i18n/useI18n'

import styled from 'theme/styled-components'
import useTheme from 'theme/useTheme'

import Button from 'components/button/Button'
import Modal from 'components/modal/Modal'
import Icon from 'components/icons/Icons'
import ToastAlert from 'components/toast/ToastAlert'
import ValidationContent from 'components/modal/ValidationContent'

import * as sitesStore from 'sites/store/siteStore'
import * as userStore from 'store/user/user'
import useReducer from 'store/useReducer'

import api from 'restaurants/api/restaurantsApi'

import Logger from 'utils/Logger'
import { windowSizeBreakpoints } from 'utils/breakpoints'
import analytics, { analyticsKeys, analyticsActionKeys } from 'utils/analytics'
import { getCurrentBadgeWithSolde } from 'utils/badgeUtils'

import numbro from 'numbro'
import languages from 'numbro/dist/languages.min'

interface Props {
  closeModal: () => void
}

const MIN_AMOUNT = 10

const MyMRSReloadModal = ({ closeModal }: Props) => {
  const i18n = useI18n()
  const [theme] = useTheme()

  const user = useReducer(userStore.store, (s) => s.user)
  const currentSite = useReducer(sitesStore.store, (s) => s.site)
  const mySites = useReducer(sitesStore.store, (s) => s.mySites)
  const badges = useReducer(userStore.store, (s) => s.badges)

  const currentBadge = React.useMemo(
    () => (!!currentSite ? getCurrentBadgeWithSolde(badges, currentSite.id) : undefined),
    [currentSite, badges]
  )
  const solde = React.useMemo(() => currentBadge?.solde, [currentBadge])

  const [amount, setAmount] = React.useState('')
  const amountFloat = React.useMemo(() => parseFloat(amount.replace(',', '.')) || 0, [amount])

  const [isReloading, setIsReloading] = React.useState(false)

  Object.getOwnPropertyNames(languages).forEach((lang) => {
    numbro.registerLanguage(languages[lang])
  })
  numbro.setLanguage('fr-FR')

  const splitInTwo = (text: string, splitText: string) => {
    //SplitInTwo vient séparer le text en deux parties distinctes à partir du premier splitText trouvé,
    //contrairement au .split() qui sépare en plusieurs parties
    const index = text.indexOf(splitText)
    return index >= 0 ? [text.slice(0, index), text.slice(index + splitText.length)] : [text, '']
  }

  React.useEffect(() => {
    adjustAmountInputSize()
  }, [amount])

  const adjustAmountInputSize = () => {
    const amountInput = document.getElementById('amountInput') as any
    if (amountInput) {
      if (amount.length < 1) {
        amountInput.style.width = amountInput.placeholder.length + 1 + 'ch'
      } else {
        amountInput.style.width = amount.length + 1 + 'ch'
      }
    }
  }

  const transformInput = (text: string) => {
    const textOnlyDigitAndComma = text.replace(/[^0-9,]+/g, '')
    const thereIsComma = textOnlyDigitAndComma.includes(',')
    if (thereIsComma) {
      const [left, right] = splitInTwo(textOnlyDigitAndComma, ',')
      const newRight = (right || '').replace(',', '').slice(0, 2)
      const newLeft = (left || '').slice(0, 3)
      setAmount(`${newLeft || '0'},${newRight}`)
    } else {
      setAmount(textOnlyDigitAndComma.slice(0, 3))
    }
  }

  const renderQuickReloadButton = (quickAmount: number) => {
    const selected = amountFloat === quickAmount
    return (
      <QuickReloadButton key={`${quickAmount}`} onClick={() => setAmount(`${quickAmount}`)}>
        <QuickReloadLabel isSelected={selected}>{quickAmount + '€'}</QuickReloadLabel>
      </QuickReloadButton>
    )
  }

  const reload = () => {
    if (!!user && !!currentSite) {
      analytics.track({
        screenName: analyticsKeys.myMRS,
        userType: user.type,
        currentSite,
        mySites,
        action: analyticsActionKeys.mrsReloadBadge,
      })
      setIsReloading(true)
      api
        .getReloadMRSBadgeURL(user.type, currentSite.id, amountFloat)
        .then((res) => {
          closeModal()
          window.open(res.url)
        })
        .catch((err) => {
          Logger.error(err)
          ToastAlert.open(() => (
            <ValidationContent
              title={i18n.t('errors.common')}
              description={i18n.t('screens.mymrs.reloadURLError')}
              onConfirm={ToastAlert.close}
            />
          ))
        })
        .finally(() => setIsReloading(false))
    }
  }

  if (!user || !currentSite) {
    return null
  }

  return (
    <ContentModal>
      <TopContainer>
        <TitleContainer>
          <Title>{i18n.t('screens.mymrs.reloadAccount')}</Title>
          <SubTitle>
            {i18n.t('screens.mymrs.currentAmount', { amount: numbro(solde || 0).formatCurrency({ mantissa: 2 }) })}
          </SubTitle>
        </TitleContainer>
        <CrossContainer onClick={() => Modal.close()} aria-label={i18n.t('label.ariaLabel.windowCrossIcon')}>
          <Icon name="cross" size={30} />
        </CrossContainer>
      </TopContainer>
      <MiddleContainer>
        <AmountInputContainer>
          <AmountTextInput
            autoFocus
            placeholder="0.00"
            id="amountInput"
            onChange={(evt) => {
              transformInput(evt.target.value)
            }}
            value={amount}
          />
          <AmountSign disabled={amount.length === 0}>{' €'}</AmountSign>
        </AmountInputContainer>
        {amountFloat < MIN_AMOUNT && <InputError>{i18n.t('screens.mymrs.errorAmount')}</InputError>}
      </MiddleContainer>
      <BottomContainer>
        <QuickReloadsContainer>
          {renderQuickReloadButton(10)}
          {renderQuickReloadButton(20)}
          {renderQuickReloadButton(30)}
          {renderQuickReloadButton(50)}
        </QuickReloadsContainer>
        <ButtonContainer>
          <Button
            loading={isReloading}
            disabled={amountFloat < MIN_AMOUNT || isReloading}
            label={i18n.t('screens.mymrs.addToAccount')}
            onClick={reload}
            font={theme.fonts.bodyBold}
          />
        </ButtonContainer>
      </BottomContainer>
    </ContentModal>
  )
}

export default MyMRSReloadModal

const ContentModal = styled('div')`
  display: flex;
  flex-direction: column;
  height: 600px;
  width: 395px;
  padding: 40px;
  gap: 20px 0px;
  @media only screen and (max-width: ${windowSizeBreakpoints.medium}px) {
    width: 80vw;
    max-width: 395px;
    padding: 30px;
  }
  @media only screen and (max-width: ${windowSizeBreakpoints.phone}px) {
    padding: 20px;
  }
`

const TopContainer = styled('div')`
  display: flex;
  justify-content: space-between;
  align-items: start;
`

const TitleContainer = styled('div')`
  display: flex;
  flex-direction: column;
  gap: 10px 0px;
`

const Title = styled('h2')`
  ${(props) => props.theme.fonts.h2Bold};
  margin: 0px;
`

const SubTitle = styled('label')`
  ${(props) => props.theme.fonts.bodyBold};
  color: ${(props) => props.theme.colors.middleGrey};
`

const CrossContainer = styled('button')`
  cursor: pointer;
`

const MiddleContainer = styled('div')`
  display: flex;
  flex-direction: column;
  flex: 1;
  align-items: center;
  justify-content: center;
`

const AmountInputContainer = styled('div')`
  display: flex;
  justify-content: center;
`

const AmountTextInput = styled('input')`
  border: 0;
  outline: 0;
  ${(props) => props.theme.fonts.bigInput};
  color: ${(props) => props.theme.colors.blue};
  border-bottom: 1px solid ${(props) => props.theme.colors.darkGrey};
  background: transparent;
  padding: 0px;
  ::placeholder {
    font-weight: 300;
    color: ${(props) => props.theme.colors.middleGrey};
  }
  &:focus {
    border-bottom-color: ${(props) => props.theme.colors.blue};
  }
`

const AmountSign = styled('label')<{ disabled: boolean }>`
  ${(props) => props.theme.fonts.bigInput};
  color: ${(props) => (props.disabled ? props.theme.colors.middleGrey : props.theme.colors.blue)};
`

const BottomContainer = styled('div')`
  display: flex;
  flex-direction: column;
  gap: 20px 0px;
`

const QuickReloadsContainer = styled('div')`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const QuickReloadButton = styled('button')`
  display: flex;
  height: 64px;
  width: 64px;
  border-radius: 32px;
  background-color: ${(props) => props.theme.colors.white};
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  align-items: center;
  justify-content: center;
`

const QuickReloadLabel = styled('p')<{ isSelected: boolean }>`
  ${(props) => props.theme.fonts.h3Bold};
  color: ${(props) => (props.isSelected ? props.theme.colors.blue : props.theme.colors.black)};
  cursor: pointer;
`

const ButtonContainer = styled('div')`
  display: flex;
  flex: 1;
  justify-content: center;
  padding: 0px 20px;
`

const InputError = styled('label')`
  ${(props) => props.theme.fonts.label};
  color: ${(props) => props.theme.colors.red};
  margin-top: 5px;
`
