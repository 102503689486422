import * as React from 'react'

import './react-datepicker.css'
import useI18n from 'i18n/useI18n'
import { locales } from 'i18n/i18n'

import useReducer from 'store/useReducer'
import * as ThemeStore from 'theme/store'

import DatePicker from 'react-datepicker'
import FocusLock from 'react-focus-lock'

interface Props {
  selectedDate: Date
  onChange: (d: Date) => void
  customInput?: JSX.Element
  minDate?: Date
  maxDate?: Date
  closePicker?: () => void
  filterDate?: (d: Date) => boolean
  excludeDates?: Date[]
  withPortal?: boolean
  withoutFocus?: boolean
}

const CustomDatePicker = ({
  selectedDate,
  onChange,
  customInput,
  minDate,
  maxDate,
  closePicker,
  filterDate,
  excludeDates,
  withPortal = true,
  withoutFocus = false,
}: Props) => {
  const i18n = useI18n()
  const theme = useReducer(ThemeStore.store, (s) => s.theme)

  const onKeyDown: React.KeyboardEventHandler = (evt) => {
    if (!!closePicker && evt.key === 'Escape') {
      closePicker()
    }
  }

  const renderDatePicker = () => (
    <DatePicker
      onKeyDown={onKeyDown}
      selected={selectedDate}
      onChange={onChange}
      customInput={customInput}
      minDate={minDate}
      maxDate={maxDate}
      locale={locales[i18n.lang]}
      excludeDates={excludeDates}
      withPortal={withPortal}
      inline
      onSelect={closePicker}
      preventOpenOnFocus
      tabIndex={-1}
      filterDate={filterDate}
      onClickOutside={closePicker}
      calendarClassName={theme === 'accessible' ? 'contrast' : undefined}
    />
  )

  if (withoutFocus) {
    return renderDatePicker()
  }

  return (
    <FocusLock autoFocus={withPortal} returnFocus>
      {renderDatePicker()}
    </FocusLock>
  )
}

export default CustomDatePicker
