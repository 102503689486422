import * as React from 'react'
import styled from 'styled-components'
import useTheme from 'theme/useTheme'

import useI18n from 'i18n/useI18n'

import { DirectoryAttendee } from 'roombooking/types'

import { windowSizeBreakpoints } from 'utils/breakpoints'

import Button from 'components/button/Button'
import AttendeeInfos from './AttendeeInfos'
import ValidationContent from 'components/modal/ValidationContent'
import Alert from 'components/alert/Alert'
import ToastAlert from 'components/toast/ToastAlert'
import Directory from 'directory/DirectoryScreen'
import Icons from 'components/icons/Icons'
import Modal from 'components/modal/Modal'

import useReducer from 'store/useReducer'
import * as siteStore from 'sites/store/siteStore'

interface Props {
  attendees: DirectoryAttendee[]
  setAttendees: React.Dispatch<React.SetStateAction<DirectoryAttendee[]>>
}

const AttendeesModal = ({ attendees, setAttendees }: Props) => {
  const [Theme] = useTheme()
  const i18n = useI18n()

  const currentSite = useReducer(siteStore.store, (s) => s.site)

  const [customList, setCustomList] = React.useState(attendees)

  const customRemove = (attendee: DirectoryAttendee) => {
    const newAttendeesList = customList.filter((a) => (!!attendee.id ? a.id !== attendee.id : a.mail !== attendee.mail))
    setAttendees(newAttendeesList)
    setCustomList(newAttendeesList)
  }

  const openDirectory = () => {
    Alert.open(() => (
      <Directory
        title={i18n.t(`screens.room.attendees.addingAttendees${currentSite?.id === '4' ? 'OrResource' : ''}`)}
        addVisitorMode
        onSelectContact={addingAttendees}
        modalType="alert"
        searchAriaLabel={i18n.t('label.ariaLabel.room.searchParticipant')}
      />
    ))
  }

  const addingAttendees = (contact: UserData, external?: boolean) => {
    if (customList.find((p) => (external ? p.mail === contact.email : p.id === contact.graphId))) {
      ToastAlert.open(() => (
        <ValidationContent
          title={i18n.t(`screens.room.attendees.alreadyParticipating${currentSite?.id === '4' ? 'WithResource' : ''}`)}
          onConfirm={ToastAlert.close}
          ariaLabelConfirm={i18n.t('label.ariaLabel.windowCrossIcon')}
        />
      ))
    } else if (!contact.email || contact.email === '') {
      ToastAlert.open(() => (
        <ValidationContent
          title={i18n.t('screens.directory.noEmail')}
          onConfirm={ToastAlert.close}
          ariaLabelConfirm={i18n.t('label.ariaLabel.windowCrossIcon')}
        />
      ))
    } else {
      const newDirectoryAttendee: DirectoryAttendee = {
        id: contact.graphId,
        givenName: contact.firstname,
        surname: contact.lastname,
        mail: contact.email,
        type: external ? 'EXTERNAL' : 'ENGIE',
      }
      const newAttendeesList = [...customList, newDirectoryAttendee]
      setAttendees(newAttendeesList)
      setCustomList(newAttendeesList)
      Alert.close()
    }
  }

  return (
    <MainContainer>
      <CrossContainer>
        <CrossTouchableContainer onClick={Modal.close} aria-label={i18n.t('label.ariaLabel.windowCrossIcon')}>
          <Icons name="cross" color={Theme.colors.darkGrey} size={25} />
        </CrossTouchableContainer>
      </CrossContainer>
      <Title>{i18n.t(`screens.room.attendees.listAttendees${currentSite?.id === '4' ? 'AndResources' : ''}`)}</Title>
      <NumberOfAttendees>
        {i18n.t(`screens.room.attendees.numberAttendees${currentSite?.id === '4' ? 'AndResources' : ''}`, {
          count: customList.length,
        })}
      </NumberOfAttendees>

      <div>
        {customList.map((a, i, list) => (
          <AttendeeInfos
            attendee={a}
            isLast={list.length === i + 1}
            key={a.mail}
            removeAttendeeFunction={customRemove}
          />
        ))}
      </div>

      <ButtonCenterContainer attendeesEmpty={customList.length === 0}>
        <ButtonContainer>
          <Button
            label={i18n.t(`screens.room.attendees.addAttendees${currentSite?.id === '4' ? 'OrResource' : ''}`)}
            color={Theme.colors.blue}
            verticalPadding={14}
            font={Theme.fonts.h3Bold}
            onClick={openDirectory}
          />
        </ButtonContainer>
      </ButtonCenterContainer>
    </MainContainer>
  )
}

// CONTAINERS

const MainContainer = styled('div')`
  display: flex;
  flex-direction: column;
  width: 360px;
  padding: 40px;
  @media only screen and (max-width: ${windowSizeBreakpoints.phone}px) {
    padding: 20px;
    width: calc(100vw - 70px);
  }
`
const ButtonContainer = styled('div')`
  display: flex;
  width: 100%;
  margin: 0px 24px;
`
const ButtonCenterContainer = styled('div')<{ attendeesEmpty: boolean }>`
  display: flex;
  align-items: center;
  width: 100%;
  margin-top: ${(props) => (props.attendeesEmpty ? 20 : 5)}px;
`

const CrossContainer = styled('div')`
  display: flex;
  align-items: center;
  justify-content: flex-end;
`

const CrossTouchableContainer = styled('button')`
  display: flex;
  align-items: center;
`

// TEXTES

const Title = styled('h1')`
  ${(props) => props.theme.fonts.contentTitle};
  color: ${(props) => props.theme.colors.darkGrey};
  font-size: 24px;
  line-height: 30px;
  margin: 0px 0px 20px 0px;
`
const NumberOfAttendees = styled('h2')`
  ${(props) => props.theme.fonts.bodyBold};
  color: ${(props) => props.theme.colors.blue};
  margin: 0px 0px 6px 0px;
  padding: 6.5px 0px 6.5px 24px;
  border-top: 0.5px solid ${(props) => props.theme.colors.mediumDarkGrey};
  border-bottom: 1px solid ${(props) => props.theme.colors.mediumDarkGrey};
`

export default AttendeesModal
