import React from 'react'

import styled from 'theme/styled-components'
import useTheme from 'theme/useTheme'

import Button from 'components/button/Button'
import Logger from 'utils/Logger'
import Toast from 'components/toast/Toast'
import ToastContent from 'components/toast/ToastContent'
import ValidationCode from 'components/input/ValidationCode'
import Modal from 'components/modal/Modal'
import CodeModalContent from 'components/modal/ValidationContent'

import useI18n from 'i18n/useI18n'
import useNavigation from 'utils/navigation/useNavigation'

import { object, string, ref, number } from 'yup'
import { Formik } from 'formik'
import analytics from 'utils/analytics'

import api from 'authent365/api/loginApi'
import accountApi from 'account/accountApi'

import * as userStore from 'store/user/user'
import oauth2 from 'authent365/oauth/OAuth2'
import Icons from '../icons/Icons'

declare type Status = 'email' | 'password' | 'validationCode' | 'register' | 'newPassword'
declare type ErrorStatus = 'email' | 'password' | 'code' | 'register' | 'blocked' | undefined
declare type ValidationCodeType = 'RESET_PASSWORD' | 'CREATE_ACCOUNT'

interface AuthentFormikTypes {
  email: string
  password: string
  newPassword: string
  firstName: string
  lastName: string
  code: string
}

const MAX_CODE_ATTEMPTS = 3
const MAX_PASSWORD_ATTEMPTS = 7

const FORMAT_REGEX = /[a-zA-ZàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð ,.'-]+$/
const UPPER_CASE_REGEX = /^[a-zA-ZàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð ,.'-]+$/
const LENGHT_REGEX = /^[a-zA-ZàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð ,.'-]{1,40}$/

const AuthentForm = () => {
  const [Theme] = useTheme()
  const [errorType, setErrorType] = React.useState<ErrorStatus>()
  const [loginStatus, setLoginStatus] = React.useState<Status>('email')
  const [loading, setLoading] = React.useState(false)
  const [token, setToken] = React.useState('')
  const [remainingCodeAttempts, setRemainingCodeAttemps] = React.useState(MAX_CODE_ATTEMPTS)
  const [codeType, setCodeType] = React.useState<ValidationCodeType>('CREATE_ACCOUNT')
  const [remainingPasswordAttempts, setRemainingPasswordAttemps] = React.useState(MAX_PASSWORD_ATTEMPTS)
  const [blockedDate, setBlockedDate] = React.useState<string>('')

  const i18n = useI18n()
  const navigation = useNavigation()

  const passwordForgotten = (email: string) => {
    api
      .sendValidationCode(email)
      .then(() => {
        setLoginStatus('validationCode')
        setCodeType('RESET_PASSWORD')
        setRemainingCodeAttemps(MAX_CODE_ATTEMPTS)
        Toast.open(() => ToastContent(i18n.t('screens.login.codeSent'), Theme.colors.blue), 'top')
      })
      .catch((err) => {
        Logger.error(err)
        Modal.open(() => (
          <CodeModalContent
            title={i18n.t(`screens.planning.errorMessages.commonError`)}
            onConfirm={Modal.close}
            ariaLabelConfirm={i18n.t('label.ariaLabel.windowCrossIcon')}
          />
        ))
      })
      .finally(() => setLoading(false))
  }

  const validate = () =>
    loginStatus === 'email'
      ? object().shape({
          email: string().email(i18n.t('errors.form.email')).required(i18n.t('errors.form.mandatory')),
        })
      : loginStatus === 'password'
      ? object().shape({
          email: string().email(i18n.t('errors.form.email')).required(i18n.t('errors.form.mandatory')),
          password: string().required(i18n.t('errors.form.mandatory')),
        })
      : loginStatus === 'register'
      ? object().shape({
          email: string().email(i18n.t('errors.form.email')).required(i18n.t('errors.form.mandatory')),
          password: string()
            .matches(
              /^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*[@\$!%\*#?&\/\+\-\_=\\,;:.()€]).{14,}$/,
              i18n.t('errors.form.passwordFormat')
            )
            .required(i18n.t('errors.form.mandatory')),
          firstName: string()
            .matches(FORMAT_REGEX, i18n.t('errors.form.firstNameFormat'))
            .matches(UPPER_CASE_REGEX, i18n.t('errors.form.firstNameUpperCase'))
            .matches(LENGHT_REGEX, i18n.t('errors.form.firstNameLength'))
            .required(i18n.t('errors.form.mandatory')),
          lastName: string()
            .matches(FORMAT_REGEX, i18n.t('errors.form.lastNameFormat'))
            .matches(UPPER_CASE_REGEX, i18n.t('errors.form.lastNameUpperCase'))
            .matches(LENGHT_REGEX, i18n.t('errors.form.lastNameLength'))
            .required(i18n.t('errors.form.mandatory')),
          passwordRepeat: string()
            .oneOf([ref('password'), null], i18n.t('errors.form.passwordMatch'))
            .matches(
              /^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*[@\$!%\*#?&\/\+\-\_=\\,;:.()€]).{14,}$/,
              i18n.t('errors.form.passwordFormat')
            )
            .required(i18n.t('errors.form.mandatory')),
        })
      : loginStatus === 'newPassword'
      ? object().shape({
          newPassword: string()
            .matches(
              /^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*[@\$!%\*#?&\/\+\-\_=\\,;:.()€]).{14,}$/,
              i18n.t('errors.form.passwordFormat')
            )
            .required(i18n.t('errors.form.mandatory')),
          passwordRepeat: string()
            .oneOf([ref('newPassword'), null], i18n.t('errors.form.passwordMatch'))
            .matches(
              /^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*[@\$!%\*#?&\/\+\-\_=\\,;:.()€]).{14,}$/,
              i18n.t('errors.form.passwordFormat')
            )
            .required(i18n.t('errors.form.mandatory')),
        })
      : object().shape({ code: number().required(i18n.t('errors.form.mandatory')) })

  const submitActions = ({ email, password, newPassword, firstName, lastName, code }: AuthentFormikTypes) => {
    setLoading(true)
    switch (loginStatus) {
      case 'email':
        api
          .getGuestStatus(email)
          .then((status) => {
            if (status === 204) {
              api.sendValidationCode(email).then(() => {
                setLoginStatus('validationCode')
                setCodeType('CREATE_ACCOUNT')
                setRemainingCodeAttemps(MAX_CODE_ATTEMPTS)
                Toast.open(() => ToastContent(i18n.t('screens.login.codeSent'), Theme.colors.blue), 'top')
              })
            } else {
              setLoginStatus('password')
            }
          })
          .catch((err) => {
            setErrorType('email')
            Logger.error(err)
          })
          .finally(() => setLoading(false))
        break
      case 'password':
        oauth2
          .login(email, password)
          .then((oauth2Res) => {
            oauth2.saveRefreshToken(oauth2Res).then(() =>
              accountApi.getInfo('EXTERNAL').then((u) => {
                if (u.id || u.email) {
                  analytics.identify((u.id || u.email) as string)
                }
                userStore.actions.setUser(u)
                navigation.push('/')
              })
            )
          })
          .catch((err) => {
            Logger.error(err)
            if (err.code === 400) {
              if (err.data.error === 'WRONG_CREDENTIALS') {
                setErrorType('password')
                setRemainingPasswordAttemps(parseInt(err.data.error_description))
              } else if (err.data.error === 'LOCKED') {
                setBlockedDate(err.data.error_description as string)
                setErrorType('blocked')
                Modal.open(() => (
                  <CodeModalContent
                    title={i18n.t('errors.form.lockedAccountTitle')}
                    onConfirm={Modal.close}
                    description={i18n.t('errors.form.lockedAccount')}
                  />
                ))
              }
            } else {
              Modal.open(() => (
                <CodeModalContent
                  title={i18n.t('errors.form.contactAdmin')}
                  onConfirm={Modal.close}
                  description={i18n.t('errors.form.unknownError')}
                />
              ))
            }
          })
          .finally(() => setLoading(false))
        break
      case 'newPassword':
        api
          .newPassword(newPassword, token)
          .then(() =>
            oauth2.login(email, newPassword).then((oauth2Res) => {
              oauth2.saveRefreshToken(oauth2Res).then(() =>
                accountApi.getInfo('EXTERNAL').then((u) => {
                  userStore.actions.setUser(u)
                  navigation.push('/')
                  Toast.open(() => ToastContent(i18n.t('screens.login.passwordChanged'), Theme.colors.blue))
                })
              )
            })
          )
          .catch((err) => {
            Logger.error(err)
          })
          .finally(() => setLoading(false))
        break
      case 'validationCode':
        api
          .validateCode(email, code, codeType)
          .then((response) => {
            setToken(response.token)
            setLoginStatus(codeType === 'RESET_PASSWORD' ? 'newPassword' : 'register')
          })
          .catch((err) => {
            setErrorType('code')
            Logger.error(err)
            if (err.data.message === 'EXPIRED') {
              Modal.open(() => (
                <CodeModalContent
                  title={i18n.t('errors.form.wrongCode')}
                  onConfirm={Modal.close}
                  description={i18n.t('errors.form.expiredCode')}
                />
              ))
              setLoginStatus('email')
            } else if (
              (remainingCodeAttempts === 1 && err.data.message === 'ALIVE') ||
              err.data.message === 'DELETED'
            ) {
              Modal.open(() => (
                <CodeModalContent
                  title={i18n.t('errors.form.wrongCode')}
                  onConfirm={Modal.close}
                  description={i18n.t('errors.form.incorrectCodeLastAttempt')}
                />
              ))
              setLoginStatus('email')
            } else if (err.data.message === 'ALIVE') {
              setRemainingCodeAttemps((n) => n - 1)
            } else {
              Modal.open(() => (
                <CodeModalContent
                  title={i18n.t('errors.form.contactAdmin')}
                  onConfirm={Modal.close}
                  description={i18n.t('errors.form.unknownError')}
                />
              ))
            }
          })
          .finally(() => setLoading(false))
        break
      case 'register':
        api
          .createGuest(firstName, lastName, password, token)
          .then(() =>
            oauth2.login(email, password).then((oauth2Res) => {
              oauth2.saveRefreshToken(oauth2Res).then(() =>
                accountApi.getInfo('EXTERNAL').then((u) => {
                  userStore.actions.setUser(u)
                  navigation.push('/')
                })
              )
            })
          )
          .catch((err) => {
            setErrorType('register')
            Logger.error(err)
          })
          .finally(() => setLoading(false))
        break
      default:
        api
          .getGuestStatus(email)
          .then((status) => {
            if (status === 202) {
              setLoginStatus('password')
            }
          })
          .catch((err) => Logger.error(err))
          .finally(() => setLoading(false))
    }
  }

  return (
    <MainContainer>
      <HeaderContainer>
        {loginStatus !== 'email' && (
          <BackContainer onClick={() => setLoginStatus('email')}>
            <Icons name="chevron_left" size={20} color={Theme.colors.white} />
          </BackContainer>
        )}
        <MessageContainer>
          {loginStatus === 'register' ? (
            <>
              <MessageTitle>{i18n.t('screens.login.registerMessage')}</MessageTitle>
              <TextErrorPassword>{i18n.t('common.allFieldsObligatory')}</TextErrorPassword>
            </>
          ) : (
            <MessageText>{i18n.t(`screens.login.${loginStatus}Message`)}</MessageText>
          )}
          {loginStatus === 'validationCode' ? (
            <CodeRemainingAttemps>
              {errorType === 'code' &&
                i18n.t(`errors.form.remainingAttempts`, {
                  count: remainingCodeAttempts,
                })}
            </CodeRemainingAttemps>
          ) : (
            loginStatus === 'register' && (
              <TextErrorPassword>{errorType === 'register' && i18n.t('errors.form.errorRegister')}</TextErrorPassword>
            )
          )}
        </MessageContainer>
      </HeaderContainer>
      <Formik
        initialValues={{
          email: '',
          password: '',
          firstName: '',
          lastName: '',
          passwordRepeat: '',
          code: '',
          newPassword: '',
        }}
        validationSchema={validate}
        onSubmit={(values, { setSubmitting, setTouched }) => {
          submitActions(values)
          setSubmitting(false)
          setTouched({})
          values.code = ''
        }}>
        {({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting }) => (
          <FormContainer onSubmit={handleSubmit}>
            {loginStatus === 'validationCode' && (
              <CodeContainer
                title={i18n.t(`screens.login.validationCodeMessage`)}
                onKeyDown={(evt) => {
                  if (evt.key === 'Enter') {
                    handleSubmit()
                  }
                }}>
                <ValidationCode size={4} value={values.code} onChange={handleChange('code')} />
              </CodeContainer>
            )}
            {(loginStatus === 'email' || loginStatus === 'password') && (
              <Container>
                <Label htmlFor="email">{i18n.t('screens.login.email')}</Label>
                <TextInput
                  id="email"
                  autoComplete="email"
                  aria-required="true"
                  aria-describedby="loginEmailError"
                  width={280}
                  type="email"
                  name={i18n.t('screens.login.email')}
                  onChange={(e) => {
                    setErrorType(undefined)
                    handleChange('email')(e)
                  }}
                  onBlur={handleBlur('email')}
                  value={values.email}
                  disabled={loginStatus === 'password'}
                />
                <TextError id="loginEmailError">
                  {errors.email && touched.email ? errors.email : ''}
                  {errorType === 'email' && i18n.t('errors.form.emailDataBase')}
                </TextError>
              </Container>
            )}
            {loginStatus === 'register' && (
              <Container>
                <Label htmlFor="firstName">{i18n.t('screens.login.firstName')}</Label>
                <TextInput
                  id="firstName"
                  autoComplete="given-name"
                  aria-required="true"
                  aria-describedby="registerFirstNameError"
                  width={250}
                  name={i18n.t('screens.login.firstName')}
                  onChange={handleChange('firstName')}
                  onBlur={handleBlur('firstName')}
                  value={values.firstName}
                />
                <TextError id="registerFirstNameError">
                  {errors.firstName && touched.firstName ? errors.firstName : ''}
                </TextError>
                <Label htmlFor="lastName">{i18n.t('screens.login.lastName')}</Label>
                <TextInput
                  id="lastName"
                  autoComplete="family-name"
                  aria-required="true"
                  aria-describedby="registerLastNameError"
                  width={250}
                  name={i18n.t('screens.login.lastName')}
                  onChange={handleChange('lastName')}
                  onBlur={handleBlur('lastName')}
                  value={values.lastName}
                />
                <TextError id="registerLastNameError">
                  {errors.lastName && touched.lastName ? errors.lastName : ''}
                </TextError>
              </Container>
            )}
            {(loginStatus === 'password' || loginStatus === 'register') && (
              <Container>
                <Label htmlFor="password">{i18n.t('screens.login.password')}</Label>
                <TextInput
                  id="password"
                  autoComplete={loginStatus === 'register' ? 'new-password' : undefined}
                  aria-required="true"
                  aria-describedby="loginPasswordError"
                  width={250}
                  type="password"
                  name={i18n.t('screens.login.password')}
                  onChange={(e) => {
                    setErrorType(undefined)
                    handleChange('password')(e)
                  }}
                  onBlur={handleBlur('password')}
                  value={values.password}
                />
                <TextErrorPassword id="loginPasswordError">
                  {errors.password && touched.password ? errors.password : ''}
                  {errorType === 'password'
                    ? i18n.t('errors.form.wrongPassword', { count: remainingPasswordAttempts })
                    : errorType === 'blocked' && i18n.t('errors.form.blockedUntil', { blocked: new Date(blockedDate) })}
                </TextErrorPassword>
              </Container>
            )}
            {loginStatus === 'newPassword' && (
              <Container>
                <Label htmlFor="newPassword">{i18n.t('screens.account.newPassword')}</Label>
                <TextInput
                  id="newPassword"
                  autoComplete="new-password"
                  aria-required="true"
                  aria-describedby="loginNewPasswordError"
                  width={250}
                  type="password"
                  name={i18n.t('screens.login.password')}
                  onChange={(e) => {
                    setErrorType(undefined)
                    handleChange('newPassword')(e)
                  }}
                  onBlur={handleBlur('newPassword')}
                  value={values.newPassword}
                />
                <TextErrorPassword id="loginNewPasswordError">
                  {errors.newPassword && touched.newPassword ? errors.newPassword : ''}
                </TextErrorPassword>
              </Container>
            )}
            {(loginStatus === 'register' || loginStatus === 'newPassword') && (
              <Container>
                <Label htmlFor="repeatPassword">{i18n.t('screens.login.passwordRepeat')}</Label>
                <TextInput
                  id="repeatPassword"
                  aria-required="true"
                  aria-describedby="loginRepeatPasswordError"
                  width={250}
                  type="password"
                  name={i18n.t('screens.login.password')}
                  onChange={handleChange('passwordRepeat')}
                  onBlur={handleBlur('passwordRepeat')}
                  value={values.passwordRepeat}
                />
                <TextErrorPassword id="loginRepeatPasswordError">
                  {errors.passwordRepeat && touched.passwordRepeat ? errors.passwordRepeat : ''}
                </TextErrorPassword>
              </Container>
            )}
            <ButtonContainer>
              <Button
                type="submit"
                label={
                  loginStatus === 'email' || loginStatus === 'validationCode' || loginStatus === 'newPassword'
                    ? i18n.t('screens.login.continueButton')
                    : loginStatus === 'register'
                    ? i18n.t('screens.login.register_button')
                    : loginStatus === 'password'
                    ? i18n.t('screens.login.login_button')
                    : ''
                }
                disabled={isSubmitting}
                onClick={handleSubmit}
                font={Theme.fonts.h1}
                fontSize={18}
                loading={loading}
                ariaLabel={`label.ariaLabel.login.${loginStatus}Status`}
              />
            </ButtonContainer>
            <ForgotPasswordContainer>
              {loginStatus === 'password' && (
                <Password onClick={() => passwordForgotten(values.email)}>
                  {i18n.t('screens.login.passwordForgotten')}
                </Password>
              )}
            </ForgotPasswordContainer>
          </FormContainer>
        )}
      </Formik>
    </MainContainer>
  )
}

export default AuthentForm

const MainContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`
const FormContainer = styled('form')`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`
const TextError = styled.p`
  ${(props) => props.theme.fonts.body};
  font-size: 12px;
  color: ${(props) => props.theme.colors.yellow};
  height: 13px;
  margin-top: 5px;
`
const TextErrorPassword = styled('p')`
  ${(props) => props.theme.fonts.body};
  font-size: 12px;
  line-height: 13px;
  color: ${(props) => props.theme.colors.yellow};
  min-height: 13px;
  margin-top: 5px;
`
const CodeRemainingAttemps = styled('p')`
  ${(props) => props.theme.fonts.body};
  font-size: 12px;
  line-height: 13px;
  color: ${(props) => props.theme.colors.white};
  min-height: 13px;
  margin-top: 5px;
`
const TextInput = styled.input`
  width: 100%;
  border: 0;
  outline: 0;
  ${(props) => props.theme.fonts.bodyBold}
  border-bottom: 1px solid ${(props) => props.theme.colors.white};
  color: ${(props) => props.theme.colors.white};
  background: transparent;
  font-size: 15px;
  padding-bottom: 5px;
  &:focus {
    border-bottom-color: ${(props) => props.theme.colors.blue};
  }
`
const Label = styled('label')`
  ${(props) => props.theme.fonts.body};
  color: ${(props) => props.theme.colors.white};
  font-size: 11px;
  margin-bottom: 2px;
  margin-left: 1px;
`
const Password = styled('button')`
  ${(props) => props.theme.fonts.body};
  font-size: 12px;
  color: ${(props) => props.theme.colors.white};
  text-decoration: underline;
  &:hover {
    color: ${(props) => props.theme.colors.blue};
    text-decoration: none;
  }
  :focus-visible {
    outline: 2px solid ${(props) => props.theme.colors.white};
  }
`
const Container = styled.div`
  flex: 1;
  width: 280px;
`
const CodeContainer = styled.div`
  width: 298px;
`
const ButtonContainer = styled('div')`
  margin-top: 50px;
  margin-bottom: 20px;
  width: 280px;
  display: flex;
`
const MessageContainer = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex: 1;
`
const MessageText = styled('p')`
  ${(props) => props.theme.fonts.body};
  font-size: 15px;
  margin: 0px;
  color: ${(props) => props.theme.colors.white};
  text-align: center;
`
const MessageTitle = styled('h1')`
  ${(props) => props.theme.fonts.h1};
  margin-top: 0px;
  color: ${(props) => props.theme.colors.blue};
`
const ForgotPasswordContainer = styled('div')`
  height: 19px;
`

const HeaderContainer = styled('div')`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin: 60px 30px 30px;
`

const BackContainer = styled('div')`
  display: flex;
  padding: 8px;
  margin-right: 12px;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`
