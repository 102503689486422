import Webservice from 'utils/Webservice'
import { assureToken } from 'authent365/utils/utils'
import config from 'core/src/config'

const api = {
  getAllBookings: (userType: UserType) =>
    assureToken(userType).then((token) =>
      Webservice<BookingsV6>(userType, `${config.globalAPIVersion}/bookings`, 'GET', {
        Authorization: `Bearer ${token}`,
      })
    ),
  createBooking: (userType: UserType, body: BookingFormV6) =>
    assureToken(userType).then((token) =>
      Webservice<Restaurant>(
        userType,
        `${config.globalAPIVersion}/bookings`,
        'POST',
        {
          Authorization: `Bearer ${token}`,
        },
        undefined,
        body
      )
    ),
  modifyBooking: (userType: UserType, bookingId: string, body: BookingFormV6) =>
    assureToken(userType).then((token) =>
      Webservice<Restaurant>(
        userType,
        `${config.globalAPIVersion}/bookings/${bookingId}`,
        'PUT',
        {
          Authorization: `Bearer ${token}`,
        },
        undefined,
        body
      )
    ),
  deleteBooking: (userType: UserType, bookingId: string) =>
    assureToken(userType).then((token) =>
      Webservice<void>(userType, `${config.globalAPIVersion}/bookings/${bookingId}`, 'DELETE', {
        Authorization: `Bearer ${token}`,
      })
    ),
}

export default api
