import * as React from 'react'

import styled from 'theme/styled-components'
import useTheme from 'theme/useTheme'

import useI18n from 'i18n/useI18n'

import { windowSizeBreakpoints } from 'utils/breakpoints'
import stringUtils from 'utils/strings'
import utils from 'utils/strings'
import { daysToExclude, getClosestDate, getDatesInInterval, getDayTimezone, getWeekDaysNumber } from 'planning/utils'
import {
  TIME_OPTIONS,
  TYPE_PLACE_OPTIONS,
  TypePlace,
  WEEKDAYS,
  WEEK_DAY_SORTER,
  allowedDaysToBook,
  bookingsSharvyToSend,
  datesToAvoidSharvy,
  filterSharvyBookings,
  findSharvyStatusColor,
  isNotAllowedToBookSharvy,
  isSamePlaceBookedSharvy,
  minSelectableDateSharvy,
} from './utils'
import useNavigation from 'utils/navigation/useNavigation'
import analytics, { analyticsKeys } from 'utils/analytics'

import Icons from 'components/icons/Icons'
import Button from 'components/button/Button'
import EmptyImagePlaceholder from 'components/image/assets/intervention_vide.svg'
import Alert from 'components/alert/Alert'
import ValidationContent from 'components/modal/ValidationContent'
import CustomDatePicker from 'components/picker/CustomDatePicker'
import SwitchFilter from 'components/switch/SwitchFilter'
import Breadcrumb from 'components/breadcrumb/Breadcrumb'
import Modal from 'components/modal/Modal'
import ReservationTimeModal from 'planning/myPlanning/ReservationTimeModal'
import Banner from 'components/banner/Banner'
import SurveyBanner from 'components/banner/SurveyBanner'
import StatusLabel from 'components/label/StatusLabel'

import Loader from 'react-loader-spinner'
import SVG from 'react-inlinesvg'
import {
  isSameDay,
  isWeekend,
  addBusinessDays,
  isBefore,
  addDays,
  getDay,
  setHours,
  setMinutes,
  isAfter,
} from 'date-fns'

import useReducer from 'store/useReducer'
import * as userStore from 'store/user/user'
import * as siteStore from 'sites/store/siteStore'
import * as featureStore from 'sites/store/featureStore'
import * as bannersStore from 'banners/store'

import api from '../planning/api'

type Status = 'loading' | 'ok' | 'error'

const onEnterKey = (e: React.KeyboardEvent, onClick: () => void) => {
  if (e.key === 'Enter') {
    onClick()
  }
}

const getWindowDimensions = () => {
  const { innerWidth: width, innerHeight: height } = window
  return {
    width,
    height,
  }
}

const CURRENT_DATE = new Date()
const MIN_DATE = !isWeekend(CURRENT_DATE) ? CURRENT_DATE : addBusinessDays(CURRENT_DATE, 1)
const MAX_DAYS = 30

const ParkingSharvyScreen = () => {
  const i18n = useI18n()
  const [Theme] = useTheme()
  const navigation = useNavigation()

  const user = useReducer(userStore.store, (s) => s.user)
  const site = useReducer(siteStore.store, (s) => s.site)
  const features: Feature[] = useReducer(featureStore.store, (f) => f.features)
  const mySites = useReducer(siteStore.store, (s) => s.mySites)
  const banners = useReducer(bannersStore.store, (s) => s.banners)

  const [screenStatus, setScreenStatus] = React.useState<Status>('loading')
  const [sharvyBookings, setSharvyBookings] = React.useState<SharvyParkingBooking[]>([])
  const [parkingInfo, setParkingInfo] = React.useState<SharvyParkingInfo>()
  const [parkingAccess, setParkingAccess] = React.useState(false)
  const [isDatePickerVisible, setIsDatePickerVisible] = React.useState(false)
  const [isRecurrenceDatePickerVisible, setIsRecurrenceDatePickerVisible] = React.useState(false)
  const [selectedDate, setSelectedDate] = React.useState(MIN_DATE)
  const [isReserving, setIsReserving] = React.useState(false)
  const [isRecurrenceActive, setIsRecurrenceActive] = React.useState(false)
  const [selectedReservationTime, setSelectedReservatonTime] = React.useState<ReservationTime>('ALL_DAY')
  const [selectedTypePlace, setSelectedTypePlace] = React.useState<TypePlace>('STANDARD')
  const [timeOptions, setTimeOptions] = React.useState<ReservationTime[]>(TIME_OPTIONS)
  const [selectedWeekDays, setSelectedWeekDays] = React.useState<WeekDay[]>([])
  const [daysToBook, setDaysToBook] = React.useState<Date[]>([])
  const [filteredDaysToBook, setFilteredDaysToBook] = React.useState<Date[]>([])
  const [windowDimensions, setWindowDimensions] = React.useState(getWindowDimensions())

  const MAX_DATE = React.useMemo(
    () => addDays(setHours(setMinutes(MIN_DATE, 59), 23), site?.maxDeskBookingDays || MAX_DAYS),
    [MIN_DATE, site]
  )

  const datesToAvoid = React.useMemo(() => datesToAvoidSharvy(sharvyBookings), [sharvyBookings])

  const [endDateRecurrence, setEndDateRecurrence] = React.useState<Date>(
    getClosestDate(MIN_DATE, MAX_DATE, MAX_DATE || CURRENT_DATE, datesToAvoid, true) || MAX_DATE
  )

  const currentSiteHasSharvyParking = features.find((feature) => feature.type === 'SHARVY_PARKING')

  const listPlates = [user?.carPlate1, user?.carPlate2, user?.carPlate3].filter((plate) => !!plate).join(', ')

  const isMobile = React.useMemo(() => windowDimensions.width <= windowSizeBreakpoints.phone, [
    windowDimensions,
    windowSizeBreakpoints,
  ])

  const showedBanner = React.useMemo(
    () => banners.find((banner) => !!banner.feature && banner.feature === 'SHARVY_PARKING'),
    [banners]
  )

  const findFeature = React.useMemo(() => site?.features.find((f) => f.type === 'SHARVY_PARKING'), [site])

  const excludedDays = React.useMemo(() => {
    if (!!parkingInfo && !!parkingInfo.workingDays) {
      const workingDays = parkingInfo.workingDays
      return daysToExclude(workingDays)
    }
    return [0, 6]
  }, [parkingInfo])

  const filterWorkingDays = (date: Date) => !excludedDays.includes(getDay(date))

  const disabledDates = React.useMemo(() => getDatesInInterval(MIN_DATE, MAX_DATE, excludedDays).concat(datesToAvoid), [
    datesToAvoid,
    excludedDays,
  ])

  const minSelectableDate = React.useMemo(() => minSelectableDateSharvy(MIN_DATE, MAX_DATE, disabledDates), [
    disabledDates,
    MAX_DATE,
  ])

  React.useEffect(() => {
    if (disabledDates.find((date) => isSameDay(date, selectedDate))) {
      setSelectedDate(minSelectableDate)
    } else if (isAfter(minSelectableDate, selectedDate)) {
      setSelectedDate(minSelectableDate)
    } else if (isBefore(MAX_DATE, selectedDate)) {
      setSelectedDate(MAX_DATE)
    }
  }, [disabledDates, selectedDate, minSelectableDate])

  React.useEffect(() => {
    const findBookingDay = sharvyBookings.find((booking) =>
      isSameDay(getDayTimezone(booking.reservationDate || ''), selectedDate)
    )
    // On met le créneau qui n'est pas réservé
    if (!!findBookingDay) {
      if (findBookingDay.isMorning) {
        setSelectedReservatonTime('AFTERNOON')
        setTimeOptions(['AFTERNOON'])
      } else {
        setSelectedReservatonTime('MORNING')
        setTimeOptions(['MORNING'])
      }
    } else {
      setSelectedReservatonTime('ALL_DAY')
      setTimeOptions(TIME_OPTIONS)
    }
  }, [sharvyBookings, selectedDate])

  React.useEffect(() => {
    if (isRecurrenceActive) {
      const weekDaysNumber = getWeekDaysNumber(selectedWeekDays)
      const daysToBook = getDatesInInterval(selectedDate, endDateRecurrence, weekDaysNumber)
      const filteredAllowedDaysToBook = allowedDaysToBook(daysToBook, sharvyBookings, selectedReservationTime)
      if (!!daysToBook.find((day) => isSameDay(day, selectedDate))) {
        setDaysToBook(daysToBook)
        setFilteredDaysToBook(filteredAllowedDaysToBook)
      } else {
        setDaysToBook([selectedDate, ...daysToBook])
        setFilteredDaysToBook([selectedDate, ...filteredAllowedDaysToBook])
      }
    }
  }, [isRecurrenceActive, selectedWeekDays, endDateRecurrence, selectedReservationTime, selectedDate, sharvyBookings])

  const onClickBook = () => {
    if (!!user && !!site) {
      setIsReserving(true)
      const dateToSend = i18n.t('screens.planning.secondForm.dateToSend', {
        date: selectedDate,
      })
      const bookingsToSend: SharvyBookingRequest[] = bookingsSharvyToSend(
        dateToSend,
        isRecurrenceActive,
        selectedReservationTime,
        filteredDaysToBook,
        selectedTypePlace,
        i18n
      )
      const bodyRequest: SharvyBookingsForm = {
        bookings: bookingsToSend,
      }
      api.sharvy
        .postSharvyBookings(user.type, site.id, bodyRequest)
        .then((res) => {
          const filteredErrors = !!res.bookings ? res.bookings.filter((item) => !!item.result?.error) : []
          const filteredSucceed = !!res.bookings ? res.bookings.filter((item) => !item.result?.error) : []
          fetchSharvyInfos()
          Alert.open(
            () => (
              <ValidationContent
                title={i18n.t('screens.planning.modal.confirmedRegistration', { count: filteredSucceed.length })}
                onConfirm={() => {
                  Alert.close()
                }}
                ariaLabelConfirm={i18n.t('label.ariaLabel.windowCrossIcon')}
                description={
                  filteredErrors.length > 0
                    ? i18n.t('screens.planning.sharvy.errorDescription', {
                        listDates: filteredErrors.map(
                          (error, index) =>
                            `${index > 0 ? ' ' : ''}${utils.capitalize(
                              i18n.t('screens.planning.reservationDate', {
                                date: getDayTimezone(error.reservationDate || ''),
                              })
                            )} (${i18n.t(
                              `screens.planning.selectionOnPlanModal.reservationTime.${
                                error.isMorning ? 'MORNING' : 'AFTERNOON'
                              }`
                            )})`
                        ),
                      })
                    : undefined
                }
              />
            ),
            true
          )
        })
        .catch(() => {
          Alert.open(
            () => (
              <ValidationContent
                title={i18n.t('screens.planning.modal.errorRegistration')}
                onConfirm={Alert.close}
                ariaLabelConfirm={i18n.t('label.ariaLabel.windowCrossIcon')}
              />
            ),
            true
          )
        })
        .finally(() => setIsReserving(false))
    }
  }

  const openTimeSlotSelector = () =>
    Modal.open(() => (
      <ReservationTimeModal
        title={i18n.t('screens.desk.time')}
        options={timeOptions}
        setChoice={(newTime) => setSelectedReservatonTime(newTime as ReservationTime)}
        typeModal="modal"
        type="time"
      />
    ))

  const openTypePlaceSelector = () =>
    Modal.open(() => (
      <ReservationTimeModal
        title={i18n.t('screens.planning.firstForm.typeSharvy')}
        options={TYPE_PLACE_OPTIONS}
        setChoice={(newType) => setSelectedTypePlace(newType as TypePlace)}
        typeModal="modal"
        type="sharvy"
      />
    ))

  const setAllWeekDays = () =>
    setSelectedWeekDays(
      WEEKDAYS.filter(
        (day) =>
          !!parkingInfo &&
          !!parkingInfo.workingDays &&
          !!parkingInfo.workingDays.find((workingDay) => workingDay.toUpperCase() === day)
      )
    )

  const toggleWeekDay = (day: WeekDay) =>
    setSelectedWeekDays((days) =>
      days.includes(day)
        ? days.length === 1
          ? days
          : days.filter((d) => d !== day).sort((d1, d2) => WEEK_DAY_SORTER[d1] - WEEK_DAY_SORTER[d2])
        : [...days, day].sort((d1, d2) => WEEK_DAY_SORTER[d1] - WEEK_DAY_SORTER[d2])
    )

  React.useEffect(() => {
    fetchSharvyInfos()
    analytics.track({ screenName: analyticsKeys.parkingSharvy, userType: user?.type, currentSite: site, mySites })
  }, [])

  React.useEffect(() => {
    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [])

  const handleResize = () => {
    setWindowDimensions(getWindowDimensions())
  }

  const fetchSharvyInfos = () => {
    if (!!user && !!site) {
      setScreenStatus('loading')
      api.sharvy
        .getUserInfos(user.type, site.id)
        .then((res) => {
          setScreenStatus('ok')
          const filteredSharvyBookings = !!res.bookings ? filterSharvyBookings(res.bookings) : []
          setSharvyBookings(filteredSharvyBookings)
          setParkingInfo(res.parkingInfo)
          setParkingAccess(res.hasParkingAccess || false)
        })
        .catch((err) => {
          if (err.code === 404) {
            setParkingAccess(false)
            setScreenStatus('ok')
          } else {
            setScreenStatus('error')
          }
        })
    }
  }

  const onDeleteBooking = (bookingsToDelete: SharvyParkingBooking[]) => {
    if (!!user && !!site) {
      const body: SharvyDeleteBookingsForm = {
        bookings: bookingsToDelete.map((b) => ({
          isMorning: b.isMorning,
          reservationDate: b.reservationDate,
        })),
      }
      Alert.open(
        () => (
          <ValidationContent
            title={i18n.t('screens.parking.confirmDeleteBooking')}
            onConfirm={() => {
              Alert.close()
              setScreenStatus('loading')
              api.sharvy
                .deleteSharvyBookings(user.type, site.id, body)
                .then(() => {
                  Alert.open(
                    () => (
                      <ValidationContent
                        title={i18n.t('screens.parking.successDeleteBooking')}
                        onConfirm={Alert.close}
                      />
                    ),
                    true
                  )
                  fetchSharvyInfos()
                })
                .catch(() => {
                  setScreenStatus('ok')
                  Alert.open(
                    () => <ValidationContent title={i18n.t('screens.planning.delete.error')} onConfirm={Alert.close} />,
                    true
                  )
                })
            }}
            ariaLabelConfirm={i18n.t('label.ariaLabel.windowCrossIcon')}
            cancelButton
            onCancel={Alert.close}
          />
        ),
        true
      )
    }
  }

  const renderRecurrence = () => (
    <RowContainer columnInSmallScreens>
      <BookingFormRecurrence isMobile={isMobile}>
        <RowContainer spaceBetween>
          <Label>{i18n.t('screens.planning.modal.recurrence')}</Label>
          <IconContainer>
            <SwitchFilter value={isRecurrenceActive} setValue={setIsRecurrenceActive} />
          </IconContainer>
        </RowContainer>
        {isRecurrenceActive && <RowContainer>{renderRecurrencePanel()}</RowContainer>}
      </BookingFormRecurrence>
      <BookingFormRecurrence isMobile={isMobile}>
        {isRecurrenceActive && (
          <MobileContainer>
            <Label>{i18n.t('screens.planning.modal.to')}</Label>
            <RowContainer>
              <ChoiceContainer>
                <CliquableDiv onClick={() => setIsRecurrenceDatePickerVisible(true)}>
                  <ChoiceText>
                    {utils.capitalize(i18n.t('screens.planning.reservationDate', { date: endDateRecurrence }))}
                  </ChoiceText>
                  <IconContainer>
                    <Icons name="chevron_right" color={Theme.colors.blue} />
                  </IconContainer>
                </CliquableDiv>
                {isRecurrenceDatePickerVisible && (
                  <CustomDatePicker
                    selectedDate={endDateRecurrence}
                    onChange={setEndDateRecurrence}
                    minDate={selectedDate}
                    maxDate={MAX_DATE}
                    closePicker={() => setIsRecurrenceDatePickerVisible(false)}
                    filterDate={filterWorkingDays}
                    excludeDates={datesToAvoid}
                  />
                )}
              </ChoiceContainer>
            </RowContainer>
          </MobileContainer>
        )}
      </BookingFormRecurrence>
    </RowContainer>
  )

  const renderDaysToBook = () =>
    daysToBook.map((day) => {
      const findBookingsDay = sharvyBookings.filter((booking) =>
        isSameDay(day, getDayTimezone(booking.reservationDate || ''))
      )
      const isNotAllowedToBook = isNotAllowedToBookSharvy(findBookingsDay, selectedReservationTime)
      return (
        <BookingFormRecurrenceDays isMobile={isMobile} key={day.toISOString()}>
          <RowContainer spaceBetween>
            <Label>{utils.capitalize(i18n.t('screens.planning.reservationDate', { date: day }))}</Label>
            <IconContainer>
              <Icons
                name={isNotAllowedToBook ? 'cross-circle' : 'check-circle'}
                size={26}
                color={isNotAllowedToBook ? Theme.colors.red : Theme.colors.blue}
              />
            </IconContainer>
          </RowContainer>
        </BookingFormRecurrenceDays>
      )
    })

  const renderRecurrenceDay = (day: WeekDay) => {
    const isSelected = !!selectedWeekDays.find((d) => d === day)
    const isEnabled = !!parkingInfo?.workingDays
      ? !!parkingInfo.workingDays.find((workingDay) => workingDay.toUpperCase() === day)
      : true
    return (
      <DayChoice disabled={!isEnabled} key={day} selected={isSelected} onClick={() => toggleWeekDay(day)}>
        <DayLetter selected={isSelected}>{i18n.t(`common.weekdays.${day}`)[0]}</DayLetter>
      </DayChoice>
    )
  }

  const renderRecurrencePanel = () => (
    <DaySelectionContainer>
      {WEEKDAYS.map(renderRecurrenceDay)}
      <EveryDayContainer onClick={setAllWeekDays}>{i18n.t('screens.planning.modal.everyDay')}</EveryDayContainer>
    </DaySelectionContainer>
  )

  const renderParkingFormBooking = () => (
    <>
      <MainTopContainer>
        <TopContainer>
          <Title>{i18n.t('screens.parking.bookingForm')}</Title>
          <FormContainer>
            <DateInputContainer>
              <Label>{i18n.t('screens.planning.firstForm.date')}</Label>
              <ChoiceContainer>
                <CliquableDiv
                  tabIndex={0}
                  onClick={() => setIsDatePickerVisible(true)}
                  onKeyDown={(e) => onEnterKey(e, () => setIsDatePickerVisible(true))}>
                  <ChoiceText>
                    {utils.capitalize(i18n.t('screens.planning.reservationDate', { date: selectedDate }))}
                  </ChoiceText>
                  <IconContainer>
                    <Icons name="chevron_right" color={Theme.colors.blue} />
                  </IconContainer>
                </CliquableDiv>
                {isDatePickerVisible && (
                  <CustomDatePicker
                    selectedDate={selectedDate}
                    onChange={setSelectedDate}
                    minDate={MIN_DATE}
                    maxDate={MAX_DATE}
                    closePicker={() => setIsDatePickerVisible(false)}
                    filterDate={filterWorkingDays}
                    excludeDates={datesToAvoid}
                  />
                )}
              </ChoiceContainer>
            </DateInputContainer>

            {!!parkingInfo && parkingInfo.useHalfDays && (
              <FlexFormContainer>
                <DateInputContainer>
                  <Label>{i18n.t('screens.planning.firstForm.timeSlot')}</Label>
                  <ChoiceContainer>
                    <CliquableDiv tabIndex={0} onClick={openTimeSlotSelector}>
                      <ChoiceText>{i18n.t(`screens.desk.reservationTime.${selectedReservationTime}`)}</ChoiceText>
                      <IconContainer>
                        <Icons name="chevron_right" color={Theme.colors.blue} />
                      </IconContainer>
                    </CliquableDiv>
                  </ChoiceContainer>
                </DateInputContainer>
              </FlexFormContainer>
            )}

            <FlexFormContainer>
              <DateInputContainer>
                <Label>{i18n.t('screens.planning.firstForm.typeSharvy')}</Label>
                <ChoiceContainer>
                  <CliquableDiv tabIndex={0} onClick={openTypePlaceSelector}>
                    <ChoiceText>{i18n.t(`screens.planning.sharvy.types.${selectedTypePlace}`)}</ChoiceText>
                    <IconContainer>
                      <Icons name="chevron_right" color={Theme.colors.blue} />
                    </IconContainer>
                  </CliquableDiv>
                </ChoiceContainer>
              </DateInputContainer>
            </FlexFormContainer>
          </FormContainer>
        </TopContainer>
      </MainTopContainer>

      <RecurrenceContainer>
        {renderRecurrence()}
        {isRecurrenceActive && renderDaysToBook()}
        <ButtonContainer>
          <Button
            font={Theme.fonts.h3Bold}
            label={i18n.t('screens.planning.modal.confirmReservation', {
              count: isRecurrenceActive ? filteredDaysToBook.length : 1,
            })}
            onClick={onClickBook}
            shadow
            width={350}
            loading={isReserving}
          />
        </ButtonContainer>
      </RecurrenceContainer>
    </>
  )

  const renderParkingBookingItem = (booking: SharvyParkingBooking) => {
    const statusColor = findSharvyStatusColor(booking.status, Theme)

    const findDayBookings = sharvyBookings.filter((b) =>
      isSameDay(getDayTimezone(b.reservationDate || ''), getDayTimezone(booking.reservationDate || ''))
    )
    const samePlaceBooked = isSamePlaceBookedSharvy(findDayBookings)

    if (samePlaceBooked && !booking.isMorning) {
      return null
    }

    return (
      <BookingContainer>
        <ParkingInfoContainer>
          <IconInfoContainer>
            <Icons name="agenda" size={15} color={Theme.colors.blue} />
            <ParkingInfoText>
              {stringUtils.capitalize(
                i18n.t('screens.parking.dateBooking', {
                  date: getDayTimezone(booking.reservationDate || ''),
                })
              )}
            </ParkingInfoText>
          </IconInfoContainer>
          <IconInfoContainer>
            <Icons name="clock" size={15} color={Theme.colors.blue} />
            <ParkingInfoText>
              {i18n.t(
                `screens.planning.selectionOnPlanModal.reservationTime.${
                  samePlaceBooked ? 'ALL_DAY' : booking.isMorning ? 'MORNING' : 'AFTERNOON'
                }`
              )}
            </ParkingInfoText>
          </IconInfoContainer>
          {!!booking.spot?.spotType && (
            <IconInfoContainer>
              <Icons name="car" size={15} color={Theme.colors.blue} />
              <ParkingInfoText>
                {i18n.t('screens.planning.sharvy.typeDescription', {
                  type: i18n.t(`screens.planning.sharvy.types.${booking.spot?.spotType}`),
                })}
              </ParkingInfoText>
            </IconInfoContainer>
          )}
          {!!booking.spot?.name && (
            <IconInfoContainer>
              <Icons name="parking" size={15} color={Theme.colors.blue} />
              <ParkingInfoText>{booking.spot?.name}</ParkingInfoText>
            </IconInfoContainer>
          )}

          {listPlates && (
            <PlateInfoContainer>
              <ParkingInfoText>{listPlates}</ParkingInfoText>
            </PlateInfoContainer>
          )}
          <StatusLabel
            color={statusColor}
            label={i18n.t(`screens.planning.sharvy.status.${booking.status}`)}
            marginBottom={4}
          />
        </ParkingInfoContainer>
        <CenterButtonContainer>
          <DeleteButtonContainer onClick={(evt) => evt.stopPropagation()} onKeyDown={(e) => e.stopPropagation()}>
            <Button
              label={i18n.t('common.delete')}
              onClick={() => onDeleteBooking(samePlaceBooked ? findDayBookings : [booking])}
              verticalPadding={6}
              horizontalPadding={0}
              font={Theme.fonts.label}
            />
          </DeleteButtonContainer>
        </CenterButtonContainer>
      </BookingContainer>
    )
  }

  if (screenStatus === 'loading') {
    return (
      <LoaderContainer>
        <Loader type="TailSpin" color={Theme.colors.blue} />
      </LoaderContainer>
    )
  }

  if (screenStatus === 'error' || !currentSiteHasSharvyParking || !parkingAccess) {
    return (
      <LoaderContainer>
        {!!showedBanner && <Banner banner={showedBanner} />}
        <SurveyBanner findFeature={findFeature} />
        <Title>
          {i18n.t(
            `screens.${
              !currentSiteHasSharvyParking
                ? 'parking.errorNoFeature'
                : screenStatus === 'error'
                ? 'planning.delete.error'
                : 'parking.error'
            }`
          )}
        </Title>
      </LoaderContainer>
    )
  }

  return (
    <>
      <Breadcrumb screen="parking" path="parking" navigation={navigation} />

      <MainContainer>
        <TopContainer>
          {!!showedBanner && <Banner banner={showedBanner} marginRight={20} />}
          <SurveyBanner findFeature={findFeature} />
          {renderParkingFormBooking()}
        </TopContainer>
        <BottomContainer>
          <Title>{i18n.t('screens.parking.bookings')}</Title>
          {sharvyBookings.length === 0 ? (
            <EmptyListContainer>
              <NoBookings>{i18n.t('screens.parking.noBookings')}</NoBookings>
              <EmptyImageContainer>
                <EmptyImageplaceHolder src={EmptyImagePlaceholder} />
              </EmptyImageContainer>
            </EmptyListContainer>
          ) : (
            <BookingsListContainer isMobile={isMobile}>
              {sharvyBookings.map(renderParkingBookingItem)}
            </BookingsListContainer>
          )}
        </BottomContainer>
      </MainContainer>
    </>
  )
}

export default ParkingSharvyScreen

const MainContainer = styled('div')`
  display: flex;
  flex-direction: column;
  min-height: calc(100vh - 116px);
  overflow: hidden;
  @media only screen and (max-width: ${windowSizeBreakpoints.phone}px) {
    width: 100%;
  }
`

const TopContainer = styled('div')`
  display: flex;
  flex-direction: column;
  margin: 20px 0px 0px 40px;
  @media only screen and (max-width: ${windowSizeBreakpoints.mediumBig}px) {
    margin-left: 50px;
  }
  @media only screen and (max-width: ${windowSizeBreakpoints.small}px) {
    margin: 40px 0px 0px 30px;
  }
  @media only screen and (max-width: ${windowSizeBreakpoints.phone}px) {
    margin-left: 20px;
    margin-top: 20px;
  }
`

const RecurrenceContainer = styled('div')`
  display: flex;
  flex-direction: column;
  margin: 12px 0px 0px 40px;
  @media only screen and (max-width: ${windowSizeBreakpoints.mediumBig}px) {
    margin-left: 50px;
  }
  @media only screen and (max-width: ${windowSizeBreakpoints.small}px) {
    margin: 12px 0px 0px 30px;
  }
  @media only screen and (max-width: ${windowSizeBreakpoints.phone}px) {
    margin-left: 20px;
    margin-top: 6px;
  }
`

const BottomContainer = styled('div')`
  display: flex;
  position: relative;
  flex-direction: column;
  flex: 1;
  height: 100%;
  min-height: calc(50vh - 30px);
  padding: 40px 0px 60px 80px;
  background-color: ${(props) => props.theme.colors.mediumLightGrey};
  @media only screen and (max-width: ${windowSizeBreakpoints.mediumBig}px) {
    padding: 40px 30px 60px 50px;
  }
  @media only screen and (max-width: ${windowSizeBreakpoints.small}px) {
    padding-left: 30px;
  }
  @media only screen and (max-width: ${windowSizeBreakpoints.phone}px) {
    padding: 20px;
  }
`

const BookingsListContainer = styled.div<{ isMobile: boolean }>`
  display: flex;
  flex-direction: column;
  height: 100%;
  ${(props) => !props.isMobile && 'width: 350px;'}
  padding: 10px 20px;
  background-color: ${(props) => props.theme.colors.white};
  @media only screen and (max-width: ${windowSizeBreakpoints.phone}px) {
    padding: 0px;
    flex: 1;
  }
`

const BookingContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 10px;
  padding: 6px 0px;
  margin: 10px 0px;
  min-height: 50px;
  box-shadow: 0 0 10px 0 ${(props) => props.theme.colors.shadow};
  &:hover {
    box-shadow: 0 0 20px 0 ${(props) => props.theme.colors.shadow};
  }
`

const Title = styled.p`
  ${(props) => props.theme.fonts.bodyBold}
  font-size: 24px;
`

const LoaderContainer = styled('div')`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 15%;
  margin-left: 16px;
  margin-right: 16px;
  flex-direction: column;
`

const ParkingInfoContainer = styled('div')`
  flex: 1;
  display: flex;
  flex-direction: column;
  width: 155px;
  margin: 5px 0px 7px 10px;
`

const IconInfoContainer = styled('div')`
  display: flex;
  align-items: center;
  margin-bottom: 4px;
`

const PlateInfoContainer = styled('div')`
  margin-left: 16px;
  margin-bottom: 4px;
`

const ParkingInfoText = styled('p')`
  ${(props) => props.theme.fonts.label};
  color: ${(props) => props.theme.colors.darkGrey};
  margin: 0px;
  margin-left: 5px;
`

const CenterButtonContainer = styled('div')`
  display: flex;
  align-items: center;
  margin: 0px 10px;
`

const DeleteButtonContainer = styled('div')`
  display: flex;
  width: 72px;
`

const NoBookings = styled('p')`
  ${(props) => props.theme.fonts.body};
  color: ${(props) => props.theme.colors.darkGrey};
`

const EmptyImageContainer = styled('div')`
  position: absolute;
  top: 0;
  left: calc(50% - 339px);
  display: flex;
  align-items: center;
  @media only screen and (max-width: ${windowSizeBreakpoints.phone}px) {
    left: 16px;
    right: 16px;
  }
`

const EmptyImageplaceHolder = styled(SVG)`
  object-fit: fill;
  width: 678px;
  @media only screen and (max-width: ${windowSizeBreakpoints.phone}px) {
    width: calc(100vw - 40px);
  }
`

const EmptyListContainer = styled('div')`
  display: flex;
  flex-direction: column;
  height: 524px;
`

const Label = styled.p`
  ${(props) => props.theme.fonts.label}
  margin: 0px;
  font-size: 16px;
  line-height: 19px;
`

const CliquableDiv = styled.button`
  cursor: pointer;
  display: flex;
`

const ChoiceContainer = styled.div`
  cursor: pointer;
  margin-top: 8px;
`

const ChoiceText = styled.p`
  ${(props) => props.theme.fonts.labelBold}
  color: ${(props) => props.theme.colors.blue};
  font-size: 16px;
  line-height: 19px;
  margin: 0px;
  padding-right: 5px;
`

const IconContainer = styled.div``

const ButtonContainer = styled.div`
  height: 60px;
  margin-top: 40px;
  margin-bottom: 20px;
  @media only screen and (max-width: ${windowSizeBreakpoints.phone}px) {
    margin: 20px 30px 20px 0px;
    display: flex;
    justify-content: center;
  }
`

const RowContainer = styled.div<{ spaceBetween?: boolean; columnInSmallScreens?: boolean }>`
  display: flex;
  ${(props) => props.spaceBetween && ' justify-content: space-between;'}
  @media only screen and (max-width: ${windowSizeBreakpoints.medium}px) {
    ${(props) => props.columnInSmallScreens && 'flex-direction: column'};
  }
`

const MobileContainer = styled.div<{ margin?: number }>`
  @media only screen and (max-width: ${windowSizeBreakpoints.phone}px) {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-right: ${(props) => props.margin || 0}px;
  }
`

const DayChoice = styled.button<{ selected: boolean; disabled?: boolean }>`
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
  border-radius: 15px;
  margin: 5px;
  background-color: ${(props) =>
    props.selected
      ? props.theme.colors.blue
      : props.disabled
      ? props.theme.colors.occupied
      : props.theme.colors.lightGrey};
  cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};
`

const DayLetter = styled.p<{ selected: boolean }>`
  ${(props) => props.theme.fonts.body}
  color: ${(props) => (props.selected ? props.theme.colors.white : props.theme.colors.black)};
  font-size: 16px;
  line-height: 19px;
  margin: 0px;
  text-transform: capitalize;
`

const DaySelectionContainer = styled.div`
  display: flex;
  flex: 1;
  justify-content: space-between;
  margin-top: 20px;
`

const EveryDayContainer = styled.button`
  ${(props) => props.theme.fonts.body}
  font-size: 12px;
  line-height: 14px;
  width: 50px;
  padding-top: 7px;
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
`

const BookingFormRecurrence = styled.div<{ isMobile: boolean }>`
  display: flex;
  flex-direction: column;
  height: 100%;
  margin-top: 40px;
  margin-right: 60px;
  ${(props) => !props.isMobile && 'width: 350px;'}
  @media only screen and (max-width: ${windowSizeBreakpoints.phone}px) {
    margin-top: 20px;
    margin-right: 20px;
  }
`

const BookingFormRecurrenceDays = styled.div<{ isMobile: boolean }>`
  display: flex;
  flex-direction: column;
  height: 100%;
  margin-top: 8px;
  margin-right: 60px;
  ${(props) => !props.isMobile && 'width: 350px;'}
  @media only screen and (max-width: ${windowSizeBreakpoints.phone}px) {
    margin-top: 8px;
    margin-right: 20px;
  }
`

const MainTopContainer = styled('div')`
  display: flex;
  flex-direction: row;
  @media only screen and (max-width: ${windowSizeBreakpoints.mediumBig}px) {
    flex-direction: column;
  }
`

const FormContainer = styled('div')`
  display: flex;
  margin-top: 20px;
  flex-wrap: wrap;
  gap: 30px 100px;
  @media only screen and (max-width: ${windowSizeBreakpoints.mediumBig}px) {
    flex-direction: column;
    gap: 30px;
  }
`

const DateInputContainer = styled('div')`
  display: flex;
  flex-direction: column;
`

const FlexFormContainer = styled('div')`
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
`
