import Webservice from 'utils/Webservice'
import { assureToken } from 'authent365/utils/utils'
import config from 'core/src/config'
import { addHours, roundToNearestMinutes } from 'date-fns'
import { executeGraphRequest } from 'authent365/utils/utils'
import { fetchPages } from 'utils/webserviceUtils'

const api = {
  getAllRooms: (siteId: string, userType: UserType) =>
    assureToken(userType).then((token) =>
      Webservice<AreaResearchResult>(userType, `${config.globalAPIVersion}/rooms?siteId=${siteId}`, 'GET', {
        Authorization: `Bearer ${token}`,
      })
    ),
  getRoomsByIds: (siteId: string, userType: UserType, roomIds: string[]) => {
    const idsList = roomIds.join(',')
    return assureToken(userType).then((token) =>
      Webservice<RoomResearchResult>(
        userType,
        `${config.globalAPIVersion}/rooms?roomIdsFilter=${idsList}&siteId=${siteId}`,
        'GET',
        {
          Authorization: `Bearer ${token}`,
        }
      )
    )
  },
  getRoomPlanning: (userType: UserType, roomId: number, siteId: string) =>
    assureToken(userType).then((token) =>
      Webservice<RoomWithPlanning>(
        userType,
        `${config.globalAPIVersion}/rooms/${roomId}/planning?siteId=${siteId}`,
        'GET',
        {
          Authorization: `Bearer ${token}`,
        }
      )
    ),
  searchRooms: (userType: UserType, body: RoomResearchForm, siteId: string) =>
    assureToken(userType).then((token) =>
      fetchPages<Area>(userType, token, 'areas', `${config.globalAPIVersion}/rooms?siteId=${siteId}`, 'POST', body)
    ),
  findAvailabilityForNextHour: (userType: UserType, roomEmail: string) => {
    const start = roundToNearestMinutes(new Date(), { nearestTo: 15 })
    const end = addHours(start, 1)
    const currentLocationTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone

    return executeGraphRequest<RoomScheduleGraphList>(
      userType,
      `/me/calendar/getschedule`,
      'POST',
      {
        Schedules: [roomEmail],
        StartTime: {
          dateTime: start.toISOString(),
          timeZone: currentLocationTimeZone,
        },
        EndTime: {
          dateTime: end.toISOString(),
          timeZone: currentLocationTimeZone,
        },
        availabilityViewInterval: '15',
      },
      undefined,
      currentLocationTimeZone
    )
  },
}

export default api
