import * as React from 'react'
import styled from 'theme/styled-components'
import useTheme from 'theme/useTheme'

import useI18n from 'i18n/useI18n'
import useNavigation from 'utils/navigation/useNavigation'

import useReducer from 'store/useReducer'
import * as siteStore from 'sites/store/siteStore'
import * as userStore from 'store/user/user'
import * as roomStore from './roomStore'
import * as bannersStore from 'banners/store'

import { windowSizeBreakpoints } from 'utils/breakpoints'
import useBreakpoint from 'utils/useBreakpoints'
import utils from './utils'
import { sortedFloors, findLevelWording } from 'intervention/utils'
import Logger from 'utils/Logger'
import analytics, { analyticsKeys, analyticsActionKeys } from 'utils/analytics'
import dateUtils from 'utils/date'

import api from './api/roomApi'
import floorsApi from 'intervention/api'
import graphApi from './api/graphRoomApi'
import { DirectoryAttendee, RoomsOfFloor, Attendee, AllTimes } from './types'

import Tab from 'components/button/Tab'
import CustomDatePicker from 'components/picker/CustomDatePicker'
import Icons from 'components/icons/Icons'
import Modal from 'components/modal/Modal'
import ModalContent from './modal/ModalContent'
import TimePickerInput from 'components/picker/TimePickerInput'
import FavoritesTab from './FavoritesTab'
import RoomInfo from './RoomInfo'
import MyBookingsTab from './MyBookingsTab'
import ValidationContent from 'components/modal/ValidationContent'
import AttendeesModal from './modal/AttendeesModal'
import Shortcut from 'components/button/PhoneNavigationShortcut'
import Breadcrumb from 'components/breadcrumb/Breadcrumb'
import TitleHelmet from 'components/title/TitleHelmet'
import { FormValuesPicker } from 'components/form/InterventionFormItems'
import MultipleFloorSelector from './modal/MultipleFloorSelector'
import InteractiveToast from 'components/toast/InteractiveToast'
import Banner from 'components/banner/Banner'
import SurveyBanner from 'components/banner/SurveyBanner'
import Button from 'components/button/Button'

import Switch from 'react-switch'
import Loader from 'react-loader-spinner'
import FocusLock from 'react-focus-lock'
import {
  isSameDay,
  differenceInMinutes,
  differenceInHours,
  addHours,
  addMinutes,
  subHours,
  subMinutes,
  min,
  max,
  setHours,
  setMinutes,
  addDays,
} from 'date-fns'
import { format, utcToZonedTime } from 'date-fns-tz'
import { useLocation } from 'react-router-dom'

type TagName = '' | 'myBookings' | 'favorites'
type TimePickerType = 'start' | 'end' | 'duration' | ''
type Status = 'ok' | 'loading' | 'error'

interface PickerOption {
  label: string
  value: number
  order?: number
}

const CAPACITY_LIMIT = 10
const INITIAL_DURATION: TimePickerDuration = { hours: 0, minutes: 30 }
const INITIAL_CAPACITY = 1
const MAX_DAYS_IN_FUTURE = 7 * 4 // Réservation possible jusqu'à 4 semaines
const MARGIN_BOTTOM = 10

const MIN_DURATION: TimePickerDuration = { hours: 0, minutes: 15 }
const MAX_DURATION: TimePickerDuration = { hours: 8, minutes: 0 }
const MAX_END = (d: Date) => setHours(setMinutes(d, 45), 23)

interface Props {
  formTimes?: AllTimes
  openBookings?: boolean
}

const RoomBookingScreen = ({ formTimes, openBookings }: Props) => {
  const [Theme] = useTheme()
  const i18n = useI18n()
  const navigation = useNavigation()
  const location = useLocation()

  const site = useReducer(siteStore.store, (s) => s.site)
  const user = useReducer(userStore.store, (s) => s.user)
  const mySites = useReducer(siteStore.store, (s) => s.mySites)
  const banners = useReducer(bannersStore.store, (s) => s.banners)
  const badges = useReducer(userStore.store, (s) => s.badges)

  const query = new URLSearchParams(location.search)
  const openTab = query.get('openTab')
  const meetingId = query.get('meetingId')

  const now = dateUtils.roundToNextQuarter(new Date())
  const default_end = addMinutes(now, 30)

  const defaultFormTimes = {
    startDate: now,
    endDate: default_end,
    duration: INITIAL_DURATION,
    pList: [],
    meetingSubject: '',
  }

  React.useEffect(() => {
    if (!!formTimes) {
      setStartDate(formTimes.startDate)
      setEndDate(formTimes.endDate)
      setDuration(formTimes.duration)
      setSubject(formTimes.meetingSubject)
      setIsOnlineMeeting(formTimes.isOnlineMeeting)
      setParticipantsList(formTimes.pList)
    }
  }, [formTimes])

  const { startDate: initialStartDate, endDate: initialEndDate, duration: initialDuration, pList, meetingSubject } =
    formTimes || defaultFormTimes

  const [selectedTab, setSelectedTab] = React.useState<TagName>('')
  const [startDate, setStartDate] = React.useState(initialStartDate)
  const [endDate, setEndDate] = React.useState(initialEndDate)
  const maxDuration = React.useMemo(() => {
    const diffInMinutes = differenceInMinutes(MAX_END(startDate), startDate)
    const isMaxDurationHigherThanTimeLeft = MAX_DURATION.hours * 60 + MAX_DURATION.minutes > diffInMinutes
    return isMaxDurationHigherThanTimeLeft
      ? { hours: Math.floor(diffInMinutes / 60), minutes: diffInMinutes % 60 }
      : MAX_DURATION
  }, [startDate])

  const [duration, setDuration] = React.useState<TimePickerDuration>(initialDuration)
  const [capacity, setCapacity] = React.useState(INITIAL_CAPACITY)
  const [participantsList, setParticipantsList] = React.useState<DirectoryAttendee[]>(pList)
  const [subject, setSubject] = React.useState(meetingSubject)
  const [isOnlineMeeting, setIsOnlineMeeting] = React.useState(true)
  const [onlySkyroom, setOnlySkyroom] = React.useState(false)
  const [onlyNotRestricted, setonlyNotRestricted] = React.useState(false)
  const [floorsSelected, setFloorsSelected] = React.useState<PickerOption[]>([])
  const [floorsInfos, setFloorsInfos] = React.useState<APILocation[]>([])
  const [seeUnbookableRooms, setSeeUnbookableRooms] = React.useState(true)

  const [searchedRooms, setSearchedRooms] = React.useState<Area[]>([])

  const [currentTimePicker, setCurrentTimePicker] = React.useState<TimePickerType>('')
  const [status, setStatus] = React.useState<Status>('loading')
  const [allRooms, setAllRooms] = React.useState<Area[]>([])
  const [refreshToggle, setRefreshToggle] = React.useState(false)
  const [datePickerToggle, setDatePickerToggle] = React.useState(false)

  // const [meetingToUpdate, setMeetingToUpdate] = React.useState<MeetingGraph>()

  const [, currentBreakpoint] = useBreakpoint()
  const subjectRef = React.useRef<any>()
  const tabsRef = React.useRef<any>()

  const showedBanner = React.useMemo(() => banners.find((banner) => !!banner.feature && banner.feature === 'ROOM'), [
    banners,
  ])

  const findFeature = React.useMemo(() => site?.features.find((f) => f.type === 'ROOM'), [site])

  const filterUnbookableRoom = React.useMemo(() => site?.filterUnbookableRoom, [site])

  const hasSkyroom = !!allRooms.find((r) => r.isSkyroom) && !!allRooms.find((r) => !r.isSkyroom)
  const hasRestricted = !!allRooms.find((r) => r.restricted) && !!allRooms.find((r) => !r.restricted)
  const allFloors = React.useMemo(() => {
    const floorsList = allRooms.reduce((acc, cur) => {
      const index = acc.findIndex((a) => a.value === cur.floor)
      if (index === -1) {
        acc.push({
          label: findLevelWording(cur.floor, i18n, cur.floorCustomName),
          value: cur.floor,
          order: cur.floorOrder,
        })
      }
      return acc
    }, [] as PickerOption[])
    return utils.sortedPickerFloors(floorsList)
  }, [allRooms])
  const hasMultipleFloors = allFloors.length > 1

  const isToday = isSameDay(startDate, now)

  const minEndDate = React.useMemo(() => addMinutes(startDate, MIN_DURATION.minutes), [startDate])

  const roomsByFloor = React.useMemo(
    () =>
      utils.sortedRoomsFloors(
        searchedRooms.reduce((acc, cur) => {
          const index = acc.findIndex((r) => r.floor === cur.floor)
          if (index > -1) {
            acc[index].rooms.push(cur)
          } else {
            acc.push({
              floor: cur.floor,
              rooms: [cur],
              floorCustomName: cur.floorCustomName,
              floorOrder: cur.floorOrder,
            })
          }
          return acc
        }, [] as RoomsOfFloor[])
      ),
    [searchedRooms]
  )

  const attendeesList = React.useMemo(
    () => [
      {
        type: 'required',
        emailAddress: { name: user?.firstName + ' ' + user?.lastName, address: user?.email },
      } as Attendee,
      ...participantsList.map(
        (p) =>
          ({ type: 'required', emailAddress: { name: p.givenName + ' ' + p.surname, address: p.mail } } as Attendee)
      ),
    ],
    [participantsList]
  )

  const badgesWithFloors = React.useMemo(
    () =>
      badges
        .filter((badge) => badge.siteId === site?.id && !!badge.floor)
        .sort((a, b) => {
          if (a.isPrincipal) {
            return -1
          }
          if (b.isPrincipal) {
            return 1
          }
          return 0
        }),
    [badges, site]
  )

  const hasMultipleBuildings = React.useMemo(
    () => Array.from(new Set(floorsInfos.map((f) => f.buildingId))).length > 1,
    [floorsInfos]
  )

  const defaultFloor = React.useMemo(
    () =>
      !hasMultipleBuildings && badgesWithFloors.length > 0
        ? floorsInfos.find((floor) => floor.myPortalFloorName === badgesWithFloors[0].floor)
        : undefined,
    [hasMultipleBuildings, badgesWithFloors, floorsInfos]
  )

  React.useEffect(() => {
    if (site && user) {
      api
        .getAllRooms(site.id, user.type)
        .then((res) => setAllRooms(res.areas))
        .catch(Logger.error)

      floorsApi
        .getAllFloorLocations(site.id, user.type)
        .then((res) => setFloorsInfos(sortedFloors(res.locations)))
        .catch(Logger.error)

      analytics.track({ screenName: analyticsKeys.room, userType: user.type, currentSite: site, mySites })
    }
  }, [])

  React.useEffect(() => {
    if (defaultFloor) {
      const findFloor = allFloors.find((f) => f.value === defaultFloor.level)
      const findPreviousFloor = allFloors.find((f) => f.value === defaultFloor.level - 1)
      const findNextFloor = allFloors.find((f) => f.value === defaultFloor.level + 1)
      if (!!findFloor) {
        const preSelectedFloors = [findPreviousFloor, findFloor, findNextFloor].filter(
          (f) => f !== undefined
        ) as PickerOption[]
        setFloorsSelected(preSelectedFloors)
      }
    }
  }, [defaultFloor, allFloors])

  // UseEffect pour update les différents time-picker

  React.useEffect(() => {
    if (currentTimePicker === 'start') {
      const newEndDate = min([MAX_END(startDate), addHours(addMinutes(startDate, duration.minutes), duration.hours)])
      setEndDate(newEndDate)
      const newDuration: TimePickerDuration = {
        hours: differenceInHours(newEndDate, startDate),
        minutes: differenceInMinutes(newEndDate, startDate) % 60,
      }
      setDuration(newDuration)
    }
  }, [startDate])

  React.useEffect(() => {
    if (currentTimePicker === 'end') {
      const newStartDate = min([
        max([now, startDate, subHours(subMinutes(endDate, MAX_DURATION.minutes), MAX_DURATION.hours)]),
        endDate,
      ])
      const newDuration: TimePickerDuration = {
        hours: differenceInHours(endDate, newStartDate),
        minutes: differenceInMinutes(endDate, newStartDate) % 60,
      }
      setStartDate(newStartDate)
      setDuration(newDuration)
    }
  }, [endDate])

  React.useEffect(() => {
    if (currentTimePicker === 'duration') {
      setEndDate(addMinutes(addHours(startDate, duration.hours), duration.minutes))
    }
  }, [duration])

  React.useEffect(() => {
    if (openBookings || openTab === 'myBookings') {
      setTimeout(() => setSelectedTab('myBookings'), 500)
    }
  }, [openTab])

  React.useEffect(() => {
    if (!!user && !!meetingId) {
      graphApi.getBooking(user.type, meetingId).then((res) => {
        // setMeetingToUpdate(res)
        setStartDate(new Date(res.start.dateTime))
        setEndDate(new Date(res.end.dateTime))
        setIsOnlineMeeting(!!res.isOnlineMeeting)
        setSubject(res.subject)
        setParticipantsList(
          res.attendees
            .filter((a) => a.status?.response !== 'declined')
            .map((a) => ({
              ...a,
              surname: a.emailAddress.name,
              givenName: '',
              mail: a.emailAddress.address,
              type: a.type as 'ENGIE' | 'EXTERNAL' | undefined,
            }))
        )
      })
    }
  }, [user?.type, meetingId])

  React.useEffect(() => {
    const timer = setTimeout(() => searchRooms(), 200)
    return () => clearTimeout(timer)
  }, [startDate, endDate, capacity, onlySkyroom, onlyNotRestricted, floorsSelected, seeUnbookableRooms])

  // fonction de recherche

  const searchRooms = () => {
    if (user && site) {
      setStatus('loading')
      api
        .searchRooms(
          user.type,
          {
            from: startDate.toISOString(),
            to: endDate.toISOString(),
            capacity: capacity,
            searchOnlyBookable: filterUnbookableRoom ? !seeUnbookableRooms : differenceInMinutes(startDate, now) >= 60,
            searchOnlyNotRestricted: onlyNotRestricted,
            searchOnlySkyroom: onlySkyroom,
            floors: floorsSelected.map((f) => f.value),
          },
          site.id
        )
        .then((res) => {
          if (!!user.distributionGroups && user.distributionGroups.length > 0) {
            const filteredRooms = res.filter(
              (area) =>
                !area.distributionLists ||
                area.distributionLists.length === 0 ||
                area.distributionLists.some((d) => user.distributionGroups!.some((group) => group === d))
            )
            setSearchedRooms(filteredRooms)
          } else {
            setSearchedRooms(res)
          }
          setStatus('ok')
        })
        .catch((err) => {
          Logger.error(err)
          setStatus('error')
        })
    }
  }

  // Les fonctions pour la réservation

  const openModal = (
    title: string,
    showCancel: boolean,
    onConfirm?: () => void,
    confirmTitle?: string,
    ariaLabelConfirm?: string,
    ariaLabelCancel?: string
  ) =>
    Modal.open(() => (
      <ValidationContent
        title={title}
        onConfirm={() => {
          Modal.close()
          if (!!onConfirm) {
            onConfirm()
          }
        }}
        cancelButton={showCancel}
        confirmButtonTitle={confirmTitle}
        font={Theme.fonts.label}
        ariaLabelConfirm={ariaLabelConfirm}
        ariaLabelCancel={ariaLabelCancel}
      />
    ))

  const confirmReservation = (
    name: string,
    o365Id: string,
    start: string,
    end: string,
    attendees: Attendee[],
    subject: string
  ) => {
    // if (!!meetingId && !!meetingToUpdate) {
    //   updateReservation(name, o365Id, meetingId, attendees)
    // } else {
    makeReservation(name, o365Id, start, end, attendees, subject)
    // }
  }

  // const updateReservation = (name: string, o365Id: string, meetingId: string, attendees: Attendee[]) => {
  //   if (!!user && !!site) {
  //     graphApi.updateAttendees(user.type, meetingId, [
  //       { type: 'required', emailAddress: { name: name, address: o365Id } },
  //       ...attendees,
  //     ])
  //   }
  // }

  const makeReservation = (
    name: string,
    o365Id: string,
    start: string,
    end: string,
    attendees: Attendee[],
    subject: string
  ) => {
    if (user && site) {
      setStatus('loading')
      // On récupère la timezone de l'utilisateur
      const currentLocationTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone
      // On récupère l'heure réelle de l'utilisateur
      const currentUserStartDateTime = utcToZonedTime(new Date(start), currentLocationTimeZone)
      const currentUserEndDateTime = utcToZonedTime(new Date(end), currentLocationTimeZone)
      // On récupère l'heure réelle du site
      const currentSiteStartDateTime = utcToZonedTime(currentUserStartDateTime, site.timezone)
      const currentSiteEndDateTime = utcToZonedTime(currentUserEndDateTime, site.timezone)
      // On formate la date
      const formattedUtcStartDateTime = format(currentSiteStartDateTime, "yyyy-MM-dd'T'HH:mm:ss.SSSSSSS", {
        timeZone: site.timezone,
      })
      const formattedUtcEndDateTime = format(currentSiteEndDateTime, "yyyy-MM-dd'T'HH:mm:ss.SSSSSSS", {
        timeZone: site.timezone,
      })
      graphApi
        .bookRoom(user.type, {
          location: { displayName: o365Id, locationEmailAddress: o365Id },
          subject: subject || i18n.t('screens.room.defaultSubject'),
          start: { dateTime: formattedUtcStartDateTime, timeZone: site.timezone },
          end: { dateTime: formattedUtcEndDateTime, timeZone: site.timezone },
          attendees: [{ type: 'required', emailAddress: { name: name, address: o365Id } }, ...attendees],
          isOnlineMeeting: isOnlineMeeting,
        })
        .then((res) => {
          InteractiveToast.close()
          searchRooms()
          setRefreshToggle((t) => !t)
          roomStore.actions.setGraphIdBooking(res.id)
          roomStore.actions.setGraphEmailBooking(o365Id)
          roomStore.actions.setFormTimes({
            duration,
            endDate,
            startDate,
            isOnlineMeeting,
            meetingSubject: subject,
            pList: participantsList,
          })
          InteractiveToast.open(
            i18n.t('screens.room.toast.waitingTitle'),
            i18n.t('screens.room.toast.waitingDescription'),
            'info-circle',
            Theme.colors.toastInfoText,
            Theme.colors.toastInfoBackground,
            currentBreakpoint === 'phone' ? 'bottom' : 'top-right',
            navigation
          )
        })
        .catch((err) => {
          setStatus('ok')
          Logger.error(err)
          openModal(
            i18n.t('screens.room.reservationFailed'),
            true,
            () => makeReservation(name, o365Id, start, end, attendees, subject),
            i18n.t('common.retry'),
            i18n.t('label.ariaLabel.booking.retryBooking'),
            i18n.t('label.ariaLabel.booking.cancelBookingAttempt')
          )
        })
    }
  }

  const openDatePicker = () => setDatePickerToggle(true)

  const makeReservationFromInfo = (name: string, o365Id: string) => {
    if (!!site) {
      confirmReservation(name, o365Id, startDate.toISOString(), endDate.toISOString(), attendeesList, subject)
    }
  }

  const onEnterKey = (e: React.KeyboardEvent, onClick: () => void) => {
    if (e.key === 'Enter' || e.key === ' ') {
      onClick()
      e.preventDefault()
    }
  }

  const openCapacityPicker = () =>
    Modal.open(() => ModalContent(i18n.t('screens.room.form.capacity'), CAPACITY_LIMIT, capacity, setCapacity))

  const openAttendeesModal = () => {
    Modal.open(() => <AttendeesModal attendees={participantsList} setAttendees={setParticipantsList} />)
  }

  const unselectTab = () => setSelectedTab('')

  // Les fonctions render

  const renderTimeInput = (
    value: TimePickerDuration,
    onchange: (val: TimePickerDuration) => void,
    timePickerType: TimePickerType,
    ariaLabel: string,
    title?: string,
    maxDuration?: TimePickerDuration, // in minutes
    minDuration?: TimePickerDuration, // in minutes
    isDuration?: boolean
  ) => {
    const openPicker = () =>
      Modal.open(() => {
        setCurrentTimePicker(timePickerType)
        return (
          <TimePickerInput
            value={value}
            onChange={onchange}
            close={Modal.close}
            title={title}
            maxDuration={maxDuration}
            minDuration={minDuration}
          />
        )
      })

    return (
      <TimeInput onClick={openPicker} aria-label={ariaLabel}>
        {isDuration
          ? utils.durationDisplay(i18n, value.hours, value.minutes)
          : i18n.t('screens.room.reservationTime', {
              hours: value.hours.toLocaleString(undefined, { minimumIntegerDigits: 2 }),
              minutes: value.minutes.toLocaleString(undefined, { minimumIntegerDigits: 2 }),
            })}
      </TimeInput>
    )
  }

  const renderFloor = (data: RoomsOfFloor) => {
    const isInLessThan15Minutes = Math.abs(differenceInMinutes(now, startDate)) < 15

    return (
      <FloorContainer key={data.floor}>
        <FloorNumberTitle>{findLevelWording(data.floor, i18n, data.floorCustomName)}</FloorNumberTitle>
        <RoomsContainer>
          {data.rooms.map((r, i, list) => (
            <RoomInfo
              key={r.uniquePlaceName}
              room={r}
              index={i}
              list={list}
              i18n={i18n}
              navigation={navigation}
              confirmReservation={makeReservationFromInfo}
              formTimes={{
                startDate,
                endDate,
                duration,
                pList: participantsList,
                meetingSubject: subject,
                isOnlineMeeting: isOnlineMeeting,
              }}
              bookingSoon={isInLessThan15Minutes}
            />
          ))}
        </RoomsContainer>
      </FloorContainer>
    )
  }

  return (
    <MainContainer>
      <TitleHelmet title={i18n.t('screens.room.title')} />
      <Breadcrumb screen="room" path="room" navigation={navigation} />

      {currentBreakpoint !== 'phone' ? (
        <TabGroupContainer selected={selectedTab !== ''} ref={tabsRef}>
          <TabContainer selected={selectedTab === 'myBookings'}>
            <Tab
              iconName="roombooking"
              onClick={() => {
                analytics.track({
                  screenName: analyticsKeys.room,
                  userType: user?.type,
                  currentSite: site,
                  mySites,
                  action: analyticsActionKeys.myBookings,
                })
                setSelectedTab('myBookings')
              }}
              tabPos="top"
              status={selectedTab === '' ? 'neutral' : selectedTab === 'myBookings' ? 'selected' : 'unselected'}
              ariaLabel={i18n.t('label.ariaLabel.room.myBookingsTab')}
            />
            <OnHoverTitleContainer>
              <OnHoverTitle>{i18n.t('screens.room.myBookings')}</OnHoverTitle>
            </OnHoverTitleContainer>
          </TabContainer>

          <TabContainer selected={selectedTab === 'favorites'}>
            <Tab
              iconName="heart-filled"
              onClick={() => {
                analytics.track({
                  screenName: analyticsKeys.room,
                  userType: user?.type,
                  currentSite: site,
                  mySites,
                  action: analyticsActionKeys.myFavorites,
                })
                setSelectedTab('favorites')
              }}
              tabPos="bottom"
              status={selectedTab === '' ? 'neutral' : selectedTab === 'favorites' ? 'selected' : 'unselected'}
              ariaLabel={i18n.t('label.ariaLabel.room.myFavoritesTag')}
            />
            <OnHoverTitleContainer>
              <OnHoverTitle>{i18n.t('screens.room.myFavorites')}</OnHoverTitle>
            </OnHoverTitleContainer>
          </TabContainer>
        </TabGroupContainer>
      ) : (
        <ShortcutsMainContainer>
          <ShortcutContainer>
            <Shortcut
              iconName="search"
              onClick={() => setSelectedTab('')}
              backgroundColor={selectedTab === '' ? Theme.colors.blue : Theme.colors.white}
              iconColor={selectedTab === '' ? Theme.colors.white : Theme.colors.darkGrey}
              shadow
            />
          </ShortcutContainer>
          <ShortcutContainer>
            <Shortcut
              iconName="heart-filled"
              onClick={() => setSelectedTab('favorites')}
              backgroundColor={selectedTab === 'favorites' ? Theme.colors.blue : Theme.colors.white}
              iconColor={selectedTab === 'favorites' ? Theme.colors.white : Theme.colors.darkGrey}
              shadow
            />
          </ShortcutContainer>
          <ShortcutContainer>
            <Shortcut
              iconName="roombooking"
              onClick={() => setSelectedTab('myBookings')}
              backgroundColor={selectedTab === 'myBookings' ? Theme.colors.blue : Theme.colors.white}
              iconColor={selectedTab === 'myBookings' ? Theme.colors.white : Theme.colors.darkGrey}
              shadow
            />
          </ShortcutContainer>
        </ShortcutsMainContainer>
      )}

      {currentBreakpoint !== 'phone' && (
        <InfoContainer
          selected={selectedTab !== ''}
          disabled={selectedTab === ''}
          autoFocus={false}
          shards={[subjectRef, tabsRef]}>
          <CrossContainer>
            <CrossIconContainer
              onClick={unselectTab}
              tabIndex={selectedTab !== '' ? 0 : -1}
              aria-label={i18n.t('label.ariaLabel.tabCrossIcon')}>
              <Icons name="cross" size={30} color={Theme.colors.blue} />
            </CrossIconContainer>
          </CrossContainer>

          {selectedTab === 'favorites' ? (
            <FavoritesTab
              searchedRooms={searchedRooms}
              confirmReservation={confirmReservation}
              form={{
                startDate,
                endDate,
                subject: subject,
              }}
              attendees={attendeesList}
              formTimes={{
                startDate,
                endDate,
                duration,
                pList: participantsList,
                meetingSubject: subject,
                isOnlineMeeting: isOnlineMeeting,
              }}
            />
          ) : (
            selectedTab === 'myBookings' && <MyBookingsTab allRooms={allRooms} refreshToggle={refreshToggle} />
          )}
        </InfoContainer>
      )}

      {(currentBreakpoint !== 'phone' || selectedTab === '') && (
        <>
          <MainTopContainer>
            <TopContainer reservationRoom={!!site?.reservationRoomEventLink}>
              {!!showedBanner && <Banner banner={showedBanner} marginBottom={20} />}
              <SurveyBanner findFeature={findFeature} />
              <SearchTitle>{i18n.t('screens.room.search')}</SearchTitle>
              <FormContainer>
                <DateInputContainer>
                  <InputCategoryTitle marginBottom={MARGIN_BOTTOM} htmlFor="RoomBookingDate">
                    {i18n.t('screens.room.form.date')}
                  </InputCategoryTitle>
                  <PointerContainer>
                    <SearchContentChevronContainer
                      id="RoomBookingDate"
                      onClick={openDatePicker}
                      aria-label={i18n.t('label.ariaLabel.room.chooseBookingDate')}>
                      <VisitDate>
                        {i18n.t(`screens.room.${isToday ? 'today' : 'reservationDate'}`, { date: startDate })}
                      </VisitDate>
                      <Icons name="chevron_right" size={15} color={Theme.colors.darkGrey} />
                    </SearchContentChevronContainer>
                    {datePickerToggle && (
                      <CustomDatePicker
                        selectedDate={startDate}
                        onChange={(newStartDate) => {
                          const diff = differenceInMinutes(endDate, startDate)
                          setStartDate(newStartDate)
                          setEndDate(addMinutes(newStartDate, diff))
                        }}
                        minDate={now}
                        maxDate={addDays(now, site?.nbDaysPlanning || MAX_DAYS_IN_FUTURE)}
                        closePicker={() => setDatePickerToggle(false)}
                      />
                    )}
                  </PointerContainer>
                </DateInputContainer>

                <FlexFormContainer>
                  <TimeInputContainer>
                    <InputCategoryTitle marginBottom={MARGIN_BOTTOM} htmlFor="RoomBookingStartTime">
                      {i18n.t('screens.room.form.start')}
                    </InputCategoryTitle>
                    <PointerContainer id="RoomBookingStartTime">
                      {renderTimeInput(
                        { hours: startDate.getHours(), minutes: startDate.getMinutes() },
                        (val) => setStartDate(setMinutes(setHours(startDate, val.hours), val.minutes)),
                        'start',
                        i18n.t('label.ariaLabel.room.chooseStartTime'),
                        i18n.t('screens.room.startingTime'),
                        { hours: 23, minutes: 30 },
                        isToday ? { hours: now.getHours(), minutes: now.getMinutes() } : { hours: 0, minutes: 0 }
                      )}
                    </PointerContainer>
                  </TimeInputContainer>

                  <TimeInputContainer>
                    <InputCategoryTitle marginBottom={MARGIN_BOTTOM} htmlFor="RoomBookingEndTime">
                      {i18n.t('screens.room.form.end')}
                    </InputCategoryTitle>
                    <PointerContainer id="RoomBookingEndTime">
                      {renderTimeInput(
                        { hours: endDate.getHours(), minutes: endDate.getMinutes() },
                        (val) => setEndDate(setMinutes(setHours(endDate, val.hours), val.minutes)),
                        'end',
                        i18n.t('label.ariaLabel.room.chooseEndTime'),
                        i18n.t('screens.room.endingTime'),
                        { hours: 23, minutes: 45 },
                        { hours: minEndDate.getHours(), minutes: minEndDate.getMinutes() }
                      )}
                    </PointerContainer>
                  </TimeInputContainer>

                  <InputCategoryContainer>
                    <InputCategoryTitle marginBottom={MARGIN_BOTTOM} htmlFor="RoomBookingDuration">
                      {i18n.t('screens.room.form.duration')}
                    </InputCategoryTitle>
                    <PointerContainer id="RoomBookingDuration">
                      {renderTimeInput(
                        duration,
                        setDuration,
                        'duration',
                        i18n.t('label.ariaLabel.room.chooseDuration'),
                        i18n.t('screens.room.chooseDuration'),
                        maxDuration,
                        MIN_DURATION,
                        true
                      )}
                    </PointerContainer>
                  </InputCategoryContainer>
                </FlexFormContainer>

                <FlexFormContainer>
                  <InputCategoryContainer>
                    <InputCategoryTitle marginBottom={MARGIN_BOTTOM} htmlFor="RoomCapacity">
                      {i18n.t('screens.room.form.capacity')}
                    </InputCategoryTitle>
                    <PointerContainer>
                      <SearchContentChevronContainer
                        id="RoomCapacity"
                        onClick={openCapacityPicker}
                        aria-label={i18n.t('label.ariaLabel.room.chooseCapacity')}>
                        <CapacityText>
                          {capacity !== CAPACITY_LIMIT
                            ? capacity
                            : i18n.t('screens.room.countPlus', { count: CAPACITY_LIMIT })}
                        </CapacityText>
                        <Icons name="chevron_right" size={15} color={Theme.colors.darkGrey} />
                      </SearchContentChevronContainer>
                    </PointerContainer>
                  </InputCategoryContainer>
                </FlexFormContainer>

                <FlexFormContainer>
                  <SubjectCategoryContainer>
                    <InputCategoryTitle marginBottom={MARGIN_BOTTOM} htmlFor="RoomBookingSubject">
                      {i18n.t('screens.room.form.subject')}
                    </InputCategoryTitle>
                    <InputField
                      id="RoomBookingSubject"
                      value={subject}
                      placeholder={i18n.t('screens.room.meetingSubject')}
                      onChange={(e) => setSubject(e.target.value)}
                      ref={subjectRef}
                      tabIndex={selectedTab !== '' ? -1 : 0}
                    />
                  </SubjectCategoryContainer>
                </FlexFormContainer>

                <FlexFormContainer>
                  <ParticipantsCategoryContainer>
                    <InputCategoryTitle marginBottom={MARGIN_BOTTOM} htmlFor="RoomBookingParticipants">
                      {i18n.t(`screens.room.form.participants${site?.id === '4' ? 'AndResources' : ''}`)}
                    </InputCategoryTitle>
                    <PointerContainer>
                      <SearchContentChevronContainer
                        id="RoomBookingParticipants"
                        onClick={openAttendeesModal}
                        aria-label={i18n.t(
                          `label.ariaLabel.room.modifyParticipants${site?.id === '4' ? 'AndResource' : ''}List`
                        )}>
                        <CapacityText>{participantsList.length}</CapacityText>
                        <Icons name="chevron_right" size={15} color={Theme.colors.darkGrey} />
                      </SearchContentChevronContainer>
                    </PointerContainer>
                  </ParticipantsCategoryContainer>
                </FlexFormContainer>

                <FlexFormContainer>
                  <OnlineMeetingSwitchContainer>
                    <InputCategoryTitle marginBottom={MARGIN_BOTTOM} htmlFor="RoomBookingTeams">
                      {i18n.t('screens.room.form.teams')}
                    </InputCategoryTitle>
                    <SwitchContainer
                      id="RoomBookingTeams"
                      tabIndex={0}
                      onKeyDown={(e) => onEnterKey(e, () => setIsOnlineMeeting((b) => !b))}
                      aria-label={i18n.t('label.ariaLabel.desk.toggleRecurrence')}>
                      <Switch
                        checkedIcon={false}
                        uncheckedIcon={false}
                        handleDiameter={22}
                        onColor={Theme.colors.blue}
                        offColor={Theme.colors.middleGrey}
                        checked={isOnlineMeeting}
                        onChange={() => setIsOnlineMeeting((b) => !b)}
                        height={30}
                        width={60}
                        tabIndex={-1}
                        aria-checked={undefined}
                      />
                    </SwitchContainer>
                  </OnlineMeetingSwitchContainer>
                </FlexFormContainer>

                {hasSkyroom && (
                  <FlexFormContainer>
                    <SkyRoomSwitchContainer>
                      <InputCategoryTitle marginBottom={MARGIN_BOTTOM} htmlFor="RoomBookingSkyroom">
                        {i18n.t('screens.room.form.onlySkyroom')}
                      </InputCategoryTitle>
                      <SwitchContainer
                        tabIndex={0}
                        onKeyDown={(e) => onEnterKey(e, () => setOnlySkyroom((b) => !b))}
                        aria-label={i18n.t('label.ariaLabel.room.toggleSkyroom')}
                        id="RoomBookingSkyroom">
                        <Switch
                          checkedIcon={false}
                          uncheckedIcon={false}
                          handleDiameter={22}
                          onColor={Theme.colors.blue}
                          offColor={Theme.colors.middleGrey}
                          checked={onlySkyroom}
                          onChange={() => setOnlySkyroom((b) => !b)}
                          height={30}
                          width={60}
                          tabIndex={-1}
                          aria-checked={undefined}
                        />
                      </SwitchContainer>
                    </SkyRoomSwitchContainer>
                  </FlexFormContainer>
                )}
                {hasRestricted && (
                  <FlexFormContainer>
                    <RestrictedSwitchContainer>
                      <InputCategoryTitle marginBottom={MARGIN_BOTTOM} htmlFor="RoomBookingRestricted">
                        {i18n.t('screens.room.form.onlyNotRestricted')}
                      </InputCategoryTitle>
                      <SwitchContainer
                        tabIndex={0}
                        onKeyDown={(e) => onEnterKey(e, () => setonlyNotRestricted((b) => !b))}
                        aria-label={i18n.t('label.ariaLabel.room.toggleRestricted')}
                        id="RoomBookingRestricted">
                        <Switch
                          checkedIcon={false}
                          uncheckedIcon={false}
                          handleDiameter={22}
                          onColor={Theme.colors.blue}
                          offColor={Theme.colors.middleGrey}
                          checked={onlyNotRestricted}
                          onChange={() => setonlyNotRestricted((b) => !b)}
                          height={30}
                          width={60}
                          tabIndex={-1}
                          aria-checked={undefined}
                        />
                      </SwitchContainer>
                    </RestrictedSwitchContainer>
                  </FlexFormContainer>
                )}
                {hasMultipleFloors && (
                  <FlexFormContainer>
                    <FloorsPickerContainer>
                      <FormValuesPicker
                        id="RoomFloorsPicker"
                        title={i18n.t('screens.room.form.floors')}
                        placeholder={i18n.t('screens.room.form.chooseFloors')}
                        values={floorsSelected}
                        onClick={() =>
                          Modal.open(() => (
                            <MultipleFloorSelector
                              allFloors={allFloors}
                              selectedFloors={floorsSelected}
                              setSelectedFloor={setFloorsSelected}
                            />
                          ))
                        }
                        removeOption={(val) => setFloorsSelected(floorsSelected.filter((s) => s.label !== val))}
                        noMarginTop
                      />
                    </FloorsPickerContainer>
                  </FlexFormContainer>
                )}
              </FormContainer>
            </TopContainer>
            {!!site?.reservationRoomEventLink && (
              <EventButtonContainer>
                <TextEventButton length={i18n.t('common.or').length}>{i18n.t('common.or')}</TextEventButton>
                <ButtonContainer>
                  <Button
                    label={i18n.t('screens.room.bookEventRoom')}
                    onClick={() => {
                      window.open(site.reservationRoomEventLink)
                      analytics.track({
                        screenName: analyticsKeys.room,
                        userType: user?.type,
                        currentSite: site,
                        mySites,
                        action: analyticsActionKeys.eventRoomLink,
                      })
                    }}
                    font={Theme.fonts.h3Bold}
                    textColor={Theme.colors.blue}
                    color={Theme.colors.white}
                    shadow
                    icon="external"
                    iconColor={Theme.colors.blue}
                    iconSize={24}
                  />
                </ButtonContainer>
              </EventButtonContainer>
            )}
          </MainTopContainer>

          <BottomContainer>
            {status === 'loading' ? (
              <LoaderContainer>
                <Loader type="TailSpin" color={Theme.colors.blue} />
              </LoaderContainer>
            ) : status === 'ok' ? (
              <>
                <HeaderContainer>
                  <SearchRoomTitle>
                    {i18n.t(`screens.room.${searchedRooms.length > 0 ? 'availableRooms' : 'noRoomsAvailable'}`, {
                      count: searchedRooms.length,
                    })}
                  </SearchRoomTitle>
                  {filterUnbookableRoom && (
                    <>
                      <InputCategoryTitle marginLeft={20} marginRight={12} htmlFor="UnbookableRooms">
                        {i18n.t('screens.room.form.seeUnbookableRooms')}
                      </InputCategoryTitle>
                      <OnlineMeetingSwitchContainer row>
                        <SwitchContainer
                          id="UnbookableRooms"
                          tabIndex={0}
                          onKeyDown={(e) => onEnterKey(e, () => setSeeUnbookableRooms((u) => !u))}>
                          <Switch
                            checkedIcon={false}
                            uncheckedIcon={false}
                            handleDiameter={22}
                            onColor={Theme.colors.blue}
                            offColor={Theme.colors.middleGrey}
                            checked={seeUnbookableRooms}
                            onChange={() => setSeeUnbookableRooms((u) => !u)}
                            height={30}
                            width={60}
                            tabIndex={-1}
                            aria-checked={undefined}
                          />
                        </SwitchContainer>
                      </OnlineMeetingSwitchContainer>
                    </>
                  )}
                </HeaderContainer>
                <GridContainer>{roomsByFloor.map(renderFloor)}</GridContainer>
              </>
            ) : (
              <ErrorMessage>{i18n.t('screens.room.noRoomsError')}</ErrorMessage>
            )}
          </BottomContainer>
        </>
      )}

      {currentBreakpoint === 'phone' && selectedTab === 'favorites' && (
        <ResponsiveBottomContainer>
          <FavoritesTab
            searchedRooms={searchedRooms}
            confirmReservation={makeReservationFromInfo}
            form={{
              startDate,
              endDate,
              subject: subject,
            }}
            attendees={attendeesList}
            formTimes={{
              startDate,
              endDate,
              duration,
              pList: participantsList,
              meetingSubject: subject,
              isOnlineMeeting: isOnlineMeeting,
            }}
          />
        </ResponsiveBottomContainer>
      )}

      {currentBreakpoint === 'phone' && selectedTab === 'myBookings' && (
        <ResponsiveBottomContainer>
          <MyBookingsTab allRooms={allRooms} refreshToggle={refreshToggle} />
        </ResponsiveBottomContainer>
      )}
    </MainContainer>
  )
}

// CONTAINERS

const MainContainer = styled('div')`
  display: flex;
  flex-direction: column;
  min-height: calc(100vh - 116px);
  overflow: hidden;
  @media only screen and (max-width: ${windowSizeBreakpoints.phone}px) {
    margin-left: 0px;
    width: 100%;
  }
`
const MainTopContainer = styled('div')`
  display: flex;
  flex-direction: row;
  @media only screen and (max-width: ${windowSizeBreakpoints.mediumBig}px) {
    flex-direction: column;
  }
`
const EventButtonContainer = styled('div')`
  flex: 0.3;
  display: flex;
  padding: 50px 0px;
  border-left: 1px solid ${(props) => props.theme.colors.mediumDarkGrey};
  padding-right: 80px;
  justify-content: center;
  align-items: center;
  @media only screen and (max-width: ${windowSizeBreakpoints.mediumBig}px) {
    border-top: 1px solid ${(props) => props.theme.colors.mediumDarkGrey};
    border-left: 0px solid ${(props) => props.theme.colors.mediumDarkGrey};
    margin: 0px 32px;
    padding-right: 0px;
    flex-direction: column;
    flex: 1;
  }
`
const ButtonContainer = styled('div')`
  flex: 1;
  display: flex;
  @media only screen and (max-width: ${windowSizeBreakpoints.mediumBig}px) {
    margin-top: 24px;
    width: 100%;
  }
`
const TextEventButton = styled('p')<{ length: number }>`
  margin-left: -${(props) => props.length * 4}px;
  margin-right: 24px;
  background-color: ${(props) => props.theme.colors.white};
  ${(props) => props.theme.fonts.subtitle};
  color: ${(props) => props.theme.colors.middleGrey};
  padding: 8px 0px;
  @media only screen and (max-width: ${windowSizeBreakpoints.mediumBig}px) {
    margin-left: 0px;
    margin-top: -${(props) => props.length * 31}px;
    padding: 0px 8px;
  }
`
const TopContainer = styled('div')<{ reservationRoom?: boolean }>`
  display: flex;
  ${(props) => props.reservationRoom && 'flex: 0.7;'}
  flex-direction: column;
  margin: 50px 80px 0px 102px;
  @media only screen and (max-width: ${windowSizeBreakpoints.mediumBig}px) {
    margin-left: 50px;
    margin-right: 50px;
    flex: 1;
  }
  @media only screen and (max-width: ${windowSizeBreakpoints.small}px) {
    margin: 40px 30px 0px 30px;
  }
`
const BottomContainer = styled('div')`
  display: flex;
  flex-direction: column;
  flex: 1;
  height: 100%;
  padding: 40px 0px 60px 102px;
  background-color: ${(props) => props.theme.colors.mediumLightGrey};
  @media only screen and (max-width: ${windowSizeBreakpoints.mediumBig}px) {
    padding: 40px 30px 60px 50px;
  }
  @media only screen and (max-width: ${windowSizeBreakpoints.small}px) {
    padding-left: 30px;
  }
  @media only screen and (max-width: ${windowSizeBreakpoints.phone}px) {
    padding-left: 0px;
    padding-right: 0px;
    margin-right: 0px;
  }
`
const InfoContainer = styled(FocusLock)<{ selected: boolean }>`
  position: fixed;
  z-index: 2;
  right: ${(props) => (props.selected ? 0 : '-375px')};
  transition: right 0.3s;
  height: calc(100% - 60px);
  width: 375px;
  background-color: ${(props) => props.theme.colors.white};
  ${(props) => props.theme.constants.shadow.card};
  @media only screen and (max-width: ${windowSizeBreakpoints.phone}px) {
    top: 170px;
    box-shadow: none;
    height: calc(100% - 170px);
  }
`
const OnHoverTitleContainer = styled('div')`
  position: absolute;
  z-index: 30;
  right: 70px;
  top: 15px;
  display: none;
  flex-direction: row;
  min-width: 100px;
  align-items: center;
  justify-content: center;
  padding: 10px 10px;
  border-radius: 8px;
  background-color: rgba(0, 0, 0, 0.7);
  backdrop-filter: blur(3px);
`
const OnHoverTitle = styled('div')`
  ${(props) => props.theme.fonts.body};
  color: ${(props) => props.theme.colors.white};
  text-align: center;
  white-space: nowrap;
`
const TabGroupContainer = styled('div')<{ selected: boolean }>`
  display: flex;
  z-index: 3;
  flex-direction: column;
  position: fixed;
  align-items: end;
  top: 70px;
  right: ${(props) => (props.selected ? '375px' : 0)};
  transition: right 0.3s;
`
const TabContainer = styled('div')<{ selected: boolean }>`
  position: relative;
  &:hover ${OnHoverTitleContainer} {
    ${(props) => !props.selected && 'display: flex'};
  }
`
const FormContainer = styled('div')`
  display: flex;
  margin: 20px 0px;
  flex-wrap: wrap;
  gap: 30px 100px;
  @media only screen and (max-width: ${windowSizeBreakpoints.mediumBig}px) {
    flex-direction: column;
    gap: 30px;
  }
`
const InputCategoryContainer = styled('div')`
  display: flex;
  flex-direction: column;
  width: 150px;
`
const DateInputContainer = styled('div')`
  display: flex;
  flex-direction: column;
`
const TimeInputContainer = styled('div')`
  display: flex;
  flex-direction: column;
  width: 142px;
`
const SearchContentChevronContainer = styled('button')`
  display: flex;
  align-items: center;
  :focus-visible {
    outline: 2px solid ${(props) => props.theme.colors.darkBlue};
  }
`
const PointerContainer = styled('div')`
  display: flex;
`
const SubjectCategoryContainer = styled('div')`
  display: flex;
  flex-direction: column;
  width: 310px;
`
const ParticipantsCategoryContainer = styled('div')`
  display: flex;
  flex-direction: column;
  width: 180px;
`
const OnlineMeetingSwitchContainer = styled('div')<{ row?: boolean }>`
  display: flex;
  flex-direction: ${(props) => (props.row ? 'row' : 'column')};
`
const SkyRoomSwitchContainer = styled('div')`
  display: flex;
  flex-direction: column;
`
const RestrictedSwitchContainer = styled('div')`
  display: flex;
  flex-direction: column;
`
const FloorsPickerContainer = styled('div')``
const GridContainer = styled('div')`
  margin-top: 20px;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(400px, 1fr));
  gap: 16px;
  margin-right: 16px;
  @media only screen and (max-width: ${windowSizeBreakpoints.medium}px) {
    display: flex;
    flex-direction: column;
    width: 367px;
  }
  @media only screen and (max-width: ${windowSizeBreakpoints.phone}px) {
    display: flex;
    flex-direction: column;
    width: 100%;
    ${(props) => props.theme.constants.shadow.card};
  }
`
const FloorContainer = styled('div')`
  margin: 0px 10px 10px 0px;
  background-color: ${(props) => props.theme.colors.white};
  ${(props) => props.theme.constants.shadow.card};
  @media only screen and (max-width: ${windowSizeBreakpoints.phone}px) {
    margin: 0px;
    box-shadow: none;
  }
`
const RoomsContainer = styled('div')`
  display: flex;
  flex-direction: column;
  border-top: 1px solid ${(props) => props.theme.colors.mediumDarkGrey};
  padding: 6px 16px 5px 24px;
`
const CrossContainer = styled('div')`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin: 10px 14px 20px 0px;
  @media only screen and (max-width: ${windowSizeBreakpoints.phone}px) {
    display: none;
  }
`
const CrossIconContainer = styled('button')`
  :focus-visible {
    outline: 2px solid ${(props) => props.theme.colors.darkBlue};
  }
`
const LoaderContainer = styled('div')`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 15%;
`
const FlexFormContainer = styled('div')`
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
`
const ShortcutsMainContainer = styled('div')`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
`
const ShortcutContainer = styled('div')`
  margin: 0px 20px;
`
const ResponsiveBottomContainer = styled('div')`
  margin-top: 40px;
  height: calc(100vh - 180px);
`
const SwitchContainer = styled('div')`
  display: flex;
  padding: 2px;
  :focus-visible {
    outline: 2px solid ${(props) => props.theme.colors.darkBlue};
  }
`
const HeaderContainer = styled('div')`
  display: flex;
  align-items: center;
  flex-direction: row;
`

// TEXTES

const SearchTitle = styled('h1')`
  ${(props) => props.theme.fonts.bodyBold};
  font-size: 24px;
  margin: 0px;
`
const SearchRoomTitle = styled('h1')`
  ${(props) => props.theme.fonts.bodyBold};
  font-size: 24px;
  margin: 0px;
  @media only screen and (max-width: ${windowSizeBreakpoints.phone}px) {
    margin-left: 10px;
  }
`
const InputCategoryTitle = styled('label')<{ marginBottom?: number; marginLeft?: number; marginRight?: number }>`
  ${(props) => props.theme.fonts.body};
  color: ${(props) => props.theme.colors.darkGrey};
  ${(props) => !!props.marginBottom && `margin-bottom: ${props.marginBottom}px;`}
  ${(props) => !!props.marginLeft && `margin-left: ${props.marginLeft}px;`}
    ${(props) => !!props.marginRight && `margin-right: ${props.marginRight}px;`}
`
const VisitDate = styled('p')`
  text-transform: capitalize;
  ${(props) => props.theme.fonts.bodyBold};
  color: ${(props) => props.theme.colors.blue};
  margin: 0px 10px 0px 0px;
`
const TimeInput = styled('button')`
  ${(props) => props.theme.fonts.bodyBold};
  color: ${(props) => props.theme.colors.blue};
  :focus-visible {
    outline: 2px solid ${(props) => props.theme.colors.darkBlue};
  }
`
const CapacityText = styled('p')`
  ${(props) => props.theme.fonts.bodyBold};
  color: ${(props) => props.theme.colors.blue};
  margin: 0px 10px 0px 0px;
`
const InputField = styled('input')`
  width: 80%;
  border: 0;
  outline: 0;
  ${(props) => props.theme.fonts.bodyBold}
  color: ${(props) => props.theme.colors.blue};
  border-bottom: 1px solid ${(props) => props.theme.colors.darkGrey};
  background: transparent;
  padding: 0px;
  ::placeholder {
    font-weight: 300;
  }
  &:focus {
    border-bottom-color: ${(props) => props.theme.colors.blue};
  }
`
const FloorNumberTitle = styled('p')`
  ${(props) => props.theme.fonts.bodyBold};
  color: ${(props) => props.theme.colors.blue};
  margin: 8px 0px 7px 24px;
`
const ErrorMessage = styled('p')`
  ${(props) => props.theme.fonts.body};
  color: ${(props) => props.theme.colors.darkGrey};
`

export default RoomBookingScreen
