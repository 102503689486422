import React from 'react'
import styled from 'theme/styled-components'
import useTheme from 'theme/useTheme'

import Button from 'components/button/Button'
import RoomImage from 'components/image/RoomImage'

interface Props {
  Content?: () => JSX.Element
  contentLabel?: string
  buttonLabel?: string
  onClick: () => void
  referentiel?: Area
}

const IMG_SIZE = 60
const MIN_HEIGHT = 60
const MIN_WIDTH = 280

const DayPlannerItem = ({ Content, contentLabel, buttonLabel, onClick, referentiel }: Props) => {
  const [Theme] = useTheme()

  return (
    <Item withBorder={!!contentLabel} minWidth={MIN_WIDTH + (!!referentiel ? 90 : 0)}>
      {!!referentiel && (
        <RoomImage
          iconSize={IMG_SIZE / 2}
          width={IMG_SIZE}
          height={IMG_SIZE}
          src={referentiel.picture}
          roomType={referentiel.placeType}
        />
      )}

      <ItemContent>
        {!!contentLabel ? (
          <EmptyContainer>
            <EmptyItemLabel>{contentLabel}</EmptyItemLabel>
          </EmptyContainer>
        ) : (
          !!Content && <Content />
        )}

        {!!buttonLabel && (
          <ItemButtonContainer>
            <Button
              label={buttonLabel}
              onClick={onClick}
              color={Theme.colors.white}
              textColor={Theme.colors.blue}
              borderWidth={1}
              verticalPadding={6}
              horizontalPadding={12}
            />
          </ItemButtonContainer>
        )}
      </ItemContent>
    </Item>
  )
}

export default DayPlannerItem

// CONTAINERS

const Item = styled('div')<{ withBorder: boolean; minWidth: number }>`
  display: flex;
  align-items: center;
  min-height: ${MIN_HEIGHT}px;
  min-width: ${(props) => props.minWidth}px;
  gap: 7px;
  background-color: ${(props) => props.theme.colors.white};
  ${(props) =>
    props.withBorder
      ? `border: 1px solid ${props.theme.colors.mediumLightGrey}`
      : `box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1); padding: 1px`};
`

const ItemContent = styled('div')`
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
  gap: 8px;
`

const ItemButtonContainer = styled('div')`
  display: flex;
  align-items: center;
  justify-content: center;
`

const EmptyContainer = styled('div')`
  display: flex;
  flex-direction: column;
  flex: 1;
`

const EmptyItemLabel = styled('p')`
  ${(props) => props.theme.fonts.subtitle};
  margin: 0;
`
