const ENV: Env = (global as any).ENV || 'dev'

const EnvConfig = (env: Env): EnvConfig => {
  switch (env) {
    case 'dev':
      return {
        SERVER_PREFIX: '',
        API_KEY: '65aotf662UawTyHRvj5U',
        oauth2: {
          clientId: 'LKwFharbcFje4Xhf7m7VCCmtASHWDE2ckiArLzLh',
          scope: 'engie:guest',
        },
        azureEngie: {
          clientId: '9c7ff763-f911-495c-842a-f12c8ef1a239',
          authority: `https://login.microsoftonline.com/24139d14-c62c-4c47-8bdd-ce71ea1d50cf`,
          redirectUri: 'http://localhost:3000',
          serverAPI: 'api://81d6271b-b267-4397-8838-20c7ab2fd3b1/AccesServeur',
          additionalScopes: ['User.Read', 'Calendars.ReadWrite', 'User.Read.All'],
        },
        azureEquans: {
          clientId: 'c7f4af48-db3c-4879-a233-0641a3081343',
          authority: `https://login.microsoftonline.com/09ebfde1-6505-4c31-942f-18875ff0189d`,
          redirectUri: 'http://localhost:3000',
          serverAPI: 'api://1aacdb3d-6575-46e5-9bd2-d1524c6f9351/AccesServer',
          additionalScopes: ['User.Read', 'Calendars.ReadWrite', 'User.ReadBasic.All'],
        },
        ONLYMOOV_KEY: 'OM-5763313326',
        PARKING_HOST: 'test.sharvy.com',
        mixpanelProjectToken: '26ad9be2195ffd4467f824290ae2398b',
        mixpanelUrl: 'https://engie.int.cardiweb.com/mixpanel',
        external: {
          deleteAccountEmail: 'engie@cardiweb.com',
        },
        zapfloor: {
          host: 'https://open.sandbox-api.zapfloorhq.com',
          version: 'v1',
          idEngieClient: '98bcf632-6bf5-475a-af5a-1d5553006a3e',
          timeFromAllDay: '06:00',
          timeToAllDay: '18:00',
          timeFromMorning: '06:00',
          timeToMorning: '12:00',
          timeFromAfternoon: '12:00',
          timeToAfternoon: '18:00',
          prefixMeetingCenter: 'https://user-client-staging.herokuapp.com',
        },
        barclap: {
          host: 'https://api.v1.aegir.rct.barclap.com/api/v1/shared',
          tableCommuUUID: '0a097035-c876-4719-a6d8-9965241145fa',
          tableServiceUUID: '1c27ee19-5731-433b-8845-76d00e092224',
          packedLunchUUID: '44c597e0-b896-4068-86ea-801a5ba470b7',
        },

        interventionSysId: {
          type1: ['cb97916fc3850e143f0f2875e001311b', '5b97d16fc3850e143f0f2875e0013118'],
          type2: ['10cad9a3c3c50e143f0f2875e00131df'],
          type3: ['5a0a1ccec3558e545e6f3fcf0501316d'],
          type4: ['b2ba5d63c3c50e143f0f2875e0013176'],
          type5: [
            'bfba99a3c3c50e143f0f2875e00131a1',
            '43ba59a3c3c50e143f0f2875e001318a',
            '7a97516fc3850e143f0f2875e00131c2',
            '5b97916fc3850e143f0f2875e00131a6',
            '2a97152fc3850e143f0f2875e001313a',
          ],
          type6: ['d3ba59a3c3c50e143f0f2875e00131e3'],
          type7: ['9ccad9a3c3c50e143f0f2875e0013185'],
          type8: ['3fbad9a3c3c50e143f0f2875e001312c'],
          type9: ['20ca1da3c3c50e143f0f2875e0013151'],
          type10: ['2fba99a3c3c50e143f0f2875e0013148'],
        },
        fleetSysId: {
          type1: ['42d666f2c37f86583f0f2875e001316d'],
          type2: ['a447aef2c37f86583f0f2875e00131da'],
          type3: ['2e082eb6c37f86583f0f2875e0013111'],
          type4: ['ab382ab6c37f86583f0f2875e00131b2'],
          type5: ['c478eaf6c37f86583f0f2875e001313c'],
          type6: ['d9a8aa3ac37f86583f0f2875e00131ba'],
          type7: ['f3c86a7ac37f86583f0f2875e00131c6'],
          type8: ['a6676636c37f86583f0f2875e0013166'],
          type9: ['e797ee36c37f86583f0f2875e00131af'],
          type10: ['23c72e76c37f86583f0f2875e0013149'],
        },
      }
    case 'int':
      return {
        SERVER_PREFIX: '',
        API_KEY: '65aotf662UawTyHRvj5U',
        oauth2: {
          clientId: 'LKwFharbcFje4Xhf7m7VCCmtASHWDE2ckiArLzLh',
          scope: 'engie:guest',
        },
        azureEngie: {
          clientId: '9c7ff763-f911-495c-842a-f12c8ef1a239',
          authority: `https://login.microsoftonline.com/24139d14-c62c-4c47-8bdd-ce71ea1d50cf`,
          redirectUri: 'https://engie.int.cardiweb.com/',
          serverAPI: 'api://81d6271b-b267-4397-8838-20c7ab2fd3b1/AccesServeur',
          additionalScopes: ['User.Read', 'Calendars.ReadWrite', 'User.Read.All'],
        },
        azureEquans: {
          clientId: 'c7f4af48-db3c-4879-a233-0641a3081343',
          authority: `https://login.microsoftonline.com/09ebfde1-6505-4c31-942f-18875ff0189d`,
          redirectUri: 'https://engie.int.cardiweb.com/',
          serverAPI: 'api://1aacdb3d-6575-46e5-9bd2-d1524c6f9351/AccesServer',
          additionalScopes: ['User.Read', 'Calendars.ReadWrite', 'User.ReadBasic.All'],
        },
        ONLYMOOV_KEY: 'OM-5763313326',
        PARKING_HOST: 'test.sharvy.com',
        mixpanelProjectToken: '26ad9be2195ffd4467f824290ae2398b',
        mixpanelUrl: 'https://engie.int.cardiweb.com/mixpanel',
        external: {
          deleteAccountEmail: 'engie@cardiweb.com',
        },
        zapfloor: {
          host: 'https://open.sandbox-api.zapfloorhq.com',
          version: 'v1',
          idEngieClient: '98bcf632-6bf5-475a-af5a-1d5553006a3e',
          timeFromAllDay: '06:00',
          timeToAllDay: '18:00',
          timeFromMorning: '06:00',
          timeToMorning: '12:00',
          timeFromAfternoon: '12:00',
          timeToAfternoon: '18:00',
          prefixMeetingCenter: 'https://user-client-staging.herokuapp.com',
        },
        barclap: {
          host: 'https://api.v1.aegir.rct.barclap.com/api/v1/shared',
          tableCommuUUID: '0a097035-c876-4719-a6d8-9965241145fa',
          tableServiceUUID: '1c27ee19-5731-433b-8845-76d00e092224',
          packedLunchUUID: '44c597e0-b896-4068-86ea-801a5ba470b7',
        },

        interventionSysId: {
          type1: ['cb97916fc3850e143f0f2875e001311b', '5b97d16fc3850e143f0f2875e0013118'],
          type2: ['10cad9a3c3c50e143f0f2875e00131df'],
          type3: ['5a0a1ccec3558e545e6f3fcf0501316d'],
          type4: ['b2ba5d63c3c50e143f0f2875e0013176'],
          type5: [
            'bfba99a3c3c50e143f0f2875e00131a1',
            '43ba59a3c3c50e143f0f2875e001318a',
            '7a97516fc3850e143f0f2875e00131c2',
            '5b97916fc3850e143f0f2875e00131a6',
            '2a97152fc3850e143f0f2875e001313a',
          ],
          type6: ['d3ba59a3c3c50e143f0f2875e00131e3'],
          type7: ['9ccad9a3c3c50e143f0f2875e0013185'],
          type8: ['3fbad9a3c3c50e143f0f2875e001312c'],
          type9: ['20ca1da3c3c50e143f0f2875e0013151'],
          type10: ['2fba99a3c3c50e143f0f2875e0013148'],
        },
        fleetSysId: {
          type1: ['42d666f2c37f86583f0f2875e001316d'],
          type2: ['a447aef2c37f86583f0f2875e00131da'],
          type3: ['2e082eb6c37f86583f0f2875e0013111'],
          type4: ['ab382ab6c37f86583f0f2875e00131b2'],
          type5: ['c478eaf6c37f86583f0f2875e001313c'],
          type6: ['d9a8aa3ac37f86583f0f2875e00131ba'],
          type7: ['f3c86a7ac37f86583f0f2875e00131c6'],
          type8: ['a6676636c37f86583f0f2875e0013166'],
          type9: ['e797ee36c37f86583f0f2875e00131af'],
          type10: ['23c72e76c37f86583f0f2875e0013149'],
        },
      }
    case 'preprod':
      return {
        SERVER_PREFIX: '',
        API_KEY: 'UgTRyBYuSxZi7LoMf9QYHxxTkS4pBE',
        oauth2: {
          clientId: 'uFHcMs2Xow7EmykZ3DB7UivFPfZqzU2fjcwWMuRw',
          scope: 'engie:guest',
        },
        azureEngie: {
          clientId: '9c7ff763-f911-495c-842a-f12c8ef1a239',
          authority: `https://login.microsoftonline.com/24139d14-c62c-4c47-8bdd-ce71ea1d50cf`,
          redirectUri: 'https://preprod.myportalcampus.engie.cardicloud.com/',
          serverAPI: 'api://81d6271b-b267-4397-8838-20c7ab2fd3b1/AccesServeur',
          additionalScopes: ['User.Read', 'Calendars.ReadWrite', 'User.Read.All'],
        },
        azureEquans: {
          clientId: 'c7f4af48-db3c-4879-a233-0641a3081343',
          authority: `https://login.microsoftonline.com/09ebfde1-6505-4c31-942f-18875ff0189d`,
          redirectUri: 'https://preprod.myportalcampus.engie.cardicloud.com/',
          serverAPI: 'api://1aacdb3d-6575-46e5-9bd2-d1524c6f9351/AccesServer',
          additionalScopes: ['User.Read', 'Calendars.ReadWrite', 'User.ReadBasic.All'],
        },
        ONLYMOOV_KEY: 'OM-1126981166',
        PARKING_HOST: 'test.sharvy.com',
        mixpanelProjectToken: '2315f54f8531ff3fccb974aeb43e6b7a',
        mixpanelUrl: 'https://preprod.myportalcampus.engie.cardicloud.com/mixpanel',
        external: {
          deleteAccountEmail: 'contact-livingat@engie.com',
        },
        zapfloor: {
          host: 'https://open.sandbox-api.zapfloorhq.com',
          version: 'v1',
          idEngieClient: '98bcf632-6bf5-475a-af5a-1d5553006a3e',
          timeFromAllDay: '06:00',
          timeToAllDay: '18:00',
          timeFromMorning: '06:00',
          timeToMorning: '12:00',
          timeFromAfternoon: '12:00',
          timeToAfternoon: '18:00',
          prefixMeetingCenter: 'https://user-client-staging.herokuapp.com',
        },
        barclap: {
          host: 'https://api.v1.aegir.rct.barclap.com/api/v1/shared',
          tableCommuUUID: '0a097035-c876-4719-a6d8-9965241145fa',
          tableServiceUUID: '1c27ee19-5731-433b-8845-76d00e092224',
          packedLunchUUID: '44c597e0-b896-4068-86ea-801a5ba470b7',
        },
        interventionSysId: {
          type1: ['cb97916fc3850e143f0f2875e001311b', '5b97d16fc3850e143f0f2875e0013118'],
          type2: ['10cad9a3c3c50e143f0f2875e00131df'],
          type3: ['5a0a1ccec3558e545e6f3fcf0501316d'],
          type4: ['b2ba5d63c3c50e143f0f2875e0013176'],
          type5: [
            'bfba99a3c3c50e143f0f2875e00131a1',
            '43ba59a3c3c50e143f0f2875e001318a',
            '7a97516fc3850e143f0f2875e00131c2',
            '5b97916fc3850e143f0f2875e00131a6',
            '2a97152fc3850e143f0f2875e001313a',
          ],
          type6: ['d3ba59a3c3c50e143f0f2875e00131e3'],
          type7: ['9ccad9a3c3c50e143f0f2875e0013185'],
          type8: ['3fbad9a3c3c50e143f0f2875e001312c'],
          type9: ['20ca1da3c3c50e143f0f2875e0013151'],
          type10: ['2fba99a3c3c50e143f0f2875e0013148'],
        },
        fleetSysId: {
          type1: ['42d666f2c37f86583f0f2875e001316d'],
          type2: ['a447aef2c37f86583f0f2875e00131da'],
          type3: ['2e082eb6c37f86583f0f2875e0013111'],
          type4: ['ab382ab6c37f86583f0f2875e00131b2'],
          type5: ['c478eaf6c37f86583f0f2875e001313c'],
          type6: ['d9a8aa3ac37f86583f0f2875e00131ba'],
          type7: ['f3c86a7ac37f86583f0f2875e00131c6'],
          type8: ['a6676636c37f86583f0f2875e0013166'],
          type9: ['e797ee36c37f86583f0f2875e00131af'],
          type10: ['23c72e76c37f86583f0f2875e0013149'],
        },
      }
    case 'prod':
      return {
        SERVER_PREFIX: '',
        API_KEY: 'ANmNda8sVsQcqHV4XeR2YJMWhKi5n4',
        oauth2: {
          clientId: 'r5Z9CEDV5yL7t3b2B8ynoYcCTjCYXR8pHMkmisUz',
          scope: 'engie:guest',
        },
        azureEngie: {
          clientId: '9c7ff763-f911-495c-842a-f12c8ef1a239',
          authority: `https://login.microsoftonline.com/24139d14-c62c-4c47-8bdd-ce71ea1d50cf`,
          redirectUri: 'https://engiegbs.livingat.bymyportal.com/',
          serverAPI: 'api://81d6271b-b267-4397-8838-20c7ab2fd3b1/AccesServeur',
          additionalScopes: ['User.Read', 'Calendars.ReadWrite', 'User.Read.All'],
        },
        azureEquans: {
          clientId: 'c7f4af48-db3c-4879-a233-0641a3081343',
          authority: `https://login.microsoftonline.com/09ebfde1-6505-4c31-942f-18875ff0189d`,
          redirectUri: 'https://engiegbs.livingat.bymyportal.com/',
          serverAPI: 'api://1aacdb3d-6575-46e5-9bd2-d1524c6f9351/AccesServer',
          additionalScopes: ['User.Read', 'Calendars.ReadWrite', 'User.ReadBasic.All'],
        },
        ONLYMOOV_KEY: 'OM-9849309646',
        PARKING_HOST: 'app.sharvy.com',
        mixpanelProjectToken: '8503d493698553b12d45c2c22c54ef57',
        mixpanelUrl: 'https://engiegbs.livingat.bymyportal.com/mixpanel',
        external: {
          deleteAccountEmail: 'contact-livingat@engie.com',
        },
        zapfloor: {
          host: 'https://open-api.zapfloorhq.com',
          version: 'v1',
          timeFromAllDay: '06:00',
          timeToAllDay: '18:00',
          timeFromMorning: '06:00',
          timeToMorning: '12:00',
          timeFromAfternoon: '12:00',
          timeToAfternoon: '18:00',
          idEngieClient: '12c859c2-8c04-433e-a191-a418bfb47797',
          prefixMeetingCenter: 'https://members.newdesk.engie.com',
        },
        barclap: {
          host: 'https://api.v1.aegir.prd.barclap.com/api/v1/shared',
          tableCommuUUID: '0a097035-c876-4719-a6d8-9965241145fa',
          tableServiceUUID: '1c27ee19-5731-433b-8845-76d00e092224',
          packedLunchUUID: '44c597e0-b896-4068-86ea-801a5ba470b7',
        },
        interventionSysId: {
          type1: ['cb97916fc3850e143f0f2875e001311b', '5b97d16fc3850e143f0f2875e0013118'],
          type2: ['10cad9a3c3c50e143f0f2875e00131df'],
          type3: ['5a0a1ccec3558e545e6f3fcf0501316d'],
          type4: ['b2ba5d63c3c50e143f0f2875e0013176'],
          type5: [
            'bfba99a3c3c50e143f0f2875e00131a1',
            '43ba59a3c3c50e143f0f2875e001318a',
            '7a97516fc3850e143f0f2875e00131c2',
            '5b97916fc3850e143f0f2875e00131a6',
            '2a97152fc3850e143f0f2875e001313a',
          ],
          type6: ['d3ba59a3c3c50e143f0f2875e00131e3'],
          type7: ['9ccad9a3c3c50e143f0f2875e0013185'],
          type8: ['3fbad9a3c3c50e143f0f2875e001312c'],
          type9: ['20ca1da3c3c50e143f0f2875e0013151'],
          type10: ['2fba99a3c3c50e143f0f2875e0013148'],
        },
        fleetSysId: {
          type1: ['42d666f2c37f86583f0f2875e001316d'],
          type2: ['a447aef2c37f86583f0f2875e00131da'],
          type3: ['2e082eb6c37f86583f0f2875e0013111'],
          type4: ['ab382ab6c37f86583f0f2875e00131b2'],
          type5: ['c478eaf6c37f86583f0f2875e001313c'],
          type6: ['d9a8aa3ac37f86583f0f2875e00131ba'],
          type7: ['f3c86a7ac37f86583f0f2875e00131c6'],
          type8: ['a6676636c37f86583f0f2875e0013166'],
          type9: ['e797ee36c37f86583f0f2875e00131af'],
          type10: ['23c72e76c37f86583f0f2875e0013149'],
        },
      }
  }
}

const CommonConfig: CommonConfig = {
  globalAPIVersion: '/services/v6',
  GRAPH_PREFIX: 'https://graph.microsoft.com/v1.0',
  appVersion: process.env.REACT_APP_VERSION || 'dev',
  registrationDefaultTime: {
    hours: 9,
    minutes: 0,
  },
  departureDefaultTime: {
    hours: 18,
    minutes: 0,
  },
  registrationMaxTime: {
    hours: 20,
    minutes: 0,
  },
  registrationMinTime: {
    hours: 7,
    minutes: 0,
  },
}

const config: Config = {
  ENV,
  ...CommonConfig,
  ...EnvConfig(ENV),
}

const load = (env: Env) => {
  Object.assign(config, { ENV: env })
  Object.assign(config, EnvConfig(env))
}

export default config
export { load }
