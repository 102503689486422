import * as React from 'react'

import styled from 'theme/styled-components'
import useTheme from 'theme/useTheme'

import useI18n from 'i18n/useI18n'

import Icon from 'components/icons/Icons'
import Button from 'components/button/Button'

interface Props {
  balance: string
  onReload: () => void
  closeDrawer: () => void
}

const LowBalance = ({ balance, closeDrawer, onReload }: Props) => {
  const [theme] = useTheme()
  const i18n = useI18n()
  return (
    <LowBalanceContainer>
      <LowBalanceInfosContainer>
        <Icon name="low_amount" size={40} color={theme.colors.blue} />
        <LowBalanceTitle>{i18n.t('screens.barclap.lowBalance.title')}</LowBalanceTitle>
        <LowBalanceDescription>
          {i18n.t('screens.barclap.lowBalance.description', {
            balance,
          })}
        </LowBalanceDescription>
      </LowBalanceInfosContainer>
      <LowBalanceButtonContainer>
        <Button
          label={i18n.t('screens.barclap.lowBalance.reload')}
          color={theme.colors.blue}
          textColor={theme.colors.white}
          font={theme.fonts.h3Bold}
          onClick={onReload}
        />
        <Button
          label={i18n.t('screens.barclap.lowBalance.later')}
          onClick={closeDrawer}
          color={theme.colors.white}
          textColor={theme.colors.blue}
          shadow
          font={theme.fonts.h3Bold}
        />
      </LowBalanceButtonContainer>
    </LowBalanceContainer>
  )
}

export default LowBalance

const LowBalanceContainer = styled('div')`
  padding: 40px;
`

const LowBalanceInfosContainer = styled('div')`
  justify-content: center;
`

const LowBalanceTitle = styled('p')`
  ${(props) => props.theme.fonts.h1Bold};
  margin: 0;
  padding-top: 12px;
`

const LowBalanceDescription = styled('p')`
  ${(props) => props.theme.fonts.bodyBold};
  color: ${(props) => props.theme.colors.middleGrey};
  margin: 0;
  padding-top: 12px;
`

const LowBalanceButtonContainer = styled('div')`
  display: flex;
  flex-direction: column;
  flex: 1;
  gap: 12px;
  margin-top: 28px;
`
