import { createEvent, createStore } from 'effector'

export const actions = {
  setSurveys: createEvent<Survey[]>('setSurveys'),
  setHiddenNotifSurveys: createEvent<string[]>('setHidenNotifSurveys'),
  resetSurveys: createEvent('resetSurvey'),
}

const initialState: SurveyState = {
  surveys: [],
  hiddenNotifSurveys: [],
}

export const store = createStore<SurveyState>(initialState, { name: 'Survey V2' })
  .on(actions.setSurveys, (s, surveys) => ({ ...s, surveys }))
  .on(actions.setHiddenNotifSurveys, (s, hiddenNotifSurveys) => ({ ...s, hiddenNotifSurveys }))
  .on(actions.resetSurveys, (s) => ({ ...s, survey: initialState.surveys }))
