const resizeImage = (file: File, maxSizeInMB: number): Promise<File> => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = (event) => {
      if (!event.target?.result) return reject('Erreur de lecture du fichier')

      const img = new Image()
      img.src = event.target.result as string
      img.onload = () => {
        const canvas = document.createElement('canvas')
        const ctx = canvas.getContext('2d')

        const width = img.width
        const height = img.height
        const maxFileSize = maxSizeInMB * 1024 * 1024

        // Réduction progressive de la taille
        let quality = 0.9
        canvas.width = width
        canvas.height = height
        ctx?.drawImage(img, 0, 0, width, height)

        const compressImage = () => {
          return new Promise<string>((resolve) => {
            let compressedDataURL = canvas.toDataURL('image/jpeg', quality)
            while (compressedDataURL.length * 0.75 > maxFileSize && quality > 0.1) {
              quality -= 0.1
              compressedDataURL = canvas.toDataURL('image/jpeg', quality)
            }
            resolve(compressedDataURL)
          })
        }

        compressImage().then((compressedDataURL) => {
          fetch(compressedDataURL)
            .then((res) => res.blob())
            .then((blob) => {
              resolve(new File([blob], file.name, { type: 'image/jpeg' }))
            })
        })
      }
    }
    reader.onerror = (error) => reject(error)
  })
}

export { resizeImage }
