import React from 'react'
import styled from 'theme/styled-components'

interface Props {
  label: string
  color: string
  centered?: boolean
  marginTop?: number
  marginBottom?: number
  font?: Font
}

const StatusLabel = ({ label, color, centered = true, marginTop = 0, marginBottom = 0, font }: Props) => (
  <StatusContainer centered={centered} marginTop={marginTop} marginBottom={marginBottom}>
    <StatusIndicator statusColor={color} />
    <StatusText statusColor={color} font={font}>
      {label}
    </StatusText>
  </StatusContainer>
)

export default StatusLabel

const StatusContainer = styled('div')<{ centered: boolean; marginTop: number; marginBottom: number }>`
  display: flex;
  ${(props) => props.centered && 'align-items: center'};
  margin-top: ${(props) => props.marginTop}px;
  margin-bottom: ${(props) => props.marginBottom}px;
`

const StatusText = styled('p')<{ statusColor: string; font?: Font }>`
  ${(props) => props.font || props.theme.fonts.label};
  color: ${(props) => props.statusColor};
  margin: 0;
`

const StatusIndicator = styled('div')<{ statusColor: string }>`
  width: 4px;
  height: 4px;
  background-color: ${(props) => props.statusColor};
  border-radius: 2px;
  margin: 0 5px;
`
