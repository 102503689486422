import Webservice from 'utils/Webservice'
import { assureToken } from 'authent365/utils/utils'
import config from 'core/src/config'
import { fetchPages } from 'utils/webserviceUtils'

const api = {
  getAllAreas: (siteId: string, userType: UserType) =>
    assureToken(userType).then((token) =>
      Webservice<AreaList>(userType, `${config.globalAPIVersion}/areas?siteId=${siteId}`, 'GET', {
        Authorization: `Bearer ${token}`,
      })
    ),
  getAllAreasLight: (siteId: string, userType: UserType) =>
    assureToken(userType).then((token) =>
      Webservice<AreaLightList>(userType, `${config.globalAPIVersion}/areas/light?siteId=${siteId}`, 'GET', {
        Authorization: `Bearer ${token}`,
      })
    ),
  fetchAreaReferentiel: (siteId: string, areaId: string, userType: UserType) =>
    assureToken(userType).then((token) =>
      Webservice<Area>(userType, `${config.globalAPIVersion}/areas/${areaId}?siteId=${siteId}`, 'GET', {
        Authorization: `Bearer ${token}`,
      })
    ),
  fetchAreaReferentielWithUniqueName: (siteId: string, areaUniqueName: string, userType: UserType) =>
    assureToken(userType).then((token) =>
      Webservice<RoomResearchResult>(
        userType,
        `${config.globalAPIVersion}/areas?siteId=${siteId}&uniqueName=${areaUniqueName}`,
        'GET',
        {
          Authorization: `Bearer ${token}`,
        }
      )
    ),
  getAllSearchedPlaces: (siteId: string, userType: UserType, searchBody: AreaResearchForm, buildingName?: string) =>
    assureToken(userType).then((token) =>
      fetchPages<Area>(
        userType,
        token,
        'areas',
        `${config.globalAPIVersion}/areas?siteId=${siteId}${!!buildingName ? `&buildingName=${buildingName}` : ''}`,
        'POST',
        searchBody
      )
    ),
  getAllStatus: (siteId: string, userType: UserType) =>
    assureToken(userType).then((token) =>
      Webservice<AreaStatus>(userType, `${config.globalAPIVersion}/areas/status?siteId=${siteId}`, 'GET', {
        Authorization: `Bearer ${token}`,
      })
    ),
}

export default api
