import { I18n } from 'i18n/types'
import { RoomsOfFloor } from './types'
import { transformLevel } from 'intervention/utils'
import { PickerOption } from './modal/MultipleFloorSelector'

type Status = 'FREE' | 'OCCUPIED' | 'UNKNOWN' | 'ERROR'

const utils = {
  durationDisplay: (i18n: I18n, hour: number, minutes: number) => {
    if (hour === 0) {
      return i18n.t('screens.room.reservationDurationMin', { minutes: minutes })
    } else if (minutes === 0) {
      return i18n.t('screens.room.reservationDurationHour', { hour: hour })
    } else {
      return i18n.t('screens.room.reservationDurationHourMinutes', { hour: hour, minutes: minutes })
    }
  },
  getCurrentTimeZoneDate: (i18n: I18n, date: string) => {
    const currentDate = new Date(i18n.t('screens.room.convertUTC', { date: new Date(date) }))
    return i18n.t('screens.room.fullUTCDate', { date: currentDate })
  },
  getStatusName: (status: Status, bookable: boolean, booked: boolean) =>
    !bookable && !booked ? `${status}` : `${status}_${booked ? 'BOOKED' : 'UNBOOKED'}`,
  getRoomPlaceholder: (roomType: string): IconName => {
    const type = roomType.toLowerCase()
    switch (type) {
      case 'coiffeur':
      case 'hairdresser':
        return 'barbershop'
      case 'spa':
        return 'hot'
      case 'infirmerie':
      case 'infirmary':
        return 'disinfectant'
      case 'salle de sport':
      case 'gym':
        return 'bodybuilder'
      case 'escaliers':
      case 'stairs':
        return 'stairs'
      case 'ascenseur':
      case 'elevator':
        return 'lift'
      case 'service courrier':
      case 'mail service':
        return 'backup'
      case 'bike parking':
        return 'bike-bmx'
      case 'rie':
      case 'restaurant':
        return 'restaurant'
      case 'accueil':
      case 'reception':
        return 'groom'
      case 'toilettes':
      case 'toilets':
        return 'toilet-paper'
      case 'salle de réunion':
      case 'meeting room':
        return 'roombooking'
      case 'evenement':
      case 'event space':
        return 'party'
      case 'conciergerie':
      case 'concierge service':
        return 'concierge'
      case 'repos':
      case 'break area':
        return 'hospital-bed'
      case 'it corner':
        return 'ordi'
      case 'espace café':
      case 'coffee area':
        return 'coffee-cup'
      case 'corner imprimante':
      case 'printer corner':
        return 'printer'
      case 'phone':
        return 'phone'
      case 'casiers':
      case 'lockers':
        return 'lockers-2'
      case 'fontaine':
      case 'water fountain':
        return 'water-sink'
      case 'terrasse':
      case 'terrace':
        return 'terrace'
      case 'zone bureau flex':
      case 'flex office area':
        return 'flex-office'
      case 'couloirs':
      case 'hallways':
        return 'hallway'
      case 'archives':
        return 'archives'
      case 'cuisine':
      case 'kitchen':
        return 'roast-chicken'
      case 'parking':
        return 'parking'
      case 'parking electrique':
      case 'electric parking':
        return 'plug-2'
      case 'parking pmr':
      case 'prm parking':
        return 'parking-pmr'
      case 'parking moto':
      case 'moto parking':
        return 'parking-moto'
      case 'bulle':
      case 'booth':
        return 'booth'
      case 'bureau':
      case 'desk':
        return 'desk-2'
      default:
        return 'crossroad'
    }
  },
  getRoomImagePlaceholder: (roomType: string) => {
    const type = roomType.toLowerCase()
    switch (type) {
      case 'salle de réunion':
      case 'meeting room':
        return require('../../shared/components/image/assets/placeholder.jpeg').default
      case 'bulle':
      case 'booth':
        return require('../../shared/components/image/assets/bulle.png').default
      case 'espace café':
      case 'coffee area':
        return require('../../shared/components/image/assets/cafe.png').default
      case 'casiers':
      case 'lockers':
        return require('../../shared/components/image/assets/casiers.png').default
      case 'terrasse':
      case 'terrace':
        return require('../../shared/components/image/assets/terrasse.png').default
      case 'accueil':
      case 'reception':
        return require('../../shared/components/image/assets/accueil.png').default
      default:
        return require('../../shared/components/image/assets/bureau.png').default
    }
  },
  isPlaceTypeIsImage: (roomType: string) => {
    const type = roomType.toLowerCase()
    if (
      type === 'salle de réunion' ||
      type === 'meeting room' ||
      type === 'accueil' ||
      type === 'reception' ||
      type === 'espace café' ||
      type === 'coffee area' ||
      type === 'casiers' ||
      type === 'lockers' ||
      type === 'terrasse' ||
      type === 'terrace' ||
      type === 'zone bureau flex' ||
      type === 'flex office area'
    ) {
      return true
    }
    return false
  },
  sortedRoomsFloors: (rooms: RoomsOfFloor[]) => {
    // On veut placer l'étage -999 entre le -1 et le 0
    const findFloorOrder = rooms.find((r) => !!r.floorOrder)
    if (!!findFloorOrder) {
      const transformLevelMapping: RoomsOfFloor[] = rooms.map((r) => ({
        ...r,
        level: transformLevel(r.floor),
      }))
      const sortedFloors = transformLevelMapping.sort((a, b) => {
        // Priorité à ceux qui ont un levelOrder défini
        if (
          a.floorOrder !== undefined &&
          a.floorOrder !== null &&
          (b.floorOrder === undefined || b.floorOrder === null)
        ) {
          return -1
        } else if (
          (a.floorOrder === undefined || a.floorOrder === null) &&
          b.floorOrder !== undefined &&
          b.floorOrder !== null
        ) {
          return 1
        } else if (
          a.floorOrder !== undefined &&
          a.floorOrder !== null &&
          b.floorOrder !== undefined &&
          b.floorOrder !== null
        ) {
          // Si les deux ont levelOrder défini, les trier par levelOrder
          return a.floorOrder - b.floorOrder
        } else {
          // Sinon, les trier par level
          return a.floor - b.floor
        }
      })
      return sortedFloors
    } else {
      const sortedFloors = rooms.sort((a, b) => transformLevel(a.floor) - transformLevel(b.floor))
      return sortedFloors
    }
  },
  sortedPickerFloors: (options: PickerOption[]) => {
    // On veut placer l'étage -999 entre le -1 et le 0
    const findFloorOrder = options.find((l) => !!l.order)
    if (!!findFloorOrder) {
      const transformLevelMapping: PickerOption[] = options.map((l) => ({
        ...l,
        level: transformLevel(l.value),
      }))
      const sortedFloors = transformLevelMapping.sort((a, b) => {
        // Priorité à ceux qui ont un levelOrder défini
        if (a.order !== undefined && a.order !== null && (b.order === undefined || b.order === null)) {
          return -1
        } else if ((a.order === undefined || a.order === null) && b.order !== undefined && b.order !== null) {
          return 1
        } else if (a.order !== undefined && a.order !== null && b.order !== undefined && b.order !== null) {
          // Si les deux ont levelOrder défini, les trier par levelOrder
          return a.order - b.order
        } else {
          // Sinon, les trier par level
          return a.value - b.value
        }
      })
      return sortedFloors
    } else {
      const sortedFloors = options.sort((a, b) => transformLevel(a.value) - transformLevel(b.value))
      return sortedFloors
    }
  },
  checkAvailabilityForNextHour: (reservations: ScheduleItemGraph[]) => {
    const ONE_MINUTE_IN_MILLISECONDS = 60000
    const now = new Date()
    const currentTime = now.getTime()
    // Intervalles de temps à tester en minutes
    const timeIntervals = [60, 45, 30, 15]

    // Convertit la date ISO en millisecondes
    const toMilliseconds = (dateTime: string) => new Date(dateTime).getTime()

    // Vérifie si une réservation chevauche l'intervalle de temps
    const overlaps = (reservation: ScheduleItemGraph, start: number, end: number) => {
      const startTime = toMilliseconds(reservation.start.dateTime)
      const endTime = toMilliseconds(reservation.end.dateTime)
      return (
        reservation.status === 'busy' && ((start < endTime && end > startTime) || (end > startTime && end < endTime))
      )
    }

    // Vérifie si la salle est disponible pour un intervalle de temps donné
    const checkAvailability = (minutes: number) => {
      const futureTime = currentTime + minutes * ONE_MINUTE_IN_MILLISECONDS
      return !reservations.some((reservation) => overlaps(reservation, currentTime, futureTime))
    }

    const availableInterval = timeIntervals
      .map((minutes) => ({
        minutes,
        available: checkAvailability(minutes),
      }))
      .find((interval) => interval.available)

    return availableInterval ? availableInterval.minutes : undefined
  },
}

export default utils
