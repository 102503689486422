import * as React from 'react'
import styled from 'theme/styled-components'
import useTheme from 'theme/useTheme'

import Loader from 'react-loader-spinner'
import Icons from '../icons/Icons'

interface Props {
  label: string
  onClick: () => void
  color?: string
  disabled?: boolean
  textColor?: string
  shadow?: boolean
  type?: 'reset' | 'button' | 'submit'
  font?: Font
  verticalPadding?: number
  horizontalPadding?: number
  fontSize?: number
  loading?: boolean
  ariaLabel?: string
  width?: number
  noFlex?: boolean
  icon?: IconName
  iconSize?: number
  iconColor?: string
  borderWidth?: number
}

const Button = ({
  label,
  onClick,
  color,
  disabled,
  textColor,
  shadow = false,
  font,
  verticalPadding = 15,
  horizontalPadding = 20,
  type = 'button',
  fontSize,
  loading = false,
  ariaLabel,
  width,
  noFlex,
  icon,
  iconSize = 17,
  iconColor,
  borderWidth,
}: Props) => {
  const [Theme] = useTheme()

  return (
    <Container
      onClick={!loading && !disabled ? onClick : undefined}
      color={!loading ? color || Theme.colors.blue : Theme.colors.middleGrey}
      disabled={disabled}
      shadow={shadow}
      verticalPadding={verticalPadding}
      horizontalPadding={horizontalPadding}
      type={type}
      aria-label={ariaLabel || label}
      width={width}
      noFlex={noFlex}
      borderWidth={borderWidth}
      borderColor={textColor || Theme.colors.blue}>
      {loading ? (
        <Loader type="ThreeDots" color={Theme.colors.white} height={20} width={30} />
      ) : (
        <>
          <Label
            color={textColor || Theme.colors.white}
            font={font || Theme.fonts.labelBold}
            fontSize={fontSize}
            disabled={disabled}>
            {label}
          </Label>
          {!!icon && (
            <IconContainer>
              <Icons name={icon} size={iconSize} color={iconColor} />
            </IconContainer>
          )}
        </>
      )}
    </Container>
  )
}

const Container = styled.button<{
  color?: string
  disabled?: boolean
  width?: number
  shadow: boolean
  verticalPadding: number
  horizontalPadding: number
  noFlex?: boolean
  borderWidth?: number
  borderColor?: string
}>`
  ${(props) => !props.noFlex && 'flex: 1'};
  padding: ${(props) => `${props.verticalPadding}px ${props.horizontalPadding}px`};
  align-items: center;
  justify-content: center;
  display: flex;
  border: ${(props) => props.borderWidth || 0}px solid ${(props) => props.borderColor};
  cursor: ${(props) => (props.disabled ? 'default' : 'pointer')};
  ${(props) => !!props.width && `width: ${props.width}px;`}
  ${(props) => !!props.width && `max-width: ${props.width}px;`}
  background-color: ${(props) =>
    props.disabled ? props.theme.colors.mediumDarkGrey : props.color || props.theme.colors.blue};
  ${(props) => props.shadow && 'box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.15);'}
  :focus-visible {
    outline: 2px solid ${(props) => props.theme.colors.outline};
  }
`

const Label = styled.div<{ color?: string; font: Font; fontSize?: number; disabled?: boolean }>`
  text-align: center;
  ${(props) => props.font}
  color: ${(props) => (props.disabled ? props.theme.colors.darkGrey : props.color || props.theme.colors.blue)};
  ${(props) => !!props.fontSize && `font-size: ${props.fontSize}px`};
`
const IconContainer = styled('div')`
  padding-left: 8px;
`
export default Button
