import * as React from 'react'
import styled from 'theme/styled-components'
import useTheme from 'theme/useTheme'

import useI18n from 'i18n/useI18n'
import { findLevelWording, getColorFromStatus } from 'intervention/utils'
import Logger from 'utils/Logger'
import { windowSizeBreakpoints } from 'utils/breakpoints'

import useReducer from 'store/useReducer'
import * as userStore from 'store/user/user'
import * as siteStore from 'sites/store/siteStore'

import api from 'map/mapApi'

import Icons from 'components/icons/Icons'
import Button from 'components/button/Button'
import ToastAlert from 'components/toast/ToastAlert'
import Modal from 'components/modal/Modal'
import StatusLabel from 'components/label/StatusLabel'
import ClaimForm from './ClaimForm'

interface Props {
  intervention: Incident
  refreshIncidents: () => void
  floors: APILocation[]
}

const UserRequestInfo = ({ intervention, refreshIncidents, floors }: Props) => {
  const i18n = useI18n()
  const [Theme] = useTheme()
  const user = useReducer(userStore.store, (s) => s.user)
  const site = useReducer(siteStore.store, (s) => s.site)

  const [room, setRoom] = React.useState<Area>()
  const {
    level: floor,
    category,
    date,
    description,
    incidentServices: services,
    status,
    subcategory,
    incidentNumber,
    priority,
    uniqueName,
    complaint,
    canPostAComplaint,
    buildingName,
    incidentType,
  } = intervention

  const [claim, setClaim] = React.useState(complaint)

  const hasMultipleBuildings = React.useMemo(() => Array.from(new Set(floors.map((f) => f.buildingId))).length > 1, [
    floors,
  ])

  React.useEffect(() => {
    if (site && user && uniqueName) {
      api
        .fetchAreaReferentielWithUniqueName(site.id, uniqueName, user.type)
        .then((res) => setRoom(res.areas[0]))
        .catch(Logger.error)
    }
  }, [])

  const renderInfoLine = (label: string, value: string, iconName: IconName, fade?: boolean) => (
    <InfoLineContainer>
      <IconTextContainer>
        <IconContainer>
          <Icons name={iconName} color={Theme.colors.blue} size={15} />
        </IconContainer>
        <RegularText color={Theme.colors.blue}>{label}</RegularText>
      </IconTextContainer>
      <InfoValue fade={fade}>{value}</InfoValue>
    </InfoLineContainer>
  )

  return (
    <MainContainer>
      <ListContainer>
        <TopContainer>
          <Title>{i18n.t(`screens.${incidentType === 'FLEET' ? 'fleet' : 'incident'}.title`)}</Title>
          <CrossContainer onClick={Modal.close} aria-label={i18n.t('label.ariaLabel.windowCrossIcon')}>
            <Icons name="cross" color={Theme.colors.darkGrey} size={25} />
          </CrossContainer>
        </TopContainer>
        <RequestContainer>
          <CategoryTitle>{category}</CategoryTitle>
          <RegularText>{subcategory}</RegularText>
          {services.length > 0 && (
            <IncidentServicesContainer>
              {services.map((s) => (
                <IncidentServiceContainer key={s.id}>
                  <IncidentServiceText>{s.name}</IncidentServiceText>
                </IncidentServiceContainer>
              ))}
            </IncidentServicesContainer>
          )}

          <RoomInfoContainer>
            <StatusLabel
              color={getColorFromStatus(status, Theme)}
              label={i18n.t([`screens.incident.status.${status}`, 'screens.incident.status.UNKNOWN'])}
              marginTop={6}
              marginBottom={6}
            />

            {!!room &&
              incidentType !== 'FLEET' &&
              renderInfoLine(
                i18n.t('screens.incident.details.location'),
                `${!!room.building && hasMultipleBuildings ? `${room.building} - ` : ''}${findLevelWording(
                  room.floor,
                  i18n,
                  room.floorCustomName
                )}`,
                'pin'
              )}

            {!uniqueName &&
              !!floor &&
              incidentType !== 'FLEET' &&
              renderInfoLine(
                i18n.t('screens.incident.details.location'),
                `${!!buildingName && hasMultipleBuildings ? `${buildingName} - ` : ''}${findLevelWording(floor, i18n)}`,
                'pin'
              )}

            {incidentNumber && renderInfoLine(i18n.t('screens.incident.details.number'), incidentNumber, 'ticket')}

            {date &&
              renderInfoLine(
                i18n.t('screens.incident.details.date'),
                i18n.t(`screens.incident.requestTime`, { date: new Date(date) }),
                'clock'
              )}

            {priority &&
              renderInfoLine(
                i18n.t('screens.incident.details.priority'),
                i18n.t(`screens.incident.form.requestPriorityValues.${priority}`),
                'warning'
              )}

            {renderInfoLine(i18n.t('screens.incident.details.description'), description, 'text', true)}
          </RoomInfoContainer>
        </RequestContainer>
        {claim ? (
          <>
            <LightSeparator />
            <RequestContainer>
              <CategoryTitle>{i18n.t('screens.incident.claim.title')}</CategoryTitle>

              <RoomInfoContainer>
                <StatusLabel
                  color={getColorFromStatus(claim.status, Theme)}
                  label={i18n.t([`screens.incident.status.${claim.status}`, 'screens.incident.status.UNKNOWN'])}
                  marginTop={6}
                  marginBottom={6}
                />

                {renderInfoLine(
                  i18n.t('screens.incident.details.date'),
                  i18n.t(`screens.incident.requestTime`, { date: new Date(claim.creationDate) }),
                  'clock'
                )}

                {renderInfoLine(i18n.t('screens.incident.details.description'), claim.description, 'text', true)}
              </RoomInfoContainer>
            </RequestContainer>
          </>
        ) : (
          canPostAComplaint &&
          !claim && (
            <ButtonContainer>
              <Button
                label={i18n.t('screens.incident.claim.makeAClaim')}
                onClick={() =>
                  ToastAlert.open(() => (
                    <ClaimForm
                      incident={intervention}
                      onClaimSubmitted={(claim) => {
                        setClaim(claim)
                        refreshIncidents()
                        ToastAlert.close()
                      }}
                    />
                  ))
                }
                font={Theme.fonts.h3Bold}
              />
            </ButtonContainer>
          )
        )}
      </ListContainer>
    </MainContainer>
  )
}

const MainContainer = styled('div')`
  display: flex;
  flex-direction: column;
  max-height: 80vh;
  width: 444px;
  @media only screen and (max-width: ${windowSizeBreakpoints.phone}px) {
    width: 90vw;
  }
`
const TopContainer = styled('div')`
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
  margin-bottom: 20px;
`
const CrossContainer = styled('button')`
  display: flex;
  align-items: center;
`

const ListContainer = styled('div')`
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: 40px;
  overflow-y: scroll;

  /* Style scrollbar pour Chrome, Safari and Opera */
  ::-webkit-scrollbar {
    width: 5px;
    background: white;
  }
  ::-webkit-scrollbar-thumb {
    border-radius: 5px;
    background: ${(props) => props.theme.colors.scrollBar};
  }

  /* Style scrollbar pour Firefox */
  scrollbar-width: thin;
  scrollbar-color: ${(props) => props.theme.colors.scrollBar} transparent;
  @media only screen and (max-width: ${windowSizeBreakpoints.phone}px) {
    padding: 20px;
  }
`

const Title = styled('h1')`
  display: flex;
  flex: 1;
  margin: 0px;
  ${(props) => props.theme.fonts.title};
  overflow-wrap: anywhere;
`

const RequestContainer = styled('div')`
  display: flex;
  flex: 1;
  flex-direction: column;
`

const RoomInfoContainer = styled('div')`
  display: flex;
  flex-direction: column;
  margin-top: 10px;
`
const IconTextContainer = styled('div')`
  display: flex;
  align-items: center;
  margin-bottom: 5px;
`

const CategoryTitle = styled('h1')`
  ${(props) => props.theme.fonts.h3Bold};
  margin: 0px;
`
const RegularText = styled('p')<{ color?: string }>`
  ${(props) => props.theme.fonts.label};
  margin: 0px;
  ${(props) => props.color && `color: ${props.color}`};
`

const InfoValue = styled('p')<{ fade?: boolean }>`
  ${(props) => props.theme.fonts.body};
  ${(props) => props.fade && `color: ${props.theme.colors.placeholderGrey}`};
  margin: 0px;
  white-space: pre-wrap;
`

const InfoLineContainer = styled('div')`
  display: flex;
  flex-direction: column;
  margin: 6px 0px;
`

const IncidentServicesContainer = styled('div')`
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
  margin-top: 10px;
`

const IncidentServiceContainer = styled('div')`
  background: ${(props) => props.theme.colors.mediumDarkGrey};
  border-radius: 4px;
  padding: 3px 5px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`

const IncidentServiceText = styled('label')`
  ${(props) => props.theme.fonts.label};
  color: ${(props) => props.theme.colors.darkGrey};
  margin: 0px;
  margin-right: 5px;
`

const IconContainer = styled('div')`
  width: 20px;
  align-items: center;
`

const LightSeparator = styled('div')`
  background-color: ${(props) => props.theme.colors.mediumLightGrey};
  margin: 20px 0px;
  width: 100%;
  min-height: 2px;
`

const ButtonContainer = styled('div')`
  display: flex;
  margin-top: 20px;
`

export default UserRequestInfo
