import * as React from 'react'

import styled from 'theme/styled-components'
import useTheme from 'theme/useTheme'

import useI18n from 'i18n/useI18n'

import { windowSizeBreakpoints } from 'utils/breakpoints'
import stringUtils from 'utils/strings'
import utils from 'utils/strings'
import { getClosestDate, getDatesInInterval, getWeekDaysNumber } from 'planning/utils'
import { findStatusColor, WEEK_DAY_SORTER } from './utils'
import dateUtils from 'utils/date'
import useNavigation from 'utils/navigation/useNavigation'
import analytics, { analyticsKeys } from 'utils/analytics'

import Icons from 'components/icons/Icons'
import Button from 'components/button/Button'
import EmptyImagePlaceholder from 'components/image/assets/intervention_vide.svg'
import Alert from 'components/alert/Alert'
import ValidationContent from 'components/modal/ValidationContent'
import CustomDatePicker from 'components/picker/CustomDatePicker'
import SwitchFilter from 'components/switch/SwitchFilter'
import Breadcrumb from 'components/breadcrumb/Breadcrumb'
import Banner from 'components/banner/Banner'
import SurveyBanner from 'components/banner/SurveyBanner'
import StatusLabel from 'components/label/StatusLabel'

import Loader from 'react-loader-spinner'
import SVG from 'react-inlinesvg'
import {
  compareAsc,
  isSameDay,
  isWeekend,
  addBusinessDays,
  endOfMonth,
  addMonths,
  isBefore,
  addHours,
  isToday,
  startOfDay,
  addDays,
  getDay,
  getHours,
  addMinutes,
} from 'date-fns'
import { zonedTimeToUtc, utcToZonedTime } from 'date-fns-tz'

import useReducer from 'store/useReducer'
import * as userStore from 'store/user/user'
import * as siteStore from 'sites/store/siteStore'
import * as featureStore from 'sites/store/featureStore'
import * as bannersStore from 'banners/store'

import api from './parkingApi'

type Status = 'loading' | 'ok' | 'error'

const onEnterKey = (e: React.KeyboardEvent, onClick: () => void) => {
  if (e.key === 'Enter') {
    onClick()
  }
}

const getWindowDimensions = () => {
  const { innerWidth: width, innerHeight: height } = window
  return {
    width,
    height,
  }
}

const CURRENT_DATE = new Date()
const MIN_DATE =
  CURRENT_DATE.getHours() < 14 && !isWeekend(CURRENT_DATE) ? CURRENT_DATE : addBusinessDays(CURRENT_DATE, 1)

const MAX_DATE = endOfMonth(addMonths(CURRENT_DATE, 1))
const WEEKDAYS: WeekDay[] = ['MONDAY', 'TUESDAY', 'WEDNESDAY', 'THURSDAY', 'FRIDAY']

interface Props {
  initialDatePlanning?: Date
}

const ParkingScreen = ({ initialDatePlanning = MIN_DATE }: Props) => {
  const i18n = useI18n()
  const [Theme] = useTheme()
  const navigation = useNavigation()

  const user = useReducer(userStore.store, (s) => s.user)
  const site = useReducer(siteStore.store, (s) => s.site)
  const features: Feature[] = useReducer(featureStore.store, (f) => f.features)
  const mySites = useReducer(siteStore.store, (s) => s.mySites)
  const banners = useReducer(bannersStore.store, (s) => s.banners)

  const [screenStatus, setScreenStatus] = React.useState<Status>('loading')
  const [bookingsList, setBookingsList] = React.useState<ParkingSpotRequest[]>([])
  const [parkingInfo, setParkingInfo] = React.useState<ParkingArea>()
  const [isDatePickerVisible, setIsDatePickerVisible] = React.useState(false)
  const [isRecurrenceDatePickerVisible, setIsRecurrenceDatePickerVisible] = React.useState(false)
  const [selectedDate, setSelectedDate] = React.useState(MIN_DATE)
  const [isReserving, setIsReserving] = React.useState(false)
  const [isRecurrenceActive, setIsRecurrenceActive] = React.useState(false)
  const [selectedWeekDays, setSelectedWeekDays] = React.useState<WeekDay[]>([])
  const [endDateRecurrence, setEndDateRecurrence] = React.useState<Date>(MAX_DATE)
  const [recurrenceSelectedDays, setRecurrenceSelectedDays] = React.useState<Date[]>([])
  const [alreadyBookedDate, setAlreadyBookedDate] = React.useState(false)
  const [windowDimensions, setWindowDimensions] = React.useState(getWindowDimensions())

  const showedBanner = React.useMemo(
    () => banners.find((banner) => !!banner.feature && banner.feature === 'PARKING_COMMUTY'),
    [banners]
  )

  const findFeature = React.useMemo(() => site?.features.find((f) => f.type === 'PARKING_COMMUTY'), [site])

  const hasParkingCommuty = React.useMemo(
    () =>
      !!site &&
      !!site.features.find((f) => f.type === 'PARKING_COMMUTY') &&
      !!features.find((f) => f.type === 'PARKING_COMMUTY'),
    [site, features]
  )

  const isMobile = React.useMemo(() => windowDimensions.width <= windowSizeBreakpoints.phone, [
    windowDimensions,
    windowSizeBreakpoints,
  ])

  const datesToAvoid = React.useMemo(
    () =>
      !!site && !!site.timezone
        ? bookingsList.map((booking) => utcToZonedTime(new Date(booking.startTime), site.timezone))
        : [],
    [bookingsList, site]
  )

  const initialDate = React.useMemo(() => getClosestDate(MIN_DATE, MAX_DATE, initialDatePlanning, datesToAvoid, true), [
    datesToAvoid,
  ])

  const maxSelectableDate = React.useMemo(
    () => getClosestDate(MIN_DATE, MAX_DATE, MAX_DATE, datesToAvoid, true, 14) || MAX_DATE,
    [datesToAvoid]
  )

  const minSelectableDate = React.useMemo(
    () => getClosestDate(MIN_DATE, MAX_DATE, MIN_DATE, datesToAvoid, true, 14) || MIN_DATE,
    [datesToAvoid]
  )

  const toggleWeekDay = (day: WeekDay) =>
    setSelectedWeekDays((days) =>
      days.includes(day) && days.length !== 1
        ? days.filter((d) => d !== day).sort((d1, d2) => WEEK_DAY_SORTER[d1] - WEEK_DAY_SORTER[d2])
        : [...days, day].sort((d1, d2) => WEEK_DAY_SORTER[d1] - WEEK_DAY_SORTER[d2])
    )

  const setAllWeekDays = () => setSelectedWeekDays(WEEKDAYS)

  React.useEffect(() => {
    fetch()

    analytics.track({ screenName: analyticsKeys.parking, userType: user?.type, currentSite: site, mySites })
  }, [])

  React.useEffect(() => {
    if (!!initialDate) {
      setSelectedDate(initialDate)
    }
  }, [initialDate])

  React.useEffect(() => {
    if (isBefore(endDateRecurrence, selectedDate)) {
      setEndDateRecurrence(selectedDate)
    }
    if (isBefore(maxSelectableDate, endDateRecurrence)) {
      setEndDateRecurrence(maxSelectableDate)
    }
  }, [selectedDate, endDateRecurrence, datesToAvoid])

  React.useEffect(() => {
    if (isBefore(endDateRecurrence, selectedDate)) {
      setEndDateRecurrence(selectedDate)
    } else if (isRecurrenceActive) {
      const weekDaysNumber = getWeekDaysNumber(selectedWeekDays)
      const daysToBook = getDatesInInterval(selectedDate, endDateRecurrence, weekDaysNumber)

      const alreadyBooked = !!daysToBook.find((day) =>
        bookingsList.find((booking) => isSameDay(new Date(booking.startTime), day))
      )
      setAlreadyBookedDate(alreadyBooked)

      const filteredDaysToBook = daysToBook
        .filter((day) => !bookingsList.find((booking) => isSameDay(new Date(booking.startTime), day)))
        .filter((date) => !isSameDay(date, selectedDate))
      setRecurrenceSelectedDays([selectedDate, ...filteredDaysToBook])
    } else {
      setRecurrenceSelectedDays([selectedDate])
    }
  }, [isRecurrenceActive, selectedDate, endDateRecurrence, selectedWeekDays])

  React.useEffect(() => {
    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [])

  const handleResize = () => {
    setWindowDimensions(getWindowDimensions())
  }

  const fetch = () => {
    if (!!user && !!site) {
      setScreenStatus('loading')
      Promise.all([api.getParkingInfos(site.id, user.type), api.getParkingBookings(site.id, user.type)])
        .then((res) => {
          if (res[0].areas.length === 0) {
            setScreenStatus('error')
          } else {
            setScreenStatus('ok')
            setParkingInfo(res[0].areas[0])
            setBookingsList(res[1].requests.sort((a, b) => compareAsc(new Date(a.startTime), new Date(b.startTime))))
          }
        })
        .catch(() => setScreenStatus('error'))
    }
  }

  const onDeleteBooking = (booking: ParkingSpotRequest) => {
    if (!!site && !!user) {
      Alert.open(
        () => (
          <ValidationContent
            title={i18n.t('screens.parking.confirmDeleteBooking')}
            onConfirm={() => {
              Alert.close()
              setScreenStatus('loading')
              api
                .deleteParkingBooking(site.id, user.type, booking.id)
                .then(() => {
                  Alert.open(
                    () => (
                      <ValidationContent
                        title={i18n.t('screens.parking.successDeleteBooking')}
                        onConfirm={Alert.close}
                      />
                    ),
                    true
                  )
                  fetch()
                })
                .catch(() => {
                  setScreenStatus('ok')
                  Alert.open(
                    () => <ValidationContent title={i18n.t('screens.parking.error')} onConfirm={Alert.close} />,
                    true
                  )
                })
            }}
            ariaLabelConfirm={i18n.t('label.ariaLabel.windowCrossIcon')}
            cancelButton
            onCancel={Alert.close}
          />
        ),
        true
      )
    }
  }

  const onCreateBooking = () => {
    if (!!parkingInfo && !!site && !!user) {
      const spotFormsRequest: ParkingSpotForm[] = recurrenceSelectedDays.reduce((acc, cur) => {
        const spotForm = {
          from: zonedTimeToUtc(
            isToday(cur)
              ? dateUtils.roundToNextQuarter(
                  addHours(
                    utcToZonedTime(
                      zonedTimeToUtc(addMinutes(CURRENT_DATE, CURRENT_DATE.getTimezoneOffset()), 'UTC'),
                      site.timezone
                    ),
                    1
                  )
                )
              : startOfDay(cur),
            site.timezone
          ).toISOString(),
          to: zonedTimeToUtc(addDays(startOfDay(cur), 1), site.timezone).toISOString(),
          parkingAreaId: parkingInfo.id,
        }
        acc.push(spotForm)
        return acc
      }, [] as ParkingSpotForm[])
      const requestBody: ParkingSpotForms = {
        spotForms: spotFormsRequest,
      }
      Alert.open(
        () => (
          <ValidationContent
            title={i18n.t('screens.parking.confirmCreateBooking')}
            onConfirm={() => {
              Alert.close()
              setIsReserving(true)
              api
                .postParkingBooking(site.id, user.type, requestBody)
                .then((res) => {
                  const isPartialResults = res.requests.length !== spotFormsRequest.length
                  Alert.open(
                    () => (
                      <ValidationContent
                        title={i18n.t(
                          `screens.parking.${
                            isPartialResults
                              ? 'partialBookings'
                              : spotFormsRequest.length === 1
                              ? 'successCreateBooking'
                              : 'successCreateBookingMultiple'
                          }`
                        )}
                        onConfirm={Alert.close}
                      />
                    ),
                    true
                  )
                  setIsRecurrenceActive(false)
                  fetch()
                })
                .catch(() =>
                  Alert.open(
                    () => <ValidationContent title={i18n.t('screens.parking.error')} onConfirm={Alert.close} />,
                    true
                  )
                )
                .finally(() => setIsReserving(false))
            }}
            ariaLabelConfirm={i18n.t('label.ariaLabel.windowCrossIcon')}
            cancelButton
            onCancel={Alert.close}
          />
        ),
        true
      )
    }
  }

  const excludeWeekEnds = (date: Date) => ![0, 6].includes(getDay(date))

  const renderRecurrence = () => (
    <RowContainer columnInSmallScreens>
      <BookingFormRecurrence isMobile={isMobile}>
        <RowContainer spaceBetween>
          <Label>{i18n.t('screens.planning.modal.recurrence')}</Label>
          <IconContainer>
            <SwitchFilter value={isRecurrenceActive} setValue={setIsRecurrenceActive} />
          </IconContainer>
        </RowContainer>
        {isRecurrenceActive && <RowContainer>{renderRecurrencePanel()}</RowContainer>}
      </BookingFormRecurrence>
      <BookingFormRecurrence isMobile={isMobile}>
        {isRecurrenceActive && (
          <MobileContainer>
            <Label>{i18n.t('screens.planning.modal.to')}</Label>
            <RowContainer>
              <ChoiceContainer>
                <CliquableDiv onClick={() => setIsRecurrenceDatePickerVisible(true)}>
                  <ChoiceText>
                    {utils.capitalize(i18n.t('screens.planning.reservationDate', { date: endDateRecurrence }))}
                  </ChoiceText>
                  <IconContainer>
                    <Icons name="chevron_right" color={Theme.colors.blue} />
                  </IconContainer>
                </CliquableDiv>
                {isRecurrenceDatePickerVisible && (
                  <CustomDatePicker
                    selectedDate={endDateRecurrence}
                    onChange={setEndDateRecurrence}
                    minDate={selectedDate}
                    maxDate={maxSelectableDate}
                    closePicker={() => setIsRecurrenceDatePickerVisible(false)}
                    filterDate={excludeWeekEnds}
                    excludeDates={datesToAvoid}
                  />
                )}
              </ChoiceContainer>
            </RowContainer>
          </MobileContainer>
        )}
      </BookingFormRecurrence>
    </RowContainer>
  )

  const renderRecurrenceDay = (day: WeekDay) => {
    const isSelected = !!selectedWeekDays.find((d) => d === day)
    return (
      <DayChoice key={day} selected={isSelected} onClick={() => toggleWeekDay(day)}>
        <DayLetter selected={isSelected}>{i18n.t(`common.weekdays.${day}`)[0]}</DayLetter>
      </DayChoice>
    )
  }

  const renderRecurrencePanel = () => (
    <DaySelectionContainer>
      {WEEKDAYS.map(renderRecurrenceDay)}
      <EveryDayContainer onClick={setAllWeekDays}>{i18n.t('screens.planning.modal.everyDay')}</EveryDayContainer>
    </DaySelectionContainer>
  )

  const renderParkingFormBooking = () => (
    <>
      <Title>{i18n.t('screens.parking.bookingForm')}</Title>
      <MobileContainer margin={20}>
        <Label>{i18n.t('screens.planning.firstForm.date')}</Label>
        <ChoiceContainer>
          <CliquableDiv
            tabIndex={0}
            onClick={() => setIsDatePickerVisible(true)}
            onKeyDown={(e) => onEnterKey(e, () => setIsDatePickerVisible(true))}>
            <ChoiceText>
              {utils.capitalize(i18n.t('screens.planning.reservationDate', { date: selectedDate }))}
            </ChoiceText>
            <IconContainer>
              <Icons name="chevron_right" color={Theme.colors.blue} />
            </IconContainer>
          </CliquableDiv>
          {isDatePickerVisible && (
            <CustomDatePicker
              selectedDate={selectedDate}
              onChange={setSelectedDate}
              minDate={minSelectableDate}
              maxDate={maxSelectableDate}
              closePicker={() => setIsDatePickerVisible(false)}
              filterDate={excludeWeekEnds}
              excludeDates={datesToAvoid}
            />
          )}
        </ChoiceContainer>
      </MobileContainer>

      {renderRecurrence()}
      {alreadyBookedDate && isRecurrenceActive && (
        <WarningTextContainer>
          <Label>{i18n.t('screens.parking.alreadyBookedDate')}</Label>
        </WarningTextContainer>
      )}
      <ButtonContainer>
        <Button
          font={Theme.fonts.h3Bold}
          label={i18n.t(
            `screens.parking.${
              isRecurrenceActive && recurrenceSelectedDays.length > 1 ? 'formTitlePlural' : 'formTitle'
            }`
          )}
          onClick={onCreateBooking}
          shadow
          width={350}
          loading={isReserving}
        />
      </ButtonContainer>
    </>
  )

  const renderParkingBookingItem = (booking: ParkingSpotRequest) => {
    const status = booking.nextAllocationStatus
    const isTodayBooking = isSameDay(new Date(), new Date(booking.startTime))
    const isBeforeMidday = (isTodayBooking && getHours(CURRENT_DATE) < 12) || !isTodayBooking
    const isRemovable = booking.parkingArea.canBeCancelled && isBeforeMidday
    const statusColor = findStatusColor(status, Theme)

    return (
      <div key={booking.id}>
        <BookingContainer>
          <ParkingInfoContainer>
            <IconInfoContainer>
              <Icons name="parking" size={15} color={Theme.colors.blue} />
              <ParkingInfoText>{booking.parkingArea.name}</ParkingInfoText>
            </IconInfoContainer>
            <IconInfoContainer>
              <Icons name="agenda" size={15} color={Theme.colors.blue} />
              <ParkingInfoText>
                {stringUtils.capitalize(
                  i18n.t('screens.parking.dateBooking', {
                    date: {
                      date: new Date(booking.startTime),
                      mode: 'dateWithTimezone',
                      timeZone: site?.timezone,
                    },
                  })
                )}
              </ParkingInfoText>
            </IconInfoContainer>
            <IconInfoContainer>
              <Icons name="pin" size={15} color={Theme.colors.blue} />
              <ParkingInfoText>{booking.parkingArea.address}</ParkingInfoText>
            </IconInfoContainer>
            <StatusLabel color={statusColor} label={i18n.t(`screens.parking.status.${status}`)} marginBottom={4} />
            {(status === 'PENDING' || status === 'ALREADY_ATTEMPTED') && booking.nextAllocationAt && (
              <>
                <IconInfoContainer>
                  <ParkingInfoText>{i18n.t('screens.parking.nextAttributionText')}</ParkingInfoText>
                </IconInfoContainer>
                <IconInfoContainer>
                  <Icons name="clock" size={15} color={Theme.colors.blue} />
                  <ParkingInfoText>
                    {stringUtils.capitalize(
                      i18n.t('screens.parking.nextAttribution', {
                        date: {
                          date: new Date(booking.nextAllocationAt),
                          mode: 'dateWithTimezone',
                          timeZone: site?.timezone,
                        },
                      })
                    )}
                  </ParkingInfoText>
                </IconInfoContainer>
              </>
            )}
          </ParkingInfoContainer>
          <CenterButtonContainer>
            <DeleteButtonContainer onClick={(evt) => evt.stopPropagation()} onKeyDown={(e) => e.stopPropagation()}>
              <Button
                label={i18n.t('common.delete')}
                onClick={() => onDeleteBooking(booking)}
                verticalPadding={6}
                horizontalPadding={0}
                font={Theme.fonts.label}
                disabled={!isRemovable}
              />
            </DeleteButtonContainer>
          </CenterButtonContainer>
        </BookingContainer>
      </div>
    )
  }

  if (!hasParkingCommuty) {
    return (
      <LoaderContainer>
        <Title>{i18n.t('screens.parking.errorNoFeature')}</Title>
      </LoaderContainer>
    )
  }

  if (screenStatus === 'loading') {
    return (
      <LoaderContainer>
        <Loader type="TailSpin" color={Theme.colors.blue} />
      </LoaderContainer>
    )
  }

  if (screenStatus === 'error') {
    return (
      <LoaderContainer>
        {!!showedBanner && <Banner banner={showedBanner} />}
        <SurveyBanner findFeature={findFeature} />
        <Title>{i18n.t('screens.parking.error')}</Title>
      </LoaderContainer>
    )
  }

  return (
    <>
      <Breadcrumb screen="parking" path="parking" navigation={navigation} />

      <MainContainer>
        <TopContainer>
          {!!showedBanner && <Banner banner={showedBanner} marginRight={20} />}
          <SurveyBanner findFeature={findFeature} />
          {renderParkingFormBooking()}
        </TopContainer>
        <BottomContainer>
          <Title>{i18n.t('screens.parking.bookings')}</Title>
          {bookingsList.length === 0 ? (
            <EmptyListContainer>
              <NoBookings>{i18n.t('screens.parking.noBookings')}</NoBookings>
              <EmptyImageContainer>
                <EmptyImageplaceHolder src={EmptyImagePlaceholder} />
              </EmptyImageContainer>
            </EmptyListContainer>
          ) : (
            <BookingsListContainer isMobile={isMobile}>
              {bookingsList.map(renderParkingBookingItem)}
            </BookingsListContainer>
          )}
        </BottomContainer>
      </MainContainer>
    </>
  )
}

export default ParkingScreen

const MainContainer = styled('div')`
  display: flex;
  flex-direction: column;
  min-height: calc(100vh - 116px);
  overflow: hidden;
  @media only screen and (max-width: ${windowSizeBreakpoints.phone}px) {
    width: 100%;
  }
`

const TopContainer = styled('div')`
  display: flex;
  flex-direction: column;
  margin: 20px 0px 0px 102px;
  @media only screen and (max-width: ${windowSizeBreakpoints.mediumBig}px) {
    margin-left: 50px;
  }
  @media only screen and (max-width: ${windowSizeBreakpoints.small}px) {
    margin: 40px 0px 0px 30px;
  }
  @media only screen and (max-width: ${windowSizeBreakpoints.phone}px) {
    margin-left: 20px;
    margin-top: 20px;
  }
`
const BottomContainer = styled('div')`
  display: flex;
  position: relative;
  flex-direction: column;
  flex: 1;
  height: 100%;
  min-height: calc(50vh - 30px);
  padding: 40px 0px 60px 102px;
  background-color: ${(props) => props.theme.colors.mediumLightGrey};
  @media only screen and (max-width: ${windowSizeBreakpoints.mediumBig}px) {
    padding: 40px 30px 60px 50px;
  }
  @media only screen and (max-width: ${windowSizeBreakpoints.small}px) {
    padding-left: 30px;
  }
  @media only screen and (max-width: ${windowSizeBreakpoints.phone}px) {
    padding: 20px;
  }
`

const BookingsListContainer = styled.div<{ isMobile: boolean }>`
  display: flex;
  flex-direction: column;
  height: 100%;
  ${(props) => !props.isMobile && 'width: 350px;'}
  padding: 10px 20px;
  background-color: ${(props) => props.theme.colors.white};
  @media only screen and (max-width: ${windowSizeBreakpoints.phone}px) {
    padding: 0px;
    flex: 1;
    background-color: ${(props) => props.theme.colors.mediumLightGrey};
  }
`

const BookingContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 10px;
  padding: 6px 0px;
  margin: 10px 0px;
  min-height: 50px;
  box-shadow: 0 0 10px 0 ${(props) => props.theme.colors.shadow};
  &:hover {
    box-shadow: 0 0 20px 0 ${(props) => props.theme.colors.shadow};
  }
`

const Title = styled.p`
  ${(props) => props.theme.fonts.bodyBold}
  font-size: 24px;
`

const LoaderContainer = styled('div')`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 15%;
  margin-left: 16px;
  margin-right: 16px;
`

const ParkingInfoContainer = styled('div')`
  flex: 1;
  display: flex;
  flex-direction: column;
  width: 155px;
  margin: 5px 0px 7px 10px;
`

const IconInfoContainer = styled('div')`
  display: flex;
  align-items: center;
  margin-bottom: 4px;
`

const ParkingInfoText = styled('p')`
  ${(props) => props.theme.fonts.label};
  color: ${(props) => props.theme.colors.darkGrey};
  margin: 0px;
  margin-left: 5px;
`

const CenterButtonContainer = styled('div')`
  display: flex;
  align-items: center;
  margin: 0px 10px;
`

const DeleteButtonContainer = styled('div')`
  display: flex;
  width: 72px;
`

const NoBookings = styled('p')`
  ${(props) => props.theme.fonts.body};
  color: ${(props) => props.theme.colors.darkGrey};
`

const EmptyImageContainer = styled('div')`
  position: absolute;
  top: 0;
  left: calc(50% - 339px);
  display: flex;
  align-items: center;
  @media only screen and (max-width: ${windowSizeBreakpoints.phone}px) {
    left: 16px;
    right: 16px;
  }
`

const EmptyImageplaceHolder = styled(SVG)`
  object-fit: fill;
  width: 678px;
  @media only screen and (max-width: ${windowSizeBreakpoints.phone}px) {
    width: calc(100vw - 40px);
  }
`

const EmptyListContainer = styled('div')`
  display: flex;
  flex-direction: column;
  height: 524px;
`

const Label = styled.p`
  ${(props) => props.theme.fonts.label}
  margin: 0px;
  font-size: 16px;
  line-height: 19px;
`

const CliquableDiv = styled.button`
  cursor: pointer;
  display: flex;
`

const ChoiceContainer = styled.div`
  cursor: pointer;
  margin-top: 8px;
`

const ChoiceText = styled.p`
  ${(props) => props.theme.fonts.labelBold}
  color: ${(props) => props.theme.colors.blue};
  font-size: 16px;
  line-height: 19px;
  margin: 0px;
  padding-right: 5px;
`

const IconContainer = styled.div``

const ButtonContainer = styled.div`
  height: 60px;
  margin-top: 40px;
  margin-bottom: 20px;
  @media only screen and (max-width: ${windowSizeBreakpoints.phone}px) {
    margin: 20px 30px 20px 0px;
    display: flex;
    justify-content: center;
  }
`

const RowContainer = styled.div<{ spaceBetween?: boolean; columnInSmallScreens?: boolean }>`
  display: flex;
  ${(props) => props.spaceBetween && ' justify-content: space-between;'}
  @media only screen and (max-width: ${windowSizeBreakpoints.medium}px) {
    ${(props) => props.columnInSmallScreens && 'flex-direction: column'};
  }
`

const MobileContainer = styled.div<{ margin?: number }>`
  @media only screen and (max-width: ${windowSizeBreakpoints.phone}px) {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-right: ${(props) => props.margin || 0}px;
  }
`

const DayChoice = styled.button<{ selected: boolean }>`
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
  border-radius: 15px;
  margin: 5px;
  background-color: ${(props) => (props.selected ? props.theme.colors.blue : props.theme.colors.lightGrey)};
  cursor: pointer;
`

const DayLetter = styled.p<{ selected: boolean }>`
  ${(props) => props.theme.fonts.body}
  color: ${(props) => (props.selected ? props.theme.colors.white : props.theme.colors.black)};
  font-size: 16px;
  line-height: 19px;
  margin: 0px;
  text-transform: capitalize;
`

const DaySelectionContainer = styled.div`
  display: flex;
  flex: 1;
  justify-content: space-between;
  margin-top: 20px;
`

const EveryDayContainer = styled.button`
  ${(props) => props.theme.fonts.body}
  font-size: 12px;
  line-height: 14px;
  width: 50px;
  padding-top: 7px;
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
`

const BookingFormRecurrence = styled.div<{ isMobile: boolean }>`
  display: flex;
  flex-direction: column;
  height: 100%;
  margin-top: 40px;
  margin-right: 60px;
  ${(props) => !props.isMobile && 'width: 350px;'}
  @media only screen and (max-width: ${windowSizeBreakpoints.phone}px) {
    margin-top: 20px;
    margin-right: 20px;
  }
`

const WarningTextContainer = styled.div`
  margin-top: 12px;
`
